import {
  DOWNLOAD_PRE_INTAKE_PDF_FAIL,
  DOWNLOAD_PRE_INTAKE_PDF_REQUEST,
  DOWNLOAD_PRE_INTAKE_PDF_SUCCESS,
  GET_FIELDS_REQUEST,
  GET_FIELDS_REQUEST_FAIL,
  GET_FIELDS_REQUEST_SUCCESS,
  GET_PRE_INTAKE_DATA_FAIL,
  GET_PRE_INTAKE_DATA_REQUEST,
  GET_PRE_INTAKE_DATA_SUCCESS,
  IS_PRE_INTAKE_SUBMIT,
  PUT_PRE_INTAKE_DATA_FAIL,
  PUT_PRE_INTAKE_DATA_REQUEST,
  PUT_PRE_INTAKE_DATA_SUCCESS,
  UPDATE_COMMUNICATION_PREFERENCES,
  UPDATE_DENTAL_INFO,
  UPDATE_DENTIST_INSURANCE_INFO,
  UPDATE_EMERGENCY_CONTACT_INFO,
  UPDATE_GENERAL_HEALTH,
  UPDATE_LETS_GET_TO_KNOW_YOU_BETTER,
  UPDATE_MEDICAL_HEALTH_HISTORY,
  UPDATE_PATIENT_INFO,
  UPDATE_PRE_INTAKE_VALIDATIONS,
  UPDATE_SLEEP_QUALITY,
} from '../../actions/actionTypes'

const initialState: any = {
  loader: false,
  getPreIntakeLoader: true,
  savePreIntakeLoader: false,
  preIntakeData: null,
  patientInformation: {
    firstName: '',
    middleName: '',
    lastName: '',
    emailAddress: '',
    sex: '',
    gender: '',
    genderOther: '',
    race: '',
    raceOther: '',
    mobilePhoneNumber: '',
    homePhoneNumber: '',
    dateOfBirth: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
    country: '',
    patient_id: '',
  },
  emergencyContactInfo: {
    emergencyContactPerson: '',
    emergencyContactNumber: '',
    relationshipToEmergencyContactPerson: '',
    other: '',
  },
  letsGetToKnowYouBetter: {
    occupation: '',
    isNightShiftInvolvedWithYourOccupation: '',
    maritalStatus: '',
    referredBy: '',
    doYouHaveDentalInsurance: '',
  },
  dentistInsuranceInfo: [
    {
      insuranceProvider: '',
      insuranceGroup: '',
      insuranceID: '',
      planMemberName: '',
      planMemberDob: null,
      position: 0,
    },
  ],
  generalHealth: {
    historyOfSmoking: '',
    alcoholAverageWeek: '',
    drugUse: null,
    drugInputUse: '',
    heightUnit: 'ft',
    heightFT: '',
    heightIN: '',
    heightCM: '',
    weightUnit: 'lb',
    weight: '',
  },
  dentalInfo: {
    dentalAnxiety: null,
    reasonForSchedulingThisVisit: '',
    nameOfPreviousOffice: '',
    lastVisitAtDentistOffice: null,
    lastTimeYouHadDentalXrayTaken: null,
    doesYourPhysicianRecommendAntibiotic: null,
    antibioticPremeditation: '',
    feelDentalHealth: '',
    checkIfYouHaveEverHadFollowing: [],
    rateAppearanceOfYourSmile: null,
    correctAppearanceOfYourSmile: null,
    hadWhiterSmile: null,
    doYouSnackMoreThatDay: null,
    toothpasteBrand: '',
    toothExtractedInLastThreeYear: null,
    fillingsInLastThreeYear: '',
    familyHistoryOfCavities: null,
    homeCareProtectiveFactors: [],
  },
  sleepQuality: {
    diagnosedSleepApnea: '',
    neckCircumferenceMeasured: '',
    snoreLoudly: '',
    feelDuringDay: '',
    gaspDuringSleep: '',
    difficultyBreathingNose: '',
    useCPAPMachine: '',
    useOralAppliance: '',
    additionalSleep: [null, null, null, null, null, null, null, null],
  },
  medicalHealthHistory: {
    physicianName: '',
    physicianContactNumber: '',
    dateOfLastVisit: null,
    seriousIllnessesOrOperations: null,
    seriousIllnessesOrOperationsInput: '',
    takingMedicationsOrSupplements: null,
    takingMedicationsOrSupplementsInput: '',
    shortnessBreath: null,
    nursing: null,
    takingBirthControl: null,
    pregnant: null,
    medicalConditions: [],
    medicalConditionsOther: '',
    checkboxListTwo: [],
    checkboxListTwoOtherInput: '',
  },
  communicationPreferences: {
    appearanceOfYourTeeth: null,
    feelIfYouWereToLoseTooth: null,
    yourTeethFunctionWell: null,
    keepYourTeethForRestOfYourLife: null,
    concernsWithAppearanceOfYourTeeth: null,
    concernsWithAppearanceOfYourTeethReason: '',
    teethSensitive: null,
    teethSensitiveReason: '',
    describeYourselfMore1: '',
    describeYourselfMore2: '',
  },
  errors: {
    dentistInsuranceInfo: {},
    emergencyContactInfo: {},
    generalHealth: {},
    letsGetToKnowYouBetter: {},
    dentalInfo: {},
    sleepQuality: {},
    medicalHealthHistory: {},
    patientInformation: {},
    communicationPreferences: {},
  },
  isPreIntakeSubmit: false,
  downloadPreIntakePdfLoader: false,
  downloadPreIntakePdfData: null,
  downloadPreIntakePdfError: null,
  getFiledLoader: true,
  getFiledData: {
    medicalConditionsData: null,
    allergiesListData: null,
  },
  getFiledError: null,
}

const preIntakeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PRE_INTAKE_DATA_REQUEST:
      return {
        ...state,
        getPreIntakeLoader: true,
      }
    case GET_PRE_INTAKE_DATA_SUCCESS:
      return {
        ...state,
        preIntakeData: action.payload,
        getPreIntakeLoader: false,
      }
    case GET_PRE_INTAKE_DATA_FAIL:
      return {
        ...state,
        preIntakeData: null,
        getPreIntakeLoader: false,
      }
    case UPDATE_DENTIST_INSURANCE_INFO:
      return {
        ...state,
        dentistInsuranceInfo: action.payload,
      }
    case UPDATE_EMERGENCY_CONTACT_INFO:
      return {
        ...state,
        emergencyContactInfo: action.payload,
      }
    case UPDATE_GENERAL_HEALTH:
      return {
        ...state,
        generalHealth: action.payload,
      }
    case UPDATE_LETS_GET_TO_KNOW_YOU_BETTER:
      return {
        ...state,
        letsGetToKnowYouBetter: action.payload,
      }
    case UPDATE_DENTAL_INFO:
      return {
        ...state,
        dentalInfo: action.payload,
      }
    case UPDATE_SLEEP_QUALITY:
      return {
        ...state,
        sleepQuality: action.payload,
      }
    case UPDATE_COMMUNICATION_PREFERENCES:
      return {
        ...state,
        communicationPreferences: action.payload,
      }
    case UPDATE_PATIENT_INFO:
      return {
        ...state,
        patientInformation: action.payload,
      }
    case UPDATE_MEDICAL_HEALTH_HISTORY:
      return {
        ...state,
        medicalHealthHistory: action.payload,
      }
    case UPDATE_PRE_INTAKE_VALIDATIONS:
      return {
        ...state,
        errors: action.payload,
      }
    case PUT_PRE_INTAKE_DATA_REQUEST:
      return {
        ...state,
        savePreIntakeLoader: true,
      }
    case PUT_PRE_INTAKE_DATA_SUCCESS:
      return {
        ...state,
        savePreIntakeLoader: false,
      }
    case PUT_PRE_INTAKE_DATA_FAIL:
      return {
        ...state,
        savePreIntakeLoader: false,
      }
    case IS_PRE_INTAKE_SUBMIT:
      return {
        ...state,
        isPreIntakeSubmit: action.payload,
      }
    case DOWNLOAD_PRE_INTAKE_PDF_REQUEST:
      return {
        ...state,
        downloadPreIntakePdfLoader: true,
        downloadPreIntakePdfData: null,
        downloadPreIntakePdfError: null,
      }
    case DOWNLOAD_PRE_INTAKE_PDF_SUCCESS:
      return {
        ...state,
        downloadPreIntakePdfData: action.payload,
        downloadPreIntakePdfLoader: false,
        downloadPreIntakePdfError: null,
      }
    case DOWNLOAD_PRE_INTAKE_PDF_FAIL:
      return {
        ...state,
        downloadPreIntakePdfData: null,
        downloadPreIntakePdfLoader: false,
        downloadPreIntakePdfError: action.payload,
      }
    case GET_FIELDS_REQUEST:
      return {
        ...state,
        getFiledLoader: true,
      }
    case GET_FIELDS_REQUEST_SUCCESS:
      return {
        ...state,
        getFiledLoader: true,
        getFiledData: action.payload,
        getFiledError: null,
      }
    case GET_FIELDS_REQUEST_FAIL:
      return {
        ...state,
        getFiledLoader: true,
        getFiledData: null,
        getFiledError: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default preIntakeReducer
