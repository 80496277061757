import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';

import pinkPatient from "../../../assets/images/v2/landingPage/pinkPatient.png";

import pinkClinicPortaOne from "../../../assets/images/v2/landingPage/clinicExam/pinkClinicPortaOne.png";
import pinkClinicPortaTwo from "../../../assets/images/v2/landingPage/clinicExam/pinkClinicPortaTwo.png";
import pinkClinicPortaThird from "../../../assets/images/v2/landingPage/clinicExam/pinkClinicPortaThird.png";
import pinkClinicPortaFour from "../../../assets/images/v2/landingPage/clinicExam/pinkClinicPortaFour.png";
import theme from '../../../theme';

const pinkClinicItems = [
  {
    imageLogo: <img src={pinkClinicPortaOne} alt="pinkClinicPortaOne" height={'56px'} width={'61px'} />,
    text: "Images required for Risk Assessment"
  },
  {
    imageLogo: <img src={pinkClinicPortaTwo} alt="pinkClinicPortaTwo" height={'56px'} width={'61px'} />,
    text: "X-rays of the Teeth for Assessment"
  },
  {
    imageLogo: <img src={pinkClinicPortaThird} alt="pinkClinicPortaThird" height={'56px'} width={'61px'} />,
    text: "Risk Assessments Results"
  },
  {
    imageLogo: <img src={pinkClinicPortaFour} alt="pinkClinicPortaFour" height={'56px'} width={'61px'} />,
    text: "Customised Treatment Plan"
  }
]

const useStyles = makeStyles((theme: any) => ({
  headerText: {
    fontSize: '40px',
    fontWeight: '600',
    lineHeight: '56px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      lineHeight: '45px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '25px',
      lineHeight: '35px'
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '20px',
      lineHeight: '25px'
    },
  },
  descriptionText: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    color: theme.palette.common.black50,
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      lineHeight: '25px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '16px',
      lineHeight: '22px'
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
      lineHeight: '20px'
    },
  },
  purpleColor: {
    color: theme.palette.v2.secondary
  },
  greenMainColor: {
    color: theme.palette.primary.main
  }
}));

const PinkPatient = () => {
  const classes = useStyles();
  const palette = theme.palette

  return (
    <Box className={`d-flex justify-center mt-30`}>
      <Grid container={true} className={`d-flex`} justifyContent='space-between' p={'0 30px'} maxWidth={1300}
        columnSpacing={{ sm: 0, md: 2 }} mt={'20px'} minHeight={'430px'}>

        <Grid item={true} lg={4} md={4} position={'relative'} sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }}>
          <Box>
            <img src={pinkPatient} alt="pinkPatient" width={'100%'} />
          </Box>
        </Grid>

        <Grid item={true} lg={8} md={8} xs={12}>
          <Typography className={classes.headerText}>
            Optimise&nbsp;<span className={classes.purpleColor}>Patient Management for&nbsp;</span>
            <span className={classes.greenMainColor}>Enhanced Care</span>
          </Typography>
          <Typography className={classes.descriptionText}>
            Simplify patient management in your dental practice. Organize crucial patient details, track status, and easily edit profiles.
          </Typography>

          <Grid container={true} p={'32px 0'} spacing={2}>
            {pinkClinicItems && pinkClinicItems?.map((res: any, index: number) => (

              <Grid key={index} item={true} lg={3} md={3} xs={6} className='d-flex flex-col'>
                {res?.imageLogo}
                <Typography fontSize={'18px'} lineHeight={'25px'} color={palette.primary.main} fontWeight={700} mt={'15px'}>
                  {res?.text}
                </Typography>
              </Grid>
            ))
            }
          </Grid>

          <Typography className={classes.descriptionText}>
            Enhance patient management and provide exceptional care with our advanced dental practice management software. Sign up today and experience the convenience and efficiency our platform brings to your practice.
          </Typography>
        </Grid>

      </Grid >
    </Box>
  )
}

export default PinkPatient;