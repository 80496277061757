import { all, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { GET_CLINIC_STAFF_REQUEST, GET_MODULES_REQUEST } from '../../actions/actionTypes'
import {
  getClinicByStaffRequestFailure,
  getClinicByStaffRequestSuccess,
  getModulesRequestFailure,
  getModulesRequestSuccess,
} from '../../actions/modules/modulesAction'
import { errorToaster } from '../../../utils/helper'

// get all Clinic
function* getModuleRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`/v2/auth/access_module`)
    yield put(
      getModulesRequestSuccess({
        moduleData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getModulesRequestFailure({
        moduleError: e?.message,
      })
    )
  }
}

//get clinic by the staff

function* getClinicByStaffRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`/v2/clinic/account/${payload.payload.staff_id}`)
    yield put(
      getClinicByStaffRequestSuccess({
        clinicByStaffData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getClinicByStaffRequestFailure({
        clinicByStaffError: e?.message,
      })
    )
  }
}

function* getModuleSaga() {
  yield takeEvery(GET_MODULES_REQUEST, getModuleRequestSaga)
}

function* getClinicByStaffSaga() {
  yield takeEvery(GET_CLINIC_STAFF_REQUEST, getClinicByStaffRequestSaga)
}
export default function* moduleSaga() {
  yield all([getModuleSaga(), getClinicByStaffSaga()])
}
