import {
    GET_MODULES_REQUEST,
    GET_MODULES_REQUEST_SUCCESS,
    GET_MODULES_REQUEST_FAILURE,
    GET_CLINIC_STAFF_REQUEST,
    GET_CLINIC_STAFF_REQUEST_SUCCESS,
    GET_CLINIC_STAFF_REQUEST_FAILURE,
} from '../../actions/actionTypes'
import {
    GetModulesRequest,
    GetModulesRequestSuccess,
    GetModulesRequestFailure,
    GetModulesRequestSuccessPayload,
    GetModulesRequestFailurePayload,
    GetClinicByStaffRequest,
    GetClinicByStaffRequestSuccess,
    GetClinicByStaffRequestFailure,
    GetClinicByStaffRequestPayload,
    GetClinicByStaffRequestSuccessPayload,
    GetClinicByStaffsRequestFailurePayload

} from '../../schema/modules/modulesSchema'


// get all module
export const getModulesRequest = (): GetModulesRequest => ({
    type: GET_MODULES_REQUEST
})
export const getModulesRequestSuccess = (payload: GetModulesRequestSuccessPayload): GetModulesRequestSuccess => ({
    type: GET_MODULES_REQUEST_SUCCESS,
    payload
})
export const getModulesRequestFailure = (payload: GetModulesRequestFailurePayload): GetModulesRequestFailure => ({
    type: GET_MODULES_REQUEST_FAILURE,
    payload
})



// get clinic by staff
export const getClinicByStaffRequest = (payload: GetClinicByStaffRequestPayload): GetClinicByStaffRequest => ({
    type: GET_CLINIC_STAFF_REQUEST,
    payload
})
export const getClinicByStaffRequestSuccess = (payload: GetClinicByStaffRequestSuccessPayload): GetClinicByStaffRequestSuccess => ({
    type: GET_CLINIC_STAFF_REQUEST_SUCCESS,
    payload
})
export const getClinicByStaffRequestFailure = (payload: GetClinicByStaffsRequestFailurePayload): GetClinicByStaffRequestFailure => ({
    type: GET_CLINIC_STAFF_REQUEST_FAILURE,
    payload
})

