export const defaultErrorMessage = 'Something went wrong' as string
export const HeathHistoryPending = 'Health history pending'
export const HeathHistoryCompleted = 'Health history completed'
export const appointmentStatusOption = [
  { name: 'Upcoming', value: 'upcoming' },
  { name: 'Ongoing', value: 'ongoing' },
  { name: 'Completed', value: 'completed' },
  { name: 'Cancelled', value: 'cancelled' },
  { name: 'Finished', value: 'finished' },
  { name: 'No Show', value: 'no_show' },
]

export const healthHistoryStatusOption = [
  { name: 'Health History Status', value: '' },
  { name: 'To Send', value: 'To Send' },
  { name: 'Sent', value: 'Sent' },
  { name: 'Completed', value: 'Completed' },
]
