import { UPDATE_DEVICE_TOKEN } from '../../actions/actionTypes'
import { notificationInterface, updateDeviceTokenAction } from '../../schema/notification/notification'

const initialState: notificationInterface = {
  deviceToken: null,
}

const notificationReducer = (state = initialState, action: updateDeviceTokenAction) => {
  switch (action.type) {
    case UPDATE_DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}

export default notificationReducer
