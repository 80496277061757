import { all, put, call, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'
import { APPROVE_CLINIC_REQUEST } from '../../actions/actionTypes'
import { approveClinicFailureAction, approveClinicSuccessAction } from '../../actions/approveClinic'

function* postApproveClinic(payload: any): any {
  try {
    const response = yield API.put('/pms/onboard', payload?.payload?.data)
    yield put(approveClinicSuccessAction())
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    if (!payload?.payload?.fromEditClinic) {
      successToaster(response?.data?.meta?.message)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(approveClinicFailureAction())
  }
}

function* postApproveClinicWatch() {
  yield takeEvery(APPROVE_CLINIC_REQUEST, postApproveClinic)
}

export default function* approveClinicSaga() {
  yield all([postApproveClinicWatch()])
}
