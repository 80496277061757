import { all, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { GET_CLINIC_FILTER_LIST_REQUEST } from '../../actions/actionTypes'
import { getClinicFilterListRequestSuccess, getClinicFilterListRequestFailure } from '../../actions/commonFilter/clinicFilterAction'
import { errorToaster } from '../../../utils/helper'

// get clinic for the filter list
function* getClinicFilterListRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`v2/clinic/details/filter`)
    yield put(
      getClinicFilterListRequestSuccess({
        clinicFilterData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getClinicFilterListRequestFailure({
        clinicFilterError: e?.message,
      })
    )
  }
}

function* getClinicFilterList() {
  yield takeEvery(GET_CLINIC_FILTER_LIST_REQUEST, getClinicFilterListRequestSaga)
}

export default function* ClinicFilterSaga() {
  yield all([getClinicFilterList()])
}
