import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';

import benefitsOne from "../../../assets/images/v2/landingPage/benefits/benefitsOne.png";
import benefitsTwo from "../../../assets/images/v2/landingPage/benefits/benefitsTwo.png";
import benefitsThree from "../../../assets/images/v2/landingPage/benefits/benefitsThree.png";
import benefitsFour from "../../../assets/images/v2/landingPage/benefits/benefitsFour.png";
import benefitsFive from "../../../assets/images/v2/landingPage/benefits/benefitsFive.png";
import benefitsSix from "../../../assets/images/v2/landingPage/benefits/benefitsSix.png";
import benefitsSeven from "../../../assets/images/v2/landingPage/benefits/benefitsSeven.png";
import benefitsEight from "../../../assets/images/v2/landingPage/benefits/benefitsEight.png";
import benefitsNine from "../../../assets/images/v2/landingPage/benefits/benefitsNine.png";
import benefitsTen from "../../../assets/images/v2/landingPage/benefits/benefitsTen.png";
import theme from '../../../theme';

const useStyles = makeStyles((theme: any) => ({
  headerText: {
    fontSize: '40px',
    lineHeight: '56px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      lineHeight: '45px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '25px',
      lineHeight: '35px'
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '20px',
      lineHeight: '25px'
    },
  },
  logoItemsBox: {
    padding: '30px 26px',
    height: '260px',
    [theme.breakpoints.between(700, 960)]: {
      height: '255px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '235px'
    },
  },
  logoItemsText: {
    fontSize: '18px',
    lineHeight: '25px',
    textTransform: 'uppercase',
    fontWeight: 700,
    margin: '10px 0',
    color: theme.palette.primary.main
  },
  logoItemsDescription: {
    fontSize: '18px',
    lineHeight: '25px',
  },
  purpleColor: {
    color: theme.palette.v2.secondary
  },
  greenMainColor: {
    color: theme.palette.primary.main
  }
}));

const benefitsItems = [
  {
    imageLogo: <img src={benefitsOne} alt="benefitsOne" />,
    heading: "GROWTH-ORIENTED",
    description: "Optimises practice revenue by capitalising on missed treatment opportunities."
  },
  {
    imageLogo: <img src={benefitsTwo} alt="benefitsTwo" />,
    heading: "ENHANCES TRUST",
    description: "Supports dentist recommendations with quantifiable data and risk analysis."
  },
  {
    imageLogo: <img src={benefitsThree} alt="benefitsThree" />,
    heading: "INCREASED EFFICIENCY",
    description: "Automates the minute details of data processing – freeing dentists to focus on billable, hands-on patient care."
  },
  {
    imageLogo: <img src={benefitsFour} alt="benefitsFour" />,
    heading: "ENGAGED PATIENTS",
    description: "Builds trust and loyalty through a superior patient experience. Patients can review their file at any time and take an active role in their care."
  },
  {
    imageLogo: <img src={benefitsFive} alt="benefitsFive" />,
    heading: "WHOLISTIC",
    description: "Improves overall patient health and enhances dental care through the creation of detailed Patient Risk Profiles in 9 key areas."
  },
  {
    imageLogo: <img src={benefitsSix} alt="benefitsSix" />,
    heading: "STREAMLINED DATA COLLECTION",
    description: "Collects and cross-references patient data – saving valuable time and effort for new and seasoned clinicians alike."
  },
  {
    imageLogo: <img src={benefitsSeven} alt="benefitsSeven" />,
    heading: "INSIGHTFUL",
    description: "Uncovers more opportunities to deliver unmatched patient care and outcomes."
  },
  {
    imageLogo: <img src={benefitsEight} alt="benefitsEight" />,
    heading: "INTUITIVE",
    description: "Works effortlessly using an intuitive dashboard, with advanced reporting & analytics."
  },
  {
    imageLogo: <img src={benefitsNine} alt="benefitsNine" />,
    heading: "CONTINUAL MONITORING",
    description: "Improves your ability to track progress, measure outcomes and schedule patient follow-up– including subsequent treatments."
  },
  {
    imageLogo: <img src={benefitsTen} alt="benefitsTen" />,
    heading: "INSPIRING",
    description: "Lets you spend more time doing the challenging, result-oriented dentistry you love!"
  },
];

const BenefitsCompo = () => {
  const classes = useStyles();
  const palette = theme.palette

  return (
    <React.Fragment>
      <Box className={`d-flex flex-col align-center mt-30`}>

        <Box className={`d-flex flex-col`} maxWidth={1300}>
          <Typography className={`${classes.headerText}`}>
            Benefits
          </Typography>
          <Typography className={`${classes.headerText}`}>
            <span className={classes.purpleColor}>Less risk. More&nbsp;</span>
            <span className={classes.greenMainColor}>reward</span>.
          </Typography>
        </Box>

        <Grid container={true} p={'0 30px'} spacing={2} maxWidth={1300}>
          {benefitsItems && benefitsItems?.map((res: any, index: number) =>
            <Grid item={true} key={index} xl={3} lg={3} md={4} sm={6} xs={12}>
              <Box className={`${classes.logoItemsBox} d-flex flex-column`}>
                <Box>
                  {res?.imageLogo}
                </Box>
                <Typography className={`${classes.logoItemsText}`}>
                  {res?.heading}
                </Typography>
                <Typography className={`${classes.logoItemsDescription}`}>
                  {res?.description}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

    </React.Fragment>

  )
}

export default BenefitsCompo;