import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import HeaderLandingPage from '../../components/landingPage/header';
import SignInComponent from '../../components/landingPage/signIn';
import FourDataWithHeader from '../../components/landingPage/fourDataWithHeader';
import StepperCompo from '../../components/landingPage/steperCompo';
import PinkPatient from '../../components/landingPage/pinkPatient';
import BenefitsCompo from '../../components/landingPage/benefits';
import InsightAndResourcePage from '../../components/landingPage/insightAndResource';
import FrequentlyQustionPage from '../../components/landingPage/frequentlyQustions';
import SubscriptionPage from '../../components/landingPage/subscriptionPage';
import FooterLandingPage from '../../components/landingPage/footer';
import theme from '../../theme';

const useStyles = makeStyles((theme: any) => ({

}));

const LandingPage = () => {
  const classes = useStyles();
  const palette = theme.palette

  return (
    <Box sx={{ backgroundColor: palette.common.white, height: '100vh' }}>
      <HeaderLandingPage />

      <Box pb={'30px'} sx={{ backgroundColor: palette.common.white, height: `calc(100vh - 90px)`, overflowY: 'scroll' }}>
        <SignInComponent />
        <FourDataWithHeader />
        <PinkPatient />
        <StepperCompo />
        <BenefitsCompo />
        <SubscriptionPage />
        {/* TODO Future Use */}
        {/* <InsightAndResourcePage />
        <FrequentlyQustionPage /> */}
      </Box>
      <FooterLandingPage />
    </Box>
  )
}

export default LandingPage;