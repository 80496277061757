import { all, fork } from 'redux-saga/effects'
import appointmentSaga from './appointment/appointmentSaga'
import ApproveClinicSaga from './approveClinic/approveClinicSaga'
import clinicAdminChangePasswordSaga from './auth/changePasswordSaga'
import communicationSaga from './auth/communicationSaga'
import createPasswordSaga from './auth/createPasswordSaga'
import clinicAdminForgotPasswordSaga from './auth/forgotPasswordSaga'
import clinicAdminLoginSaga from './auth/loginSaga'
import matchPasswordSaga from './auth/matchPasswordSaga'
import otpSaga from './auth/otpSaga'
import clinicAdminResetPasswordSaga from './auth/resetPasswordSaga'
import clinicAdminSignUpSaga from './auth/signUpSaga'
import termsConditionPrivacyPolicySaga from './auth/termsConditionPrivacyPolicySaga'
import verifiedEmailSaga from './auth/verifiedEmailSaga'
import clinicSaga from './clinic/clinicSaga'
import clinicSettingSaga from './clinic/clinicSettingsSaga'
import ClinicFilterSaga from './commonFilter/clinicFilterSaga'
import verifyUserEmailSaga from './commonFilter/verifyUserEmailSaga'
import memberSaga from './member/memberSaga'
import moduleSaga from './module/moduleSaga'
import ownerSaga from './owner/ownerSaga'
import ownerProfileSaga from './ownerProfile/ownerProfileSaga'
import patientSaga from './patient/patientSaga'
import preIntakesSaga from './preIntake/preIntakeSaga'
import subscriptionPlansSaga from './subscriptionPlans/subscriptionPlansSaga'

export function* rootSaga() {
  yield all([
    fork(clinicAdminSignUpSaga),
    fork(clinicAdminLoginSaga),
    fork(clinicAdminChangePasswordSaga),
    fork(ownerProfileSaga),
    fork(clinicAdminForgotPasswordSaga),
    fork(clinicAdminResetPasswordSaga),
    fork(termsConditionPrivacyPolicySaga),
    fork(otpSaga),
    fork(verifiedEmailSaga),
    fork(clinicSaga),
    fork(patientSaga),
    fork(memberSaga),
    fork(appointmentSaga),
    fork(moduleSaga),
    fork(matchPasswordSaga),
    fork(ClinicFilterSaga),
    fork(ApproveClinicSaga),
    fork(subscriptionPlansSaga),
    fork(ownerSaga),
    fork(createPasswordSaga),
    fork(verifyUserEmailSaga),
    fork(preIntakesSaga),
    fork(clinicSettingSaga),
    fork(communicationSaga),
  ])
}
