import { saveAs } from 'file-saver' // For saving the file as CSV
import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, getAccountData, getOwnerData, successToaster } from '../../../utils/helper'
import {
  CREATE_CLINIC_REQUEST,
  DELETE_CLINIC_REQUEST,
  DOWNLOAD_CSV_FILE_REQUEST,
  GET_ALL_MEMBER_REQUEST,
  GET_CLINIC_REQUEST,
  GET_MSA_AGREEMENT_REQUEST,
  GET_PMS_SOFTWARE_REQUEST,
  GET_PRACTICE_INFORMATION_REQUEST,
  GET_PROCEDURE_CODE_REQUEST,
  PUT_MSA_AGREEMENT_REQUEST,
  PUT_PRACTICE_INFORMATION_REQUEST,
  PUT_PROCEDURE_DATA_REQUEST,
  UPDATE_CLINIC_REQUEST,
  UPDATE_CLINIC_STATUS_REQUEST,
  UPLOAD_CSV_FILE_REQUEST,
  VIEW_CLINIC_REQUEST,
} from '../../actions/actionTypes'
import {
  changeMsaAgreementRequest,
  changePracticeInformationRequest,
  clinicUpdateStatusRequestFailure,
  clinicUpdateStatusRequestSuccess,
  createClinicRequestFailure,
  createClinicRequestSuccess,
  deleteClinicRequestFailure,
  deleteClinicRequestSuccess,
  downloadCsvFileRequestFailureAction,
  downloadCsvFileRequestSuccessAction,
  getAllMemberRequestFailure,
  getAllMemberRequestSuccess,
  getClinicCacheDataClearRequest,
  getClinicRequestFailure,
  getClinicRequestSuccess,
  getMsaAgreementRequestFailure,
  getMsaAgreementRequestSuccess,
  getPMSSoftwareRequestFailure,
  getPMSSoftwareRequestSuccess,
  getPracticeInformationRequestFailure,
  getPracticeInformationRequestSuccess,
  getProcedureCodeCacheDataClearRequest,
  getProcedureCodeRequestFailure,
  getProcedureCodeRequestSuccess,
  putMsaAgreementRequestFailure,
  putMsaAgreementRequestSuccess,
  putPracticeInformationRequestFailure,
  putPracticeInformationRequestSuccess,
  putProcedureDataRequestFailure,
  putProcedureDataRequestSuccess,
  updateClinicRequestFailure,
  updateClinicRequestSuccess,
  uploadCsvFileRequestFailureAction,
  uploadCsvFileRequestSuccessAction,
  viewClinicCacheDataClearRequest,
  viewClinicRequestFailure,
  viewClinicRequestSuccess,
} from '../../actions/clinic/clinicAction'

// create clinic
function* createClinicRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`v2/clinic/create`, payload?.payload)
    yield call(payload.payload.callback, response.data)
    // if needed in future
    // successToaster(response?.data?.meta?.message)
    yield put(createClinicRequestSuccess(response.data?.detail))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      createClinicRequestFailure({
        clinicCreateError: e?.message,
      })
    )
  }
}

// get all the clinic (clinic list)
function* getClinicRequestSaga(payload: any): any {
  const accountData = getAccountData()

  yield put(getClinicCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload.payload.search },
      { key: 'page', value: payload.payload.page },
      { key: 'size', value: payload.payload.size },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/clinic/get/all${tmpUrl}`
    const restrictUrl = `/v2/clinic/get/all?clinic_id=${payload.payload.clinic_id}`
    const url = newUrl
    const response = yield API.get(accountData?.is_staff ? restrictUrl : url)
    yield put(
      getClinicRequestSuccess({
        getClinicData: response.data?.detail,
        getClinicMeta: response.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getClinicRequestFailure({
        getClinicError: e?.message,
      })
    )
  }
}

// view clinic details
function* viewClinicRequestSaga(payload: any): any {
  yield put(viewClinicCacheDataClearRequest())
  try {
    const response = yield API.get(`/v2/clinic/${payload?.payload?.clinic_id}`)
    if (payload.payload.callback) {
      yield call(payload.payload.callback, response?.data?.detail)
    }
    // if needed in future
    // successToaster(response?.data?.meta?.message)

    yield put(
      viewClinicRequestSuccess({
        viewClinicData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      viewClinicRequestFailure({
        viewClinicError: e?.message,
      })
    )
  }
}

// update clinic
function* updateClinicRequestSaga(payload: any): any {
  const payloadClinic = payload?.payload?.profile_picture ? payload?.payload?.profile_picture : payload?.payload?.data
  try {
    const response = yield API.put(`/v2/clinic/update`, payloadClinic)
    // if needed in future
    if (payload.payload.callback) {
      yield call(payload.payload.callback, response.data)
    }
    successToaster(response?.data?.meta?.message)
    yield put(
      updateClinicRequestSuccess({
        updateClinicData: response.data,
      })
    )
  } catch (e: any) {
    if (payload?.payload?.errorCallback) {
      payload?.payload?.errorCallback()
    }
    errorToaster(e?.message)
    yield put(
      updateClinicRequestFailure({
        updateClinicError: e?.message,
      })
    )
  }
}

// delete clinic
function* deleteClinicRequestSaga(payload: any): any {
  try {
    const response = yield API.delete(`clinic?clinic_id=${payload.payload?.clinic_id}`)
    yield call(payload.payload.callback, response.data)
    successToaster(response?.data?.meta?.message)
    yield put(
      deleteClinicRequestSuccess({
        deleteClinicData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      deleteClinicRequestFailure({
        deleteClinicError: e?.message,
      })
    )
  }
}
// update clinic status
function* updateClinicStatusRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`v2/clinic/${payload.payload?.clinic_id}/status`, payload.payload)
    yield call(payload.payload.callback, response.data)
    // if needed in future
    // successToaster(response?.data?.meta?.message)
    yield put(
      clinicUpdateStatusRequestSuccess({
        clinicStatusUpdateData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      clinicUpdateStatusRequestFailure({
        clinicStatusUpdateError: e?.message,
      })
    )
  }
}

// get PMS Software
function* getPMSSoftwareRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`v2/clinic/pms/software`)
    yield put(
      getPMSSoftwareRequestSuccess({
        pmsSoftwareData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getPMSSoftwareRequestFailure({
        pmsSoftwareError: e?.message,
      })
    )
  }
}

// get all member
function* getAllMemberRequestSaga(payload: any): any {
  const accountData = getAccountData()

  const staffUrl = `v2/staff/get/details?clinic_id=${payload?.payload?.clinic_id}`
  try {
    const response = yield API.get(accountData?.is_staff ? staffUrl : `v2/staff/get/details`)
    yield put(
      getAllMemberRequestSuccess({
        allMemberData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getAllMemberRequestFailure({
        allMemberError: e?.message,
      })
    )
  }
}

// get procedure code via clinic
function* getProcedureCodeRequestSaga(payload: any): any {
  yield put(getProcedureCodeCacheDataClearRequest())

  try {
    const params = [
      { key: 'search', value: payload.payload.search },
      { key: 'page', value: payload.payload.page },
      { key: 'size', value: payload.payload.size },
      { key: 'code_type', value: payload.payload.code_type },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `v2/treatment/procedure-codes/clinic/${payload?.payload?.clinic_id}${tmpUrl}`
    const url = newUrl
    const response = yield API.get(url)
    yield put(
      getProcedureCodeRequestSuccess({
        procedureCodeData: response?.data?.detail,
        procedureCodeMeta: response?.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getProcedureCodeRequestFailure({
        procedureCodeError: e?.message,
      })
    )
  }
}

// update procedure data
function* updateProcedureDataRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`v2/treatment/procedure-code`, payload.payload?.data)
    yield call(payload.payload.callback, response.data)
    // if needed in future
    successToaster(response?.data?.meta?.message)
    yield put(
      putProcedureDataRequestSuccess({
        updatedProcedureCodeData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      putProcedureDataRequestFailure({
        updatedProcedureCodeError: e?.message,
      })
    )
  }
}

function* getCSVData(payload: any): any {
  try {
    const response = yield API.get(
      `/v2/treatment/download/clinic-procedure-codes/${payload?.payload?.clinic_id}?code_type=${payload?.payload?.code_type}`
    )
    // Convert the binary response data to a Blob
    const blob = new Blob([response.data], { type: 'text/csv' })
    // Use the 'file-saver' library to save the Blob as a CSV file
    saveAs(blob, 'data.csv')
    yield put(downloadCsvFileRequestSuccessAction(response?.data))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(downloadCsvFileRequestFailureAction(e?.message))
  }
}

function* uploadCSVData(payload: any): any {
  try {
    const response = yield API.post(
      `/v2/treatment/upload-csv/clinic/${payload?.payload?.file?.get('clinic_id')}/procedure-codes?code_type=${payload?.payload?.file?.get(
        'code_type'
      )}`,
      payload?.payload?.file
    )
    yield put(uploadCsvFileRequestSuccessAction(response?.data?.detail))
    successToaster(response?.data?.meta?.message)
    if (payload.payload.callback) {
      payload.payload.callback()
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(uploadCsvFileRequestFailureAction(e?.message))
  }
}
// Get practice information
function* getPracticeInformation(payload: any): any {
  try {
    const response = yield API.get(`/v2/clinic/${payload?.payload?.clinic_id}/practice-information`)
    yield put(getPracticeInformationRequestSuccess(response.data?.detail))
    const data = response.data?.detail

    const payloadData = {
      email: data?.email || null,
      pm_software: data?.pm_software || null,
      imaging_software: data?.imaging_software || null,
      scheduling_software: data?.scheduling_software || null,
      intra_oral_3d_scanner: data?.intra_oral_3d_scanner || null,
      practice_days_no: data?.practice_days_no || null,
      dentists_no: data?.dentists_no?.toString() || null,
      assistants_no: data?.assistants_no?.toString() || null,
      hygienists_no: data?.hygienists_no?.toString() || null,
      npe_patients_no: data?.npe_patients_no?.toString() || null,
      recall_patient_no: data?.recall_patient_no?.toString() || null,
      other_technologies_used: data?.other_technologies_used || null,
      extra_comments: data?.extra_comments || null,
      exam_duration_new_patient: data?.exam_duration_new_patient?.split(',')?.filter((f: string) => f?.length) || [],
      is_photos_taken: data?.is_photos_taken?.split(',')?.filter((f: string) => f?.length) || [],
      how_preintake_data_collected: data?.how_preintake_data_collected?.split(',')?.filter((f: string) => f?.length) || [],
      how_often_md_history_updated: data?.how_often_md_history_updated?.split(',')?.filter((f: string) => f?.length) || [],
    } as any
    yield put(changePracticeInformationRequest(payloadData))
  } catch (e: any) {
    yield put(getPracticeInformationRequestFailure(e?.message))
  }
}

function* putPracticeInformation(payload: any): any {
  const payloadData = payload?.payload
  const newPayload = {
    email: payloadData?.email,
    pm_software: payloadData?.pm_software,
    imaging_software: payloadData?.imaging_software,
    scheduling_software: payloadData?.scheduling_software,
    intra_oral_3d_scanner: payloadData?.intra_oral_3d_scanner,
    practice_days_no: parseInt(payloadData?.practice_days_no),
    dentists_no: parseInt(payloadData?.dentists_no),
    assistants_no: parseInt(payloadData?.assistants_no),
    hygienists_no: parseInt(payloadData?.hygienists_no),
    npe_patients_no: parseInt(payloadData?.npe_patients_no),
    recall_patient_no: parseInt(payloadData?.recall_patient_no),
    other_technologies_used: payloadData?.other_technologies_used,
    extra_comments: payloadData?.extra_comments,
    exam_duration_new_patient: payloadData?.exam_duration_new_patient?.toString(),
    is_photos_taken: payloadData?.is_photos_taken?.toString(),
    how_preintake_data_collected: payloadData?.how_preintake_data_collected?.toString(),
    how_often_md_history_updated: payloadData?.how_often_md_history_updated?.toString(),
  }
  try {
    const response = yield API.post(`/v2/clinic/${payload?.payload?.clinic_id}/practice-information`, newPayload)
    yield put(putPracticeInformationRequestSuccess(response.data?.detail))
    if (payloadData?.callback) {
      yield call(payloadData?.callback)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(putPracticeInformationRequestFailure(e?.message))
  }
}
function* putMsaAgreementSaga(payload: any): any {
  const payloadData = payload?.payload?.file
  try {
    const response = yield API.post(`/v2/clinic/${payload?.payload?.file?.get('clinic_id')}/msa`, payloadData)
    yield put(putMsaAgreementRequestSuccess(response.data?.detail))
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(putMsaAgreementRequestFailure(e?.message))
  }
}

function* getMsaAgreementSaga(payload: any): any {
  const payloadData = payload?.payload
  try {
    const response = yield API.get(`/v2/clinic/${payload?.payload?.clinic_id}/msa`)
    yield put(getMsaAgreementRequestSuccess(response.data?.detail))
    const data = response.data?.detail
    yield put(changeMsaAgreementRequest(data))

    if (payloadData?.callback) {
      yield call(payloadData?.callback)
    }
  } catch (e: any) {
    yield put(getMsaAgreementRequestFailure(e?.message))
  }
}

function* createClinicSaga() {
  yield takeEvery(CREATE_CLINIC_REQUEST, createClinicRequestSaga)
}

function* getClinicSaga() {
  yield takeEvery(GET_CLINIC_REQUEST, getClinicRequestSaga)
}

function* viewClinicSaga() {
  yield takeEvery(VIEW_CLINIC_REQUEST, viewClinicRequestSaga)
}

function* updateClinicSaga() {
  yield takeEvery(UPDATE_CLINIC_REQUEST, updateClinicRequestSaga)
}

function* deleteClinicSaga() {
  yield takeEvery(DELETE_CLINIC_REQUEST, deleteClinicRequestSaga)
}
function* updateClinicStatusSaga() {
  yield takeEvery(UPDATE_CLINIC_STATUS_REQUEST, updateClinicStatusRequestSaga)
}
function* getPMSSoftwareSaga() {
  yield takeEvery(GET_PMS_SOFTWARE_REQUEST, getPMSSoftwareRequestSaga)
}
function* getAllMemberSaga() {
  yield takeEvery(GET_ALL_MEMBER_REQUEST, getAllMemberRequestSaga)
}
function* getProcedureCodeSaga() {
  yield takeEvery(GET_PROCEDURE_CODE_REQUEST, getProcedureCodeRequestSaga)
}
function* updateProcedureCodeDataSaga() {
  yield takeEvery(PUT_PROCEDURE_DATA_REQUEST, updateProcedureDataRequestSaga)
}
function* getCSVDataWatch() {
  yield takeEvery(DOWNLOAD_CSV_FILE_REQUEST, getCSVData)
}

function* uploadCSVDataWatch() {
  yield takeEvery(UPLOAD_CSV_FILE_REQUEST, uploadCSVData)
}

function* getPracticeInformationWatch() {
  yield takeEvery(GET_PRACTICE_INFORMATION_REQUEST, getPracticeInformation)
}
function* putPracticeInformationWatch() {
  yield takeEvery(PUT_PRACTICE_INFORMATION_REQUEST, putPracticeInformation)
}
function* putMsaAgreementWatch() {
  yield takeEvery(PUT_MSA_AGREEMENT_REQUEST, putMsaAgreementSaga)
}

function* getMsaAgreementWatch() {
  yield takeEvery(GET_MSA_AGREEMENT_REQUEST, getMsaAgreementSaga)
}

export default function* clinicSaga() {
  yield all([
    createClinicSaga(),
    getClinicSaga(),
    viewClinicSaga(),
    updateClinicSaga(),
    deleteClinicSaga(),
    updateClinicStatusSaga(),
    getPMSSoftwareSaga(),
    getAllMemberSaga(),
    getProcedureCodeSaga(),
    updateProcedureCodeDataSaga(),
    getCSVDataWatch(),
    uploadCSVDataWatch(),
    getPracticeInformationWatch(),
    putPracticeInformationWatch(),
    putMsaAgreementWatch(),
    getMsaAgreementWatch(),
  ])
}
