import {
  CommunicationsFailurePayload,
  CommunicationsRequest,
  CommunicationsRequestFailure,
  CommunicationsRequestPayload,
  CommunicationsRequestSuccess,
  CommunicationsRequestSuccessPayload,
} from '../../schema/auth/communicationSchema'
import { COMMUNICATIONS_REQUEST, COMMUNICATIONS_REQUEST_FAIL, COMMUNICATIONS_REQUEST_SUCCESS } from '../actionTypes'

export const communicationsRequest = (payload: CommunicationsRequestPayload): CommunicationsRequest => ({
  type: COMMUNICATIONS_REQUEST,
  payload,
})

export const communicationsRequestSuccess = (payload: CommunicationsRequestSuccessPayload): CommunicationsRequestSuccess => ({
  type: COMMUNICATIONS_REQUEST_SUCCESS,
  payload,
})

export const communicationsRequestFailure = (payload: CommunicationsFailurePayload): CommunicationsRequestFailure => ({
  type: COMMUNICATIONS_REQUEST_FAIL,
  payload,
})
