import { all, put, takeEvery } from 'redux-saga/effects'
import { verifyOtpRequestFailure, sendOtpRequestFailure, sendOtpRequestSuccess, verifyOtpRequestSuccess } from '../../actions/authActions/otpAction'
import { VERIFY_OTP_REQUEST, SEND_OTP_REQUEST } from '../../actions/actionTypes'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'

function* verifyOtpRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/auth/verify/otp?is_owner=${payload?.payload?.isOwner}`, payload.payload)
    // TODO : if future need
    // yield put(
    //     verifyOtpRequestSuccess({
    //         verifyOTPData: response
    //     })
    // );

    if (response) {
      yield put(verifyOtpRequestSuccess({}))
      successToaster(response?.data?.meta?.message)
      if (payload.payload.callback) {
        payload.payload.callback(response)
      }
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      verifyOtpRequestFailure({
        verifyOTPError: e?.message,
      })
    )
  }
}

function* sendOtpRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/auth/send/otp`, payload?.payload)
    yield put(
      sendOtpRequestSuccess({
        mobile: response.mobile,
      })
    )
    if (response) {
      successToaster(response?.data?.meta?.message)
      if (payload.payload.callback) {
        payload.payload.callback()
      }
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      sendOtpRequestFailure({
        sendOTPError: e?.message,
      })
    )
  }
}

function* verifyOtpSaga() {
  yield takeEvery(VERIFY_OTP_REQUEST, verifyOtpRequestSaga)
}

function* sendOtpSaga() {
  yield takeEvery(SEND_OTP_REQUEST, sendOtpRequestSaga)
}

export default function* otpSaga() {
  yield all([verifyOtpSaga(), sendOtpSaga()])
}
