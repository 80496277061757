import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';

import ellipseTop from "../../../../assets/images/v2/landingPage/ellipseTop.png";
import ellipseDown from "../../../../assets/images/v2/landingPage/ellipseDown.png";

import clinicBoxOne from "../../../../assets/images/v2/landingPage/clinicExam/clinicBoxOne.png";
import clinicBoxTwo from "../../../../assets/images/v2/landingPage/clinicExam/clinicBoxTwo.png";
import clinicBoxThree from "../../../../assets/images/v2/landingPage/clinicExam/clinicBoxThree.png";
import clinicBoxFour from "../../../../assets/images/v2/landingPage/clinicExam/clinicBoxFour.png";
import theme from '../../../../theme';

const clinicBoxItemOne = [
  {
    imageLogo: <img src={clinicBoxOne} alt="clinicBoxOne" />,
    heading: "Complete Member Management",
    description: "Gain full control over your team with a comprehensive list of members. Easily access all the essential information."
  },
  {
    imageLogo: <img src={clinicBoxTwo} alt="clinicBoxTwo" />,
    heading: "Simplified Actions",
    description: "Take swift actions to maintain an up-to-date and efficient team. Manage them from the list with just a few clicks."
  }
];

const clinicBoxItemTwo = [
  {
    imageLogo: <img src={clinicBoxThree} alt="clinicBoxThree" />,
    heading: "Quick Search Capability",
    description: "No more digging through endless lists; simply enter the name or relevant details to locate the desired member efficiently."
  },
  {
    imageLogo: <img src={clinicBoxFour} alt="clinicBoxFour" />,
    heading: "Seamlessly Add New Members",
    description: "Our intuitive interface guides you through the process, allowing you to assign roles and assign them to the appropriate clinic."
  }
];


const useStyles = makeStyles((theme: any) => ({
  headerText: {
    fontSize: '40px',
    fontWeight: '600',
    lineHeight: '56px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      lineHeight: '45px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '25px',
      lineHeight: '35px'
    },
    [theme.breakpoints.down(768)]: {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '20px',
      lineHeight: '25px'
    },
  },
  descriptionText: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.black50,
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      lineHeight: '25px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '16px',
      lineHeight: '22px'
    },
    [theme.breakpoints.down(768)]: {
      textAlign: 'start',
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
      lineHeight: '20px'
    },
  },
  logoItemsBox: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '20px',
    padding: '30px 26px',
    height: '280px',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      '& .second-child': {
        color: theme.palette.primary.main,
      },
      border: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('md')]: {
      height: '295px'
    },
    [theme.breakpoints.between(700, 960)]: {
      height: '255px'
    },
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content'
    }
  },
  logoItemsText: {
    fontSize: '18px',
    lineHeight: '25px',
    textTransform: 'uppercase',
    fontWeight: 700,
    margin: '10px 0'
  },
  logoItemsDescription: {
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center'
  },
  gridMainBox: {
    [theme.breakpoints.down(1100)]: {
      padding: '0 50px'
    },
    [theme.breakpoints.down(450)]: {
      padding: '0'
    },
  },
  gridBoxOne: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down(1100)]: {
      justifyContent: 'center'
    },
  },
  gridBoxTwo: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down(1100)]: {
      justifyContent: 'center'
    },
  }
}));

const FourBox = () => {
  const classes = useStyles();
  const palette = theme.palette

  return (
    <React.Fragment>
      <Box className={`d-flex justify-center mt-30`}>

        <Box maxWidth={1300}>
          <Box paddingLeft={'100px'} mt={'45px'} className={classes.gridMainBox}>
            <Grid container spacing={2} position={'relative'} className={classes.gridBoxOne}>
              <Box position={'absolute'} top={'35%'} right={'13%'}>
                <img src={ellipseTop} alt="ellipseTop" height={'354px'} width={'354px'} />
              </Box>
              {
                clinicBoxItemOne && clinicBoxItemOne?.map((res: any, index: number) =>
                  <Grid item={true} key={index} xl={3} lg={3} md={4} sm={6} xs={12}
                    sx={{
                      zIndex: 1024,
                      minWidth: '400px',
                      [theme.breakpoints.down('sm')]: {
                        margin: '15px',
                        zIndex: 0,
                      },
                      [theme.breakpoints.down(450)]: {
                        minWidth: '300px'
                      }
                    }}>
                    <Box className={`${classes.logoItemsBox} d-flex flex-column`}>
                      <Box className={`d-flex justify-center`}>
                        {res?.imageLogo}
                      </Box>
                      <Typography className={`${classes.logoItemsText} d-flex justify-center second-child`}>
                        {res?.heading}
                      </Typography>
                      <Typography className={`${classes.logoItemsDescription} d-flex justify-center`}>
                        {res?.description}
                      </Typography>
                    </Box>
                  </Grid>
                )}
            </Grid>
          </Box>

          <Box paddingRight={'100px'} mt={'15px'} className={classes.gridMainBox}>
            <Grid container spacing={2} position={'relative'} className={classes.gridBoxTwo}>
              <Box position={'absolute'} left={'15%'} top={'-40%'}>
                <img src={ellipseDown} alt="ellipseDown" height={'354px'} width={'354px'} />
              </Box>
              {
                clinicBoxItemTwo && clinicBoxItemTwo?.map((res: any, index: number) =>
                  <Grid item={true} key={index} xl={3} lg={3} md={4} sm={6} xs={12}
                    sx={{
                      zIndex: 1024,
                      minWidth: '400px',
                      [theme.breakpoints.down('sm')]: {
                        margin: '15px',
                        zIndex: 0,
                      },
                      [theme.breakpoints.down(450)]: {
                        minWidth: '300px'
                      }
                    }}>
                    <Box className={`${classes.logoItemsBox} d-flex flex-column`}>
                      <Box className={`d-flex justify-center`}>
                        {res?.imageLogo}
                      </Box>
                      <Typography className={`${classes.logoItemsText} d-flex justify-center second-child`}>
                        {res?.heading}
                      </Typography>
                      <Typography className={`${classes.logoItemsDescription} d-flex justify-center`}>
                        {res?.description}
                      </Typography>
                    </Box>
                  </Grid>
                )}
            </Grid>
          </Box>
        </Box>
      </Box>

    </React.Fragment >
  )
}

export default FourBox;