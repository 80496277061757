import {
    CLINIC_ADMIN_SIGNUP_REQUEST,
    CLINIC_ADMIN_SIGNUP_REQUEST_SUCCESS,
    CLINIC_ADMIN_SIGNUP_REQUEST_FAILURE
} from '../../actions/actionTypes'
import {
    ClinicAdminSignUpInterface,
    clinicAdminSignupActions,
} from '../../schema/auth/signUpSchema'

const initialState: ClinicAdminSignUpInterface = {
    signUpLoader: false,
    signUpData: null,
    signUpError: ""
}
const clinicAdminSignUpReducer = (
    state = initialState,
    action: clinicAdminSignupActions
) => {
    switch (action.type) {
        case CLINIC_ADMIN_SIGNUP_REQUEST:
            return {
                ...state,
                signUpLoader: true,
            };
        case CLINIC_ADMIN_SIGNUP_REQUEST_SUCCESS:
            return {
                ...state,
                signUpLoader: false,
                signUpData: action.payload,
                signUpError: null,
            };
        case CLINIC_ADMIN_SIGNUP_REQUEST_FAILURE:
            return {
                ...state,
                signUpLoader: false,
                signUpData: null,
                signUpError: action.payload.signUpError,
            };
        default:
            return {
                ...state
            };
    }
}
export default clinicAdminSignUpReducer