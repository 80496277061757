import { COMMUNICATIONS_REQUEST, COMMUNICATIONS_REQUEST_FAIL, COMMUNICATIONS_REQUEST_SUCCESS } from '../../actions/actionTypes'
import { communicationsActions, CommunicationsInterface } from '../../schema/auth/communicationSchema'

const initialState: CommunicationsInterface = {
  communicationLoader: false,
  communication_preferences: null,
  communicationError: '',
}

const communicationReducer = (state = initialState, action: communicationsActions) => {
  switch (action.type) {
    case COMMUNICATIONS_REQUEST:
      return {
        ...state,
        communicationLoader: true,
      }
    case COMMUNICATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        communicationLoader: false,
        communication_preferences: action?.payload,
        communicationError: null,
      }
    case COMMUNICATIONS_REQUEST_FAIL:
      return {
        ...state,
        communicationLoader: false,
        communication_preferences: null,
        communicationError: action?.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default communicationReducer
