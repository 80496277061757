import {
  ClinicAdminChangePasswordFailurePayload,
  ClinicAdminChangePasswordRequest,
  ClinicAdminChangePasswordRequestFailure,
  ClinicAdminChangePasswordRequestPayload,
  ClinicAdminChangePasswordRequestSuccess,
  ClinicAdminChangePasswordRequestSuccessPayload,
} from '../../schema/auth/changePasswordSchema'
import {
  CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST,
  CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_FAILURE,
  CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_SUCCESS,
} from '../actionTypes'

export const clinicAdminChangePasswordRequest = (payload: ClinicAdminChangePasswordRequestPayload): ClinicAdminChangePasswordRequest => ({
  type: CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST,
  payload,
})

export const clinicAdminChangePasswordRequestSuccess = (
  payload: ClinicAdminChangePasswordRequestSuccessPayload
): ClinicAdminChangePasswordRequestSuccess => ({
  type: CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_SUCCESS,
  payload,
})

export const clinicAdminChangePasswordRequestFailure = (
  payload: ClinicAdminChangePasswordFailurePayload
): ClinicAdminChangePasswordRequestFailure => ({
  type: CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST_FAILURE,
  payload,
})
