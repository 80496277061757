import { all, put, takeEvery } from 'redux-saga/effects'
import { SEND_EMAIL_VERIFICATION_REQUEST, VERIFIED_EMAIL_REQUEST } from '../../actions/actionTypes'
import API from '../../../utils/api'
import {
  verifiedEmailRequestSuccess,
  verifiedEmailRequestFailure,
  sendEmailVerificationRequestSuccess,
  sendEmailVerificationRequestFailure,
} from '../../actions/authActions/verifiedEmailAction'
import { errorToaster, successToaster } from '../../../utils/helper'

function* verifiedEmailRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/auth/verify/email`, payload?.payload)
    successToaster(response?.data?.meta?.message)
    yield put(
      verifiedEmailRequestSuccess({
        verifiedEmailData: response?.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      verifiedEmailRequestFailure({
        verifiedEmailError: e?.message,
      })
    )
  }
}

function* sendEmailVerificationRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`v2/auth/send/email/token`, payload?.payload)
    successToaster(response?.data?.meta?.message)
    yield put(
      sendEmailVerificationRequestSuccess({
        sendEmailVerification: response?.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      sendEmailVerificationRequestFailure({
        sendEmailVerificationError: e?.message,
      })
    )
  }
}

function* postVerifiedEmailSaga() {
  yield takeEvery(VERIFIED_EMAIL_REQUEST, verifiedEmailRequestSaga)
}

function* postSendVerificationSaga() {
  yield takeEvery(SEND_EMAIL_VERIFICATION_REQUEST, sendEmailVerificationRequestSaga)
}
export default function* verifiedEmailSaga() {
  yield all([postVerifiedEmailSaga(), postSendVerificationSaga()])
}
