import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, getAccountData, getOwnerData, successToaster } from '../../../utils/helper'
import {
  CREATE_APPOINTMENT_REQUEST,
  GET_ALL_PATIENT_REQUEST,
  GET_ALL_STAFF_BY_CLINIC_REQUEST,
  GET_APPOINTMENT_COUNT_REQUEST,
  GET_APPOINTMENT_REQUEST,
  GET_CHECKOUT_DATA_REQUEST,
  GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST,
  PUT_CHECKOUT_DATA_REQUEST,
  UPDATE_APPOINTMENT_REQUEST,
  VIEW_APPOINTMENT_REQUEST,
} from '../../actions/actionTypes'
import {
  createAppointmentRequestFailure,
  createAppointmentRequestSuccess,
  getAllPatientRequestFailure,
  getAllPatientRequestSuccess,
  getAllStaffByClinicRequestFailure,
  getAllStaffByClinicRequestSuccess,
  getAppointmentCacheDataClearRequest,
  getAppointmentCountRequestFailure,
  getAppointmentCountRequestSuccess,
  getAppointmentRequestFailure,
  getAppointmentRequestSuccess,
  getCheckoutDataRequestFailureAction,
  getCheckoutDataRequestSuccessAction,
  getCheckoutProviderDropdownRequestFailureAction,
  getCheckoutProviderDropdownRequestSuccessAction,
  putCheckoutDataRequestFailureAction,
  putCheckoutDataRequestSuccessAction,
  updateAppointmentRequestFailure,
  updateAppointmentRequestSuccess,
  updatePreauthChecklistDataAction,
  viewAppointmentCacheDataClearRequest,
  viewAppointmentRequestFailure,
  viewAppointmentRequestSuccess,
} from '../../actions/appointment/appointmentAction'

// Get appointment
function* getAppointmentRequestSaga(payload: any): any {
  const accountData = getAccountData()

  yield put(getAppointmentCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
      { key: 'status', value: payload?.payload?.status },
      { key: 'pre_intake_status', value: payload?.payload?.pre_intake_status },
      { key: 'time_period', value: payload?.payload?.time_period },
      { key: 'appointment_type', value: payload?.payload?.appointment_type },
      { key: 'sort_by', value: payload?.payload?.sort_by },
      { key: 'desc', value: payload.payload.desc },
      { key: 'from_date', value: payload.payload.from_date },
      { key: 'to_date', value: payload.payload.to_date },
    ]
    if (accountData?.is_staff || payload?.payload?.clinic_id) {
      params.push({ key: 'clinic_id', value: payload?.payload?.clinic_id })
    }
    if (payload?.payload?.patient_id) {
      params.push({ key: 'patient_id', value: payload?.payload?.patient_id })
    }
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (typeof p.value === 'boolean' ? p?.value !== null : p?.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (typeof p.value === 'boolean' ? p?.value !== null : p?.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const response = yield API.get(`/v2/appointment/owner/get/all${tmpUrl}`)
    yield put(getAppointmentRequestSuccess({ getAppointmentData: response?.data }))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getAppointmentRequestFailure({ getAppointmentError: e?.message }))
  }
}

// Create Appointment
function* createAppointmentRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/appointment/owner`, payload?.payload?.data)
    yield call(payload?.payload?.callback, response?.data)
    yield put(createAppointmentRequestSuccess({ createAppointmentData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(createAppointmentRequestFailure({ createAppointmentError: e?.message }))
  }
}

// update appointment
function* updateAppointmentRequestSaga(payload: any): any {
  const accountData = getAccountData()

  try {
    const params = []
    if (accountData?.is_staff) {
      params.push({ key: 'clinic_id', value: payload?.payload?.clinic_id })
    }
    let tmpUrl = params?.length > 0 ? `&` : ''
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })

    const newUrl = `/v2/appointment/owner?appointment_id=${payload?.payload?.appointment_id}${tmpUrl}`
    const url = newUrl
    const response = yield API.put(url, payload?.payload?.data)
    yield call(payload?.payload?.callback, response?.data)
    successToaster(response?.data?.meta?.message)
    yield put(
      updateAppointmentRequestSuccess({
        updateAppointmentData: response?.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      updateAppointmentRequestFailure({
        updateAppointmentError: e?.message,
      })
    )
  }
}

// view appointment details
function* viewAppointmentRequestSaga(payload: any): any {
  const accountData = getAccountData()

  yield put(viewAppointmentCacheDataClearRequest())
  try {
    const params = []
    if (accountData?.is_staff) {
      params.push({ key: 'clinic_id', value: payload?.payload?.clinic_id })
    }
    let tmpUrl = params?.length > 0 ? `?` : ''
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/appointment/owner/${payload?.payload?.appointment_id}${tmpUrl}`
    const url = newUrl
    const response = yield API.get(url)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data?.detail)
    }
    yield put(
      viewAppointmentRequestSuccess({
        viewAppointmentData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      viewAppointmentRequestFailure({
        viewAppointmentError: e?.message,
      })
    )
  }
}

// get all patient
function* getAllPatientRequestSaga(payload: any): any {
  try {
    const params = []
    if (payload?.payload?.clinic_id) {
      params.push({ key: 'clinic_id', value: payload?.payload?.clinic_id })
    }
    let tmpUrl = params?.length > 0 ? `?` : ''
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/patient/details/all${tmpUrl}`
    const url = newUrl
    const response = yield API.get(url)
    yield put(
      getAllPatientRequestSuccess({
        allPatientData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getAllPatientRequestFailure({
        allPatientError: e?.message,
      })
    )
  }
}

// get all staff by clinic
function* getAllStaffByClinicRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`/v2/staff/clinic/${payload?.payload?.clinic_id}`)
    yield put(
      getAllStaffByClinicRequestSuccess({
        allStaffData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getAllStaffByClinicRequestFailure({
        allStaffError: e?.message,
      })
    )
  }
}

// get checkout data
function* checkoutDataRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`v2/treatment/checklist/${payload.payload?.appointment_id}`)

    const data = response?.data?.detail

    yield put(getCheckoutDataRequestSuccessAction(data))

    yield put(
      updatePreauthChecklistDataAction({
        pa: data?.checklist?.pa,
        bw: data?.checklist?.bw,
        panoramoic_xray: data?.checklist?.panoramoic_xray,
        perio_charting: data?.checklist?.perio_charting,
        photos: data?.checklist?.photos,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getCheckoutDataRequestFailureAction({
        error: e?.message,
      })
    )
  }
}

function* checkoutProviderDropdownRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`v2/staff/all/clinic/${payload.payload?.clinic_id}`)

    const data = response?.data?.detail

    yield put(getCheckoutProviderDropdownRequestSuccessAction(data))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getCheckoutProviderDropdownRequestFailureAction({
        error: e?.message,
      })
    )
  }
}

function* putCheckoutDataRequestSaga(payload: any): any {
  const handleProcedureCode = (treatmentData: any) => {
    const newArray = [] as any
    if (treatmentData?.treatment_status === 'approved') {
      treatmentData?.treatment_procedure?.map((res: any) => {
        const newData = {
          treatment_cart_id: res?.treatment_cart_id,
          fees: parseFloat(res?.fees),
        }
        if (res?.fees) {
          newArray?.push(newData)
          return newArray
        }
      })
    } else {
      const newAlternativeData = {
        treatment_cart_id: treatmentData?.alternative_procedure?.treatment_cart_id,
        fees: parseFloat(treatmentData?.alternative_procedure?.fees),
      }
      if (treatmentData?.alternative_procedure?.fees) {
        newArray?.push(newAlternativeData)
        return newArray
      }
    }
    return newArray
  }

  const formattedPayloadTreatmentPlan = [
    ...payload?.payload?.data?.treatments?.map((treatmentGroup: any, index: number) => ({
      position: index,
      treatment_id: treatmentGroup?.treatment_id,
      time: treatmentGroup?.time || null,
      notes: treatmentGroup?.notes || null,
      provider_id: treatmentGroup?.provider_id || null,
      treatments: treatmentGroup.treatments?.map((treatment: any) => ({
        treatment_details_id: treatment.treatment_details_id,
        status: treatment.status,
        insurance_status: treatment.insurance_status,
        treatment_fees: handleProcedureCode(treatment),
      })),
    })),
  ] as any

  const updatedCheckoutData = {
    appointment_id: payload?.payload?.data?.appointmentID,
    treatments: formattedPayloadTreatmentPlan,
    checklist: payload?.payload?.data?.checklist,
    hygiene_regime: payload?.payload?.data?.hygiene_regime,
    submit: payload?.payload?.submit,
  } as any

  updatedCheckoutData?.treatments &&
    updatedCheckoutData?.treatments.map((res: any) => {
      res?.assistantID === null && delete res?.assistantID
      res?.dentistID === null && delete res?.dentistID
      res?.hygienistID === null && delete res?.hygienistID
    })

  try {
    const response = yield API.post(`/v2/treatment/checklist`, updatedCheckoutData)

    yield put(putCheckoutDataRequestSuccessAction({ updatedCheckoutData }))
    successToaster(response?.data?.meta?.message)

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      putCheckoutDataRequestFailureAction({
        error: e?.detail,
      })
    )
  }
}

// get appointment count
function* getAppointmentCountRequestSaga(payload: any): any {
  const accountData = getAccountData()

  try {
    const params = [
      { key: 'time_period', value: payload?.payload?.time_period },
    ]
    if (payload?.payload?.status) {
      params.push({ key: 'status', value: payload?.payload?.status })
    }
    if (accountData?.is_staff || payload?.payload?.clinic_id) {
      params.push({ key: 'clinic_id', value: payload?.payload?.clinic_id })
    }
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const response = yield API.get(`/v2/dashboard/appointments/count/owner${tmpUrl}`)
    yield put(getAppointmentCountRequestSuccess(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getAppointmentCountRequestFailure(e?.message))
  }
}

function* getAppointmentSaga() {
  yield takeEvery(GET_APPOINTMENT_REQUEST, getAppointmentRequestSaga)
}

function* createAppointmentSaga() {
  yield takeEvery(CREATE_APPOINTMENT_REQUEST, createAppointmentRequestSaga)
}

function* updateAppointmentSaga() {
  yield takeEvery(UPDATE_APPOINTMENT_REQUEST, updateAppointmentRequestSaga)
}

function* viewAppointmentSaga() {
  yield takeEvery(VIEW_APPOINTMENT_REQUEST, viewAppointmentRequestSaga)
}

function* getAllPatientSaga() {
  yield takeEvery(GET_ALL_PATIENT_REQUEST, getAllPatientRequestSaga)
}

function* getAllStaffByClinicSaga() {
  yield takeEvery(GET_ALL_STAFF_BY_CLINIC_REQUEST, getAllStaffByClinicRequestSaga)
}

function* checkoutDataWatch() {
  yield takeEvery(GET_CHECKOUT_DATA_REQUEST, checkoutDataRequestSaga)
}

function* checkoutDropdownWatch() {
  yield takeEvery(GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST, checkoutProviderDropdownRequestSaga)
}

function* putCheckoutDataWatch() {
  yield takeEvery(PUT_CHECKOUT_DATA_REQUEST, putCheckoutDataRequestSaga)
}

// getAppointmentCountRequestSaga
function* getAppointmentCountWatch() {
  yield takeEvery(GET_APPOINTMENT_COUNT_REQUEST, getAppointmentCountRequestSaga)
}

export default function* appointmentSaga() {
  yield all([
    getAppointmentSaga(),
    createAppointmentSaga(),
    updateAppointmentSaga(),
    viewAppointmentSaga(),
    getAllPatientSaga(),
    getAllStaffByClinicSaga(),
    checkoutDataWatch(),
    checkoutDropdownWatch(),
    putCheckoutDataWatch(),
    getAppointmentCountWatch(),
  ])
}
