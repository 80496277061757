import {
    CLINIC_ADMIN_SIGNUP_REQUEST,
    CLINIC_ADMIN_SIGNUP_REQUEST_SUCCESS,
    CLINIC_ADMIN_SIGNUP_REQUEST_FAILURE
} from '../../actions/actionTypes'

import {
    ClinicAdminSignUpRequestPayload,
    ClinicAdminSignUpRequestSuccessPayload,
    ClinicAdminSignUpFailurePayload,
    ClinicAdminSignUpRequest,
    ClinicAdminSignUpRequestSuccess,
    ClinicAdminSignUpRequestFailure
} from '../../schema/auth/signUpSchema'

export const clinicAdminSignUpRequest = (payload: ClinicAdminSignUpRequestPayload): ClinicAdminSignUpRequest => ({
    type: CLINIC_ADMIN_SIGNUP_REQUEST,
    payload,
});

export const clinicAdminSignUpRequestSuccess = (payload: ClinicAdminSignUpRequestSuccessPayload): ClinicAdminSignUpRequestSuccess => ({
    type: CLINIC_ADMIN_SIGNUP_REQUEST_SUCCESS,
    payload,
});

export const clinicAdminSignUpRequestFailure = (payload: ClinicAdminSignUpFailurePayload): ClinicAdminSignUpRequestFailure => ({
    type: CLINIC_ADMIN_SIGNUP_REQUEST_FAILURE,
    payload,
});
