import { POST_EDIT_MEMBER_ROLE_REQUEST, UPDATE_ASSIGN_ROLE_TO_MEMBER_REQUEST } from '../../actions/actionTypes'

const initialState: any = {
  detail: { clinic_id: null, positions: [], assign_modules: [] },
  editMemberRole: {},
}

const assignRoleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_ASSIGN_ROLE_TO_MEMBER_REQUEST:
      return {
        detail: action?.payload,
      }
    case POST_EDIT_MEMBER_ROLE_REQUEST:
      return {
        ...state,
        editMemberRole: action?.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default assignRoleReducer
