import {
    GET_MODULES_REQUEST,
    GET_MODULES_REQUEST_SUCCESS,
    GET_MODULES_REQUEST_FAILURE,
    GET_CLINIC_STAFF_REQUEST,
    GET_CLINIC_STAFF_REQUEST_SUCCESS,
    GET_CLINIC_STAFF_REQUEST_FAILURE,
} from '../../actions/actionTypes'
import {
    ModuleInterface,
    getModulesAction
} from '../../schema/modules/modulesSchema'

const initialState: ModuleInterface = {
    moduleLoader: false,
    moduleData: null,
    moduleError: null,
    clinicByStaffLoader: false,
    clinicByStaffData: null,
    clinicByStaffError: null
}

const moduleReducer = (
    state = initialState,
    action: getModulesAction
) => {
    switch (action.type) {
        case GET_MODULES_REQUEST:
            return {
                ...state,
                moduleLoader: true
            }
        case GET_MODULES_REQUEST_SUCCESS:
            return {
                ...state,
                moduleLoader: false,
                moduleData: action?.payload?.moduleData,
                moduleError: null
            }
        case GET_MODULES_REQUEST_FAILURE:
            return {
                ...state,
                moduleLoader: false,
                moduleData: null,
                moduleError: action?.payload?.moduleError
            }
        case GET_CLINIC_STAFF_REQUEST:
            return {
                ...state,
                clinicByStaffLoader: true
            }
        case GET_CLINIC_STAFF_REQUEST_SUCCESS:
            return {
                ...state,
                clinicByStaffLoader: false,
                clinicByStaffData: action?.payload?.clinicByStaffData,
                clinicByStaffError: null
            }
        case GET_CLINIC_STAFF_REQUEST_FAILURE:
            return {
                ...state,
                clinicByStaffLoader: false,
                clinicByStaffData: null,
                clinicByStaffError: action?.payload?.clinicByStaffError
            }
        default:
            return {
                ...state
            };
    }
}

export default moduleReducer