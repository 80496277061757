import {
  VerifyUserEmailRequest,
  VerifyUserEmailRequestFailure,
  VerifyUserEmailRequestFailurePayload,
  VerifyUserEmailRequestPayload,
  VerifyUserEmailRequestSuccess,
  VerifyUserEmailRequestSuccessPayload,
} from '../../schema/commonFilter/verifyUserEmail'
import { VERIFY_USER_EMAIL_REQUEST, VERIFY_USER_EMAIL_REQUEST_FAIL, VERIFY_USER_EMAIL_REQUEST_SUCCESS } from '../actionTypes'

// Verify Email for User already exist
export const verifyUserEmailRequestAction = (payload: VerifyUserEmailRequestPayload): VerifyUserEmailRequest => ({
  type: VERIFY_USER_EMAIL_REQUEST,
  payload,
})
export const verifyUserEmailRequestSuccessAction = (payload: VerifyUserEmailRequestSuccessPayload): VerifyUserEmailRequestSuccess => ({
  type: VERIFY_USER_EMAIL_REQUEST_SUCCESS,
  payload,
})
export const verifyUserEmailRequestFailureAction = (payload: VerifyUserEmailRequestFailurePayload): VerifyUserEmailRequestFailure => ({
  type: VERIFY_USER_EMAIL_REQUEST_FAIL,
  payload,
})
