import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import linkedIN from "../../../assets/images/v2/landingPage/linkedIN.png";
import youtube from "../../../assets/images/v2/landingPage/youtube.png";
import theme from '../../../theme';
import {currentYear} from "../../../utils/helper";


const useStyles = makeStyles((theme: any) => ({
  footerBox: {
    position: 'fixed',
    height: '90px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    padding: '0px 24px',
    bottom: '0px',
    width: '100%',
    zIndex: '2048'
  },
  imageClass: {
    marginTop: '8px'
  }
}));

const FooterLandingPage = () => {
  const classes = useStyles();
  return (
    <Box className={`${classes.footerBox}`}>
      <Box maxWidth={'100%'} className={`d-flex justify-content-between align-center h-100`}
        sx={{
          [theme.breakpoints.down(550)]: {
            flexDirection: 'column',
            justifyContent: 'center'
          },
        }}
      >
        <Typography>
          {`Terms of Use | Privacy Policy © ${currentYear} OraQ AI Inc. All rights reserved.`}
        </Typography>
        <Box className={`d-flex align-center`} gap={'20px'}
          sx={{
            [theme.breakpoints.down(550)]: {
              gap: '10px'
            },
          }}
        >
          <img src={linkedIN} alt="linkedIn" height={'25px'} width={'25px'} />
          <img src={youtube} alt="youtube" height={'25px'} width={'25px'} className={classes.imageClass} />
        </Box>
      </Box>
    </Box>
  )
}

export default FooterLandingPage;