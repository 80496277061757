import {
  CreatePasswordRequest,
  CreatePasswordRequestFailure,
  CreatePasswordRequestFailurePayload,
  CreatePasswordRequestPayload,
  CreatePasswordRequestSuccess,
  CreatePasswordRequestSuccessPayload,
  UpdateCreatePasswordData,
  UpdateCreatePasswordError,
} from '../../schema/auth/createPasswordSchema'
import {
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_REQUEST_FAILURE,
  CREATE_PASSWORD_REQUEST_SUCCESS,
  UPDATE_CREATE_PASSWORD_DATA,
  UPDATE_CREATE_PASSWORD_ERROR,
} from '../actionTypes'

export const createPasswordRequest = (payload: CreatePasswordRequestPayload): CreatePasswordRequest => ({
  type: CREATE_PASSWORD_REQUEST,
  payload,
})

export const createPasswordRequestSuccess = (payload: CreatePasswordRequestSuccessPayload): CreatePasswordRequestSuccess => ({
  type: CREATE_PASSWORD_REQUEST_SUCCESS,
  payload,
})

export const createPasswordRequestFailure = (payload: CreatePasswordRequestFailurePayload): CreatePasswordRequestFailure => ({
  type: CREATE_PASSWORD_REQUEST_FAILURE,
  payload,
})

export const updateCreatePasswordDataAction = (payload: any): UpdateCreatePasswordData => ({
  type: UPDATE_CREATE_PASSWORD_DATA,
  payload,
})
export const updateCreatePasswordErrorAction = (payload: any): UpdateCreatePasswordError => ({
  type: UPDATE_CREATE_PASSWORD_ERROR,
  payload,
})
