import {
    CLINIC_ADMIN_LOGIN_REQUEST,
    CLINIC_ADMIN_LOGIN_REQUEST_SUCCESS,
    CLINIC_ADMIN_LOGIN_REQUEST_FAILURE
} from '../../actions/actionTypes'
import {
    ClinicAdminLoginRequestPayload,
    ClinicAdminLoginRequestSuccessPayload,
    ClinicAdminLoginFailurePayload,
    ClinicAdminLoginRequest,
    ClinicAdminLoginRequestSuccess,
    ClinicAdminLoginRequestFailure
} from '../../schema/auth/loginSchema'

export const clinicAdminLoginRequest = (payload: ClinicAdminLoginRequestPayload): ClinicAdminLoginRequest => ({
    type: CLINIC_ADMIN_LOGIN_REQUEST,
    payload,
});

export const clinicAdminLoginRequestSuccess = (payload: ClinicAdminLoginRequestSuccessPayload): ClinicAdminLoginRequestSuccess => ({
    type: CLINIC_ADMIN_LOGIN_REQUEST_SUCCESS,
    payload,
});

export const clinicAdminLoginRequestFailure = (payload: ClinicAdminLoginFailurePayload): ClinicAdminLoginRequestFailure => ({
    type: CLINIC_ADMIN_LOGIN_REQUEST_FAILURE,
    payload,
});
