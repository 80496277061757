import {
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST,
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS,
    CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE,
    IS_MULTIPLE_ACCOUNT_CLINIC_ADMIN_FORGOT_PASSWORD_POPUP
} from '../../actions/actionTypes'

import {
    ClinicAdminForgotPasswordRequestPayload,
    ClinicAdminForgotPasswordRequestSuccessPayload,
    ClinicAdminForgotPasswordFailurePayload,
    ClinicAdminForgotPasswordRequest,
    ClinicAdminForgotPasswordRequestSuccess,
    ClinicAdminForgotPasswordRequestFailure,
    IsMultipleAccountClinicAdminForgotPasswordPopUp
} from '../../schema/auth/forgotPasswordSchema'

export const clinicAdminForgotPasswordRequest = (payload: ClinicAdminForgotPasswordRequestPayload): ClinicAdminForgotPasswordRequest => ({
    type: CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST,
    payload,
});

export const clinicAdminForgotPasswordRequestSuccess = (payload: ClinicAdminForgotPasswordRequestSuccessPayload): ClinicAdminForgotPasswordRequestSuccess => ({
    type: CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_SUCCESS,
    payload,
});

export const clinicAdminForgotPasswordRequestFailure = (payload: ClinicAdminForgotPasswordFailurePayload): ClinicAdminForgotPasswordRequestFailure => ({
    type: CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST_FAILURE,
    payload,
});

export const isMultipleAccountClinicAdminForgotPasswordPopUp = (payload: any): IsMultipleAccountClinicAdminForgotPasswordPopUp => ({
    type: IS_MULTIPLE_ACCOUNT_CLINIC_ADMIN_FORGOT_PASSWORD_POPUP,
    payload,
});
