import { all, call, put, takeEvery } from 'redux-saga/effects'
import { CLINIC_ADMIN_RESET_PASSWORD_REQUEST } from '../../actions/actionTypes'
import {
  clinicAdminResetPasswordRequestSuccess,
  clinicAdminResetPasswordRequestFailure,
} from '../../actions/authActions/resetPasswordAction'
import { toast } from 'react-toastify'
import API from '../../../utils/api'
import { errorToaster } from '../../../utils/helper'

function* clinicAdminResetPasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`v2/auth/reset/password`, payload.payload)
    toast.success(response.data?.meta?.message)
    yield call(payload.payload.callback, response.data)
    yield put(
      clinicAdminResetPasswordRequestSuccess({
        resetPasswordData: response.data,
      })
    )
  } catch (e: any) {
    //TODO: update toast according to status code
    errorToaster(e?.message)
    yield put(
      clinicAdminResetPasswordRequestFailure({
        resetPasswordError: e?.message,
      })
    )
  }
}

function* postClinicAdminResetPasswordSaga() {
  yield takeEvery(CLINIC_ADMIN_RESET_PASSWORD_REQUEST, clinicAdminResetPasswordRequestSaga)
}

export default function* clinicAdminResetPasswordSaga() {
  yield all([postClinicAdminResetPasswordSaga()])
}
