import { APPROVE_CLINIC_FAILURE, APPROVE_CLINIC_REQUEST, APPROVE_CLINIC_SUCCESS, APPROVE_CLINIC_FINISHED } from '../../actions/actionTypes'

export const approveClinicAction = (payload: any): any => ({
  type: APPROVE_CLINIC_REQUEST,
  payload,
})
export const approveClinicSuccessAction = (): any => ({
  type: APPROVE_CLINIC_SUCCESS,
})
export const approveClinicFailureAction = (): any => ({
  type: APPROVE_CLINIC_FAILURE,
})
export const approveClinicFinishedAction = (): any => ({
  type: APPROVE_CLINIC_FINISHED
})