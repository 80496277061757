import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import FourBox from './fourBox';
import theme from '../../../theme';


const useStyles = makeStyles((theme: any) => ({
  headerText: {
    fontSize: '40px',
    fontWeight: '600',
    lineHeight: '56px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      lineHeight: '45px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '25px',
      lineHeight: '35px'
    },
    [theme.breakpoints.down(768)]: {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '20px',
      lineHeight: '25px'
    },
  },
  descriptionText: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.black50,
    textAlign: 'center',
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      lineHeight: '25px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '16px',
      lineHeight: '22px'
    },
    [theme.breakpoints.down(768)]: {
      textAlign: 'start',
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
      lineHeight: '20px'
    },
  },
  purpleColor: {
    color: theme.palette.v2.secondary
  },
  greenMainColor: {
    color: theme.palette.primary.main
  }
}));

const FourDataWithHeader = () => {
  const classes = useStyles();
  const palette = theme.palette

  return (
    <React.Fragment>
      <Box className={`d-flex justify-center mt-30`}>
        <Grid container={true} className={`d-flex justify-center flex-col`} p={'0 30px'} mt={'30px !important'} maxWidth={'810px'} margin={'auto'}>

          <Grid item={true} lg={12} md={12}>
            <Typography className={classes.headerText}>
              Streamline&nbsp;<span className={classes.purpleColor}>Your&nbsp;</span>
              <span className={classes.greenMainColor}>Dental Team Management</span>
            </Typography>
          </Grid>

          <Grid item={true} lg={12} md={12}>
            <Typography className={classes.descriptionText}>
              Efficiently manage your dental team members and optimise your clinic operations with our comprehensive management tools. Our user-friendly interface allows you to effortlessly oversee and organise your team, ensuring smooth collaboration and enhanced productivity.
            </Typography>
          </Grid>

        </Grid>
      </Box>

      <FourBox />

    </React.Fragment >
  )
}

export default FourDataWithHeader;