import React from 'react';
import { makeStyles } from '@mui/styles';
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box, Button, Grid, Switch, Typography } from '@mui/material';
import { styled } from '@mui/system'
import saveArrow from "../../../assets/images/v2/landingPage/clinicExam/saveArrow.png";
import theme from '../../../theme';

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 140,
  height: 50,
  padding: '0px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateX(70px)',
    '&.Mui-checked': {
      color: theme.palette.common.white,
      transform: 'translateX(0px)',
      '& .MuiSwitch-thumb:before': {
        content: '"Monthly"',
        top: '27%',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.v2.primary.main,
    border: `1px solid ${theme.palette.v2.primary.main}`,
    borderRadius: '10px',
    width: 70,
    height: 50,
    '&:before': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: '27%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '"Yearly"',
    },
  },

  '& .MuiSwitch-track': {
    border: `1px solid ${theme.palette.v2.primary.main}`,
    borderRadius: '10px',
    backgroundColor: theme.palette.common.white,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      content: '"Monthly"',
      color: theme.palette.v2.primary.main,
      left: 20,
      fontWeight: 600,
    },
    '&:after': {
      content: '"Yearly"',
      color: theme.palette.v2.primary.main,
      right: 40,
      fontWeight: 600,
    },
  },
}))

const useStyles = makeStyles((theme: any) => ({
  headerText: {
    fontSize: '40px',
    lineHeight: '56px',
    fontWeight: 600,
    display: 'inline-block',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '30px',
      lineHeight: '45px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '25px',
      lineHeight: '35px'
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '20px',
      lineHeight: '25px'
    },
  },
  descriptionText: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    display: 'flex',
    color: theme.palette.common.black50,
    marginTop: '10px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      lineHeight: '25px'
    },
    [theme.breakpoints.down(1100)]: {
      fontSize: '16px',
      lineHeight: '22px'
    },
    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
      lineHeight: '20px'
    },
  },
  scopeSwitch: {
    width: 'fit-content',
  },
  planName: {
    fontSize: '24px',
    fontWeight: 600
  },
  planPrice: {
    fontSize: '24px',
    fontWeight: 600,
    color: theme.palette.common.black50
  },
  planText: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.common.black50,
    lineHeight: '21px',
    textAlign: 'center'
  },
  signInBtn: {
    height: '36px',
    width: 'fit-content',
    padding: '6px 16px',
    border: `1px solid ${theme.palette.v2.primary.main}`,
    borderRadius: '4px',
    color: theme.palette.v2.primary.main,
    marginTop: '20px',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.v2.primary.main
    },
  },
  signUpBtn: {
    height: '36px',
    width: 'fit-content',
    padding: '6px 16px',
    borderRadius: "4px",
    background: theme.palette.v2.primary.main,
    color: theme.palette.common.white,
    textDecoration: "none",
    textAlign: 'center',
    marginTop: '20px',
    '&:hover': {
      background: theme.palette.v2.primary.main,
      color: theme.palette.common.white,
    }
  },
  subscriptionMainBox: {
    [theme.breakpoints.down(950)]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  subscriptionBox: {
    width: '403px',
    [theme.breakpoints.down(1250)]: {
      width: '350px'
    },
    [theme.breakpoints.down(1100)]: {
      width: '300px'
    },
    [theme.breakpoints.down(950)]: {
      width: '403px'
    },
    [theme.breakpoints.down(450)]: {
      width: '350px'
    },
  },
  purpleColor: {
    color: theme.palette.v2.secondary
  },
  greenMainColor: {
    color: theme.palette.primary.main
  },
  imageClass: {
    position: 'absolute',
    bottom: '-50%',
    right: '15%',
    transform: 'translate(-50%, -50%)'
  }
}));

const SubscriptionPage = () => {
  const classes = useStyles();
  const palette = theme.palette
  const [scope, setScope] = React.useState(true)

  const chipChangeHandler = (val?: any) => {
    setScope(!scope)
  }

  return (
    <React.Fragment>
      <Box className={`d-flex flex-col align-center mt-30`}>
        <Box mt={'10px'} maxWidth={1300} className={`d-flex justify-center`}>
          <Box maxWidth={'612px'} className={`d-flex flex-col`}>
            <Typography className={`${classes.headerText}`}>
              We Offer&nbsp;<span className={classes.purpleColor}>Great price plans for&nbsp;</span>
              <span className={classes.greenMainColor}>OraQ Dental</span>
            </Typography>
            <Typography className={`${classes.descriptionText}`} mt={2}>
              Unlock the full potential of OraQ Dentist Portal with our affordable pricing plans. Choose the package that suits your practice needs and start maximising efficiency and productivity today.
            </Typography>
          </Box>
        </Box>
        <Box position={'relative'} className={'d-flex-all-center'} mt={'20px'} width={'fit-Content'} sx={{ marginInline: 'auto' }}>
          <Box className={''} textAlign={'center'} >
            <FormGroup>
              <FormControlLabel
                className={`${classes.scopeSwitch}`}
                control={<MaterialUISwitch checked={scope} />}
                label=''
                value={scope}
                onChange={chipChangeHandler}
              />
            </FormGroup>
          </Box>
          <Typography fontSize={'12px'} fontWeight={600} color={palette.v2.secondary}>
            SAVE UP TO 17%
          </Typography>
          <img src={saveArrow} alt='empoweredOne' width={'61px'} height={'23px'} className={classes.imageClass} />
        </Box>
        <Box className={`${classes.subscriptionMainBox} d-flex justify-center`} mt={'40px'} maxWidth={1300}>
          <Box border={`1px solid ${palette.divider}`} borderRadius={'10px'} className={`${classes.subscriptionBox}`} height={'398px'}>
            <Box p={'40px'} className={'d-flex flex-column align-center'}>
              <Typography className={classes.planName} color={palette.common.black50}>
                Basic
              </Typography>
              <Box className={'d-flex'} mt={'20px'}>
                $
                <Typography className={classes.planPrice} ml={'5px'} mr={'5px'}>
                  595
                </Typography>
                mo
              </Box>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`} borderBottom={`1px solid ${palette.divider}`}>
                <Typography className={`${classes.planText}`}>
                  Includes Software Integration
                </Typography>
              </Box>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`} borderBottom={`1px solid ${palette.divider}`}>
                <Typography className={`${classes.planText}`}>
                  Not Allowed PMS Integration
                </Typography>
              </Box>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`}>
                <Typography className={`${classes.planText}`}>
                  Lorem ipsum dolor sit amet
                </Typography>
              </Box>
              <Button variant='outlined' className={classes.signInBtn}>
                GET STARTED
              </Button>
            </Box>
          </Box>

          <Box border={`1px solid ${palette.primary.main}`} borderRadius={'10px'} className={`${classes.subscriptionBox}`} height={'474px'}
            sx={{
              margin: '0 16px 0 16px',
              background: palette.primary.greenLight,
              [theme.breakpoints.down(950)]: {
                width: '403px',
                margin: '16px 0 16px 0',
              },
            }}>
            <Box p={'40px'} className={'d-flex flex-column align-center'}>
              <Box width={'100%'} className={`d-flex justify-end`}>
                <Box color={palette.common.white} fontSize={'14px'} fontWeight={500} padding={'4px 12px'} borderRadius={'16px'}
                  sx={{ backgroundColor: palette.v2.primary.main }}>
                  Popular
                </Box>
              </Box>
              <Typography className={classes.planName} color={palette.v2.primary.main}>
                Premium
              </Typography>
              <Box className={'d-flex'} mt={'20px'}>
                $
                <Typography className={classes.planPrice} ml={'5px'} mr={'5px'}>
                  60
                </Typography>
                mo
              </Box>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`} borderBottom={`1px solid ${palette.divider}`}>
                <Typography className={`${classes.planText}`}>
                  Tailored for medium-sized dental practices and clinics
                </Typography>
              </Box>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`} borderBottom={`1px solid ${palette.divider}`}>
                <Typography className={`${classes.planText}`}>
                  Allowed PMS Integration
                </Typography>
              </Box>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`}>
                <Typography className={`${classes.planText}`}>
                  Lorem ipsum dolor sit amet
                </Typography>
              </Box>
              <Button variant='outlined' className={classes.signUpBtn}>
                GET STARTED
              </Button>
            </Box>
          </Box>

          <Box border={`1px solid ${palette.divider}`} borderRadius={'10px'} className={`${classes.subscriptionBox}`} height={'398px'}>
            <Box p={'40px'} className={'d-flex flex-column align-center'}>
              <Typography className={classes.planName} color={palette.common.black50}>
                Enterprise
              </Typography>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`} borderBottom={`1px solid ${palette.divider}`}>
                <Typography className={`${classes.planText}`}>
                  All from Starter and Business
                </Typography>
              </Box>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`} borderBottom={`1px solid ${palette.divider}`}>
                <Typography className={`${classes.planText}`}>
                  Complete suite of features
                </Typography>
              </Box>
              <Box padding={'24px 0'} width={'312px'} className={`d-flex justify-center`}>
                <Typography className={`${classes.planText}`}>
                  Please Contact Us for more Details
                </Typography>
              </Box>
              <Button variant='outlined' className={classes.signInBtn}>
                CONTACT US
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default SubscriptionPage;