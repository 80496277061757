import {
  CreateAppointmentRequest,
  CreateAppointmentRequestFailure,
  CreateAppointmentRequestFailurePayload,
  CreateAppointmentRequestPayload,
  CreateAppointmentRequestSuccess,
  CreateAppointmentRequestSuccessPayload,
  GetAllPatientRequest,
  GetAllPatientRequestFailure,
  GetAllPatientRequestFailurePayload,
  GetAllPatientRequestPayload,
  GetAllPatientRequestSuccess,
  GetAllPatientRequestSuccessPayload,
  GetAllStaffByClinicRequest,
  GetAllStaffByClinicRequestFailure,
  GetAllStaffByClinicRequestFailurePayload,
  GetAllStaffByClinicRequestPayload,
  GetAllStaffByClinicRequestSuccess,
  GetAllStaffByClinicRequestSuccessPayload,
  GetAppointmentCountRequest,
  GetAppointmentCountRequestFailure,
  GetAppointmentCountRequestFailurePayload,
  GetAppointmentCountRequestPayload,
  GetAppointmentCountRequestSuccess,
  GetAppointmentCountRequestSuccessPayload,
  GetAppointmentRequest,
  GetAppointmentRequestFailure,
  GetAppointmentRequestFailurePayload,
  GetAppointmentRequestPayload,
  GetAppointmentRequestSuccess,
  GetAppointmentRequestSuccessPayload,
  GetCheckoutDataFailurePayload,
  GetCheckoutDataRequest,
  GetCheckoutDataRequestFailure,
  GetCheckoutDataRequestPayload,
  GetCheckoutDataRequestSuccess,
  GetCheckoutDataRequestSuccessPayload,
  GetCheckoutProviderDropdownFailurePayload,
  GetCheckoutProviderDropdownRequest,
  GetCheckoutProviderDropdownRequestFailure,
  GetCheckoutProviderDropdownRequestPayload,
  GetCheckoutProviderDropdownRequestSuccess,
  GetCheckoutProviderDropdownRequestSuccessPayload,
  PutCheckoutDataFailurePayload,
  PutCheckoutDataRequest,
  PutCheckoutDataRequestFailure,
  PutCheckoutDataRequestPayload,
  PutCheckoutDataRequestSuccess,
  PutCheckoutDataRequestSuccessPayload,
  SetDescendingAscendingAppointment,
  SetDescendingAscendingAppointmentPayload,
  UpdateAppointmentRequest,
  UpdateAppointmentRequestFailure,
  UpdateAppointmentRequestFailurePayload,
  UpdateAppointmentRequestPayload,
  UpdateAppointmentRequestSuccess,
  UpdateAppointmentRequestSuccessPayload,
  UpdateHygieneRegimeData,
  UpdateInsuranceTreatmentData,
  UpdatePreAuthData,
  UpdatePreAuthDataPayload,
  ViewAppointmentRequest,
  ViewAppointmentRequestFailure,
  ViewAppointmentRequestFailurePayload,
  ViewAppointmentRequestPayload,
  ViewAppointmentRequestSuccess,
  ViewAppointmentRequestSuccessPayload,
} from '../../schema/appointment/appointmentScheme'
import {
  CHANGE_APPOINTMENT_INFO,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_REQUEST_FAILURE,
  CREATE_APPOINTMENT_REQUEST_SUCCESS,
  GET_ALL_PATIENT_REQUEST,
  GET_ALL_PATIENT_REQUEST_FAILURE,
  GET_ALL_PATIENT_REQUEST_SUCCESS,
  GET_ALL_STAFF_BY_CLINIC_REQUEST,
  GET_ALL_STAFF_BY_CLINIC_REQUEST_FAILURE,
  GET_ALL_STAFF_BY_CLINIC_REQUEST_SUCCESS,
  GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
  GET_APPOINTMENT_COUNT_REQUEST,
  GET_APPOINTMENT_COUNT_REQUEST_FAILURE,
  GET_APPOINTMENT_COUNT_REQUEST_SUCCESS,
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_REQUEST_FAILURE,
  GET_APPOINTMENT_REQUEST_SUCCESS,
  GET_CHECKOUT_DATA_REQUEST,
  GET_CHECKOUT_DATA_REQUEST_FAILURE,
  GET_CHECKOUT_DATA_REQUEST_SUCCESS,
  GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST,
  GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_FAILURE,
  GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_SUCCESS,
  PUT_CHECKOUT_DATA_REQUEST,
  PUT_CHECKOUT_DATA_REQUEST_FAILURE,
  PUT_CHECKOUT_DATA_REQUEST_SUCCESS,
  SET_DESCENDING_ASCENDING_APPOINTMENT,
  UPDATE_APPOINTMENT_INFO,
  UPDATE_APPOINTMENT_INFO_FAILURE,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_REQUEST_FAILURE,
  UPDATE_APPOINTMENT_REQUEST_SUCCESS,
  UPDATE_CHECKOUT_VALIDATIONS,
  UPDATE_HYGIENE_REGIME_DATA,
  UPDATE_INSURANCE_TREATMENT_DATA,
  UPDATE_PREAUTH_CHECKLIST_DATA,
  VIEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
  VIEW_APPOINTMENT_REQUEST,
  VIEW_APPOINTMENT_REQUEST_FAILURE,
  VIEW_APPOINTMENT_REQUEST_SUCCESS,
} from '../actionTypes'

// Get appointment
export const getAppointmentRequest = (payload: GetAppointmentRequestPayload): GetAppointmentRequest => ({
  type: GET_APPOINTMENT_REQUEST,
  payload,
})
export const getAppointmentRequestSuccess = (payload: GetAppointmentRequestSuccessPayload): GetAppointmentRequestSuccess => ({
  type: GET_APPOINTMENT_REQUEST_SUCCESS,
  payload,
})
export const getAppointmentRequestFailure = (payload: GetAppointmentRequestFailurePayload): GetAppointmentRequestFailure => ({
  type: GET_APPOINTMENT_REQUEST_FAILURE,
  payload,
})
export const getAppointmentCacheDataClearRequest = (): any => ({
  type: GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
})

// Create appointment
export const createAppointmentRequest = (payload: CreateAppointmentRequestPayload): CreateAppointmentRequest => ({
  type: CREATE_APPOINTMENT_REQUEST,
  payload,
})
export const createAppointmentRequestSuccess = (payload: CreateAppointmentRequestSuccessPayload): CreateAppointmentRequestSuccess => ({
  type: CREATE_APPOINTMENT_REQUEST_SUCCESS,
  payload,
})
export const createAppointmentRequestFailure = (payload: CreateAppointmentRequestFailurePayload): CreateAppointmentRequestFailure => ({
  type: CREATE_APPOINTMENT_REQUEST_FAILURE,
  payload,
})

// update appointment
export const updateAppointmentRequest = (payload: UpdateAppointmentRequestPayload): UpdateAppointmentRequest => ({
  type: UPDATE_APPOINTMENT_REQUEST,
  payload,
})
export const updateAppointmentRequestSuccess = (payload: UpdateAppointmentRequestSuccessPayload): UpdateAppointmentRequestSuccess => ({
  type: UPDATE_APPOINTMENT_REQUEST_SUCCESS,
  payload,
})
export const updateAppointmentRequestFailure = (payload: UpdateAppointmentRequestFailurePayload): UpdateAppointmentRequestFailure => ({
  type: UPDATE_APPOINTMENT_REQUEST_FAILURE,
  payload,
})

// view appointment
export const viewAppointmentRequest = (payload: ViewAppointmentRequestPayload): ViewAppointmentRequest => ({
  type: VIEW_APPOINTMENT_REQUEST,
  payload,
})
export const viewAppointmentRequestSuccess = (payload: ViewAppointmentRequestSuccessPayload): ViewAppointmentRequestSuccess => ({
  type: VIEW_APPOINTMENT_REQUEST_SUCCESS,
  payload,
})
export const viewAppointmentRequestFailure = (payload: ViewAppointmentRequestFailurePayload): ViewAppointmentRequestFailure => ({
  type: VIEW_APPOINTMENT_REQUEST_FAILURE,
  payload,
})

// manage appointment local data

export const updateAppointmentInfoRequest = (payload: CreateAppointmentRequestPayload): CreateAppointmentRequestPayload =>
  ({
    type: UPDATE_APPOINTMENT_INFO,
    payload,
  } as any)

export const updateAppointmentInfoRequestFailure = (): any =>
  ({
    type: UPDATE_APPOINTMENT_INFO_FAILURE,
  } as any)

export const changeAppointmentInfoAction = (payload: any): any =>
  ({
    type: CHANGE_APPOINTMENT_INFO,
    payload,
  } as any)
export const viewAppointmentCacheDataClearRequest = (): any => ({
  type: VIEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
})

// get all patient
export const getAllPatientRequest = (payload: GetAllPatientRequestPayload): GetAllPatientRequest => ({
  type: GET_ALL_PATIENT_REQUEST,
  payload,
})
export const getAllPatientRequestSuccess = (payload: GetAllPatientRequestSuccessPayload): GetAllPatientRequestSuccess => ({
  type: GET_ALL_PATIENT_REQUEST_SUCCESS,
  payload,
})
export const getAllPatientRequestFailure = (payload: GetAllPatientRequestFailurePayload): GetAllPatientRequestFailure => ({
  type: GET_ALL_PATIENT_REQUEST_FAILURE,
  payload,
})

// get all staff by clinic
export const getAllStaffByClinicRequest = (payload: GetAllStaffByClinicRequestPayload): GetAllStaffByClinicRequest => ({
  type: GET_ALL_STAFF_BY_CLINIC_REQUEST,
  payload,
})
export const getAllStaffByClinicRequestSuccess = (
  payload: GetAllStaffByClinicRequestSuccessPayload
): GetAllStaffByClinicRequestSuccess => ({
  type: GET_ALL_STAFF_BY_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const getAllStaffByClinicRequestFailure = (
  payload: GetAllStaffByClinicRequestFailurePayload
): GetAllStaffByClinicRequestFailure => ({
  type: GET_ALL_STAFF_BY_CLINIC_REQUEST_FAILURE,
  payload,
})

// checkout
export const updatePreauthChecklistDataAction = (payload: UpdatePreAuthDataPayload): UpdatePreAuthData => ({
  type: UPDATE_PREAUTH_CHECKLIST_DATA,
  payload,
})

export const getCheckoutDataRequestAction = (payload: GetCheckoutDataRequestPayload): GetCheckoutDataRequest => ({
  type: GET_CHECKOUT_DATA_REQUEST,
  payload,
})

export const getCheckoutDataRequestSuccessAction = (payload: GetCheckoutDataRequestSuccessPayload): GetCheckoutDataRequestSuccess => ({
  type: GET_CHECKOUT_DATA_REQUEST_SUCCESS,
  payload,
})

export const getCheckoutDataRequestFailureAction = (payload: GetCheckoutDataFailurePayload): GetCheckoutDataRequestFailure => ({
  type: GET_CHECKOUT_DATA_REQUEST_FAILURE,
  payload,
})

export const getCheckoutProviderDropdownRequestAction = (
  payload: GetCheckoutProviderDropdownRequestPayload
): GetCheckoutProviderDropdownRequest => ({
  type: GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST,
  payload,
})

export const getCheckoutProviderDropdownRequestSuccessAction = (
  payload: GetCheckoutProviderDropdownRequestSuccessPayload
): GetCheckoutProviderDropdownRequestSuccess => ({
  type: GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_SUCCESS,
  payload,
})

export const getCheckoutProviderDropdownRequestFailureAction = (
  payload: GetCheckoutProviderDropdownFailurePayload
): GetCheckoutProviderDropdownRequestFailure => ({
  type: GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_FAILURE,
  payload,
})

export const putCheckoutDataRequestAction = (payload: PutCheckoutDataRequestPayload): PutCheckoutDataRequest => ({
  type: PUT_CHECKOUT_DATA_REQUEST,
  payload,
})

export const putCheckoutDataRequestSuccessAction = (payload: PutCheckoutDataRequestSuccessPayload): PutCheckoutDataRequestSuccess => ({
  type: PUT_CHECKOUT_DATA_REQUEST_SUCCESS,
  payload,
})

export const putCheckoutDataRequestFailureAction = (payload: PutCheckoutDataFailurePayload): PutCheckoutDataRequestFailure => ({
  type: PUT_CHECKOUT_DATA_REQUEST_FAILURE,
  payload,
})

export const updateHygieneRegimeDataAction = (payload: any): UpdateHygieneRegimeData => ({
  type: UPDATE_HYGIENE_REGIME_DATA,
  payload,
})

export const updateInsuranceTreatmentDataAction = (payload: any): UpdateInsuranceTreatmentData => ({
  type: UPDATE_INSURANCE_TREATMENT_DATA,
  payload,
})

// get Appointment count

export const getAppointmentCountRequest = (payload: GetAppointmentCountRequestPayload): GetAppointmentCountRequest => ({
  type: GET_APPOINTMENT_COUNT_REQUEST,
  payload,
})
export const getAppointmentCountRequestSuccess = (
  payload: GetAppointmentCountRequestSuccessPayload
): GetAppointmentCountRequestSuccess => ({
  type: GET_APPOINTMENT_COUNT_REQUEST_SUCCESS,
  payload,
})
export const getAppointmentCountRequestFailure = (
  payload: GetAppointmentCountRequestFailurePayload
): GetAppointmentCountRequestFailure => ({
  type: GET_APPOINTMENT_COUNT_REQUEST_FAILURE,
  payload,
})

// set descending ascending
export const setDescendingAscendingAppointmentAction = (
  payload: SetDescendingAscendingAppointmentPayload
): SetDescendingAscendingAppointment => ({
  type: SET_DESCENDING_ASCENDING_APPOINTMENT,
  payload,
})

// validation checkout
export const updateCheckoutValidationsAction = (payload: any): any => ({
  type: UPDATE_CHECKOUT_VALIDATIONS,
  payload,
})
