import { Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

const Loader = (props: any) => {
  return (
    <Grid container={true} justifyContent={'center'} alignItems={'center'} sx={{ display: 'flex' }} height={props.height || '100vh'}>
      <CircularProgress color={'secondary'} />
    </Grid>
  )
}

export default Loader
