import {
  GET_CARDS_REQUEST,
  GET_CARDS_REQUEST_FAILURE,
  GET_CARDS_REQUEST_SUCCESS,
  GET_SUBSCRIPTION_PLANS_REQUEST,
  GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST,
  GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_FAILURE,
  GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_SUCCESS,
  SELECT_CARD_REQUEST,
  SELECT_SUBSCRIPTION_PLANS_ID,
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_REQUEST_FAILURE,
  UNSUBSCRIBE_REQUEST_SUCCESS,
  REMOVE_CARD_REQUEST,
  REMOVE_CARD_REQUEST_SUCCESS,
  REMOVE_CARD_REQUEST_FAILURE,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_REQUEST_SUCCESS,
  UPDATE_PAYMENT_METHOD_REQUEST_FAILURE,
} from '../../actions/actionTypes'

const initialState: any = {
  getPlanLoader: false,
  getCardsLoader: false,
  removeCardLoader: false,
  plans: [],
  cards: [],
  selectedCard: '',
  selectedPlanId: '',
  unsubscribeLoader: false,
  unsubscribeData: null,
  unsubscribeError: null,
  planHistoryLoader: false,
  planHistoryData: null,
  planHistoryError: null,
}
const subscriptionPlansReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS_REQUEST:
      return {
        ...state,
        getPlanLoader: true,
      }
    case GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS:
      return {
        ...state,
        getPlanLoader: false,
        plans: action.payload,
      }
    case GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE:
      return {
        ...state,
        getPlanLoader: false,
        plans: [],
      }
    case SELECT_SUBSCRIPTION_PLANS_ID:
      return {
        ...state,
        selectedPlanId: action.payload,
      }
    case UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        unsubscribeLoader: true,
      }
    case UNSUBSCRIBE_REQUEST_SUCCESS:
      return {
        ...state,
        unsubscribeLoader: false,
        unsubscribeData: action.payload,
        unsubscribeError: null,
      }
    case UNSUBSCRIBE_REQUEST_FAILURE:
      return {
        ...state,
        unsubscribeLoader: false,
        unsubscribeData: null,
        unsubscribeError: action.payload,
      }
    case GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST:
      return {
        ...state,
        planHistoryLoader: true,
      }
    case GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_SUCCESS:
      return {
        ...state,
        planHistoryLoader: false,
        planHistoryData: action.payload?.planHistoryData,
        planHistoryMeta: action.payload?.planHistoryMeta,
        planHistoryError: null,
      }
    case GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_FAILURE:
      return {
        ...state,
        planHistoryLoader: false,
        planHistoryData: null,
        planHistoryError: action.payload,
      }
    case GET_CARDS_REQUEST:
      return {
        ...state,
        getCardsLoader: true,
      }
    case GET_CARDS_REQUEST_SUCCESS:
      return {
        ...state,
        getCardsLoader: false,
        cards: action.payload,
      }
    case GET_CARDS_REQUEST_FAILURE:
      return {
        ...state,
        getCardsLoader: false,
        cards: [],
      }
    case SELECT_CARD_REQUEST:
      return {
        ...state,
        selectedCard: action.payload,
      }

    case REMOVE_CARD_REQUEST:
      return {
        ...state,
        removeCardLoader: true,
      }
    case REMOVE_CARD_REQUEST_SUCCESS:
      return {
        ...state,
        removeCardLoader: false,
      }
    case REMOVE_CARD_REQUEST_FAILURE:
      return {
        ...state,
        removeCardLoader: false,
      }
    case UPDATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        getCardsLoader: true,
      }
    case UPDATE_PAYMENT_METHOD_REQUEST_SUCCESS:
      return {
        ...state,
        getCardsLoader: false,
      }
    case UPDATE_PAYMENT_METHOD_REQUEST_FAILURE:
      return {
        ...state,
        getCardsLoader: false,
      }
    default:
      return {
        ...state,
      }
  }
}
export default subscriptionPlansReducer
