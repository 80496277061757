import {
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_REQUEST_FAILURE,
  CREATE_MEMBER_REQUEST_SUCCESS,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_REQUEST_FAILURE,
  DELETE_MEMBER_REQUEST_SUCCESS,
  EDIT_ROLE_TO_MEMBER_REQUEST,
  EDIT_ROLE_TO_MEMBER_REQUEST_FAILURE,
  EDIT_ROLE_TO_MEMBER_REQUEST_SUCCESS,
  GET_ALL_CLINIC_REQUEST,
  GET_ALL_CLINIC_REQUEST_FAILURE,
  GET_ALL_CLINIC_REQUEST_SUCCESS,
  GET_MEMBER_CACHE_DATA_CLEAR_REQUEST,
  GET_MEMBER_REQUEST,
  GET_MEMBER_REQUEST_FAILURE,
  GET_MEMBER_REQUEST_SUCCESS,
  POST_EDIT_MEMBER_ROLE_REQUEST,
  UPDATE_ASSIGN_ROLE_TO_MEMBER_REQUEST,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_REQUEST_FAILURE,
  UPDATE_MEMBER_REQUEST_SUCCESS,
  UPDATE_MEMBER_STATUS_LOCAL,
  UPDATE_MEMBER_STATUS_REQUEST,
  UPDATE_MEMBER_STATUS_REQUEST_FAILURE,
  UPDATE_MEMBER_STATUS_REQUEST_SUCCESS,
  VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST,
  VIEW_MEMBER_REQUEST,
  VIEW_MEMBER_REQUEST_FAILURE,
  VIEW_MEMBER_REQUEST_SUCCESS,
} from '../actionTypes'

import {
  CreateMemberRequest,
  CreateMemberRequestFailure,
  CreateMemberRequestFailurePayload,
  CreateMemberRequestPayload,
  CreateMemberRequestSuccess,
  CreateMemberRequestSuccessPayload,
  DeleteMemberRequest,
  DeleteMemberRequestFailure,
  DeleteMemberRequestFailurePayload,
  DeleteMemberRequestPayload,
  DeleteMemberRequestSuccess,
  DeleteMemberRequestSuccessPayload,
  EditRoleToMemberRequest,
  EditRoleToMemberRequestFailure,
  EditRoleToMemberRequestFailurePayload,
  EditRoleToMemberRequestPayload,
  EditRoleToMemberRequestSuccess,
  EditRoleToMemberRequestSuccessPayload,
  GetAllClinicRequest,
  GetAllClinicRequestFailure,
  GetAllClinicRequestFailurePayload,
  GetAllClinicRequestPayload,
  GetAllClinicRequestSuccess,
  GetAllClinicRequestSuccessPayload,
  GetMemberRequest,
  GetMemberRequestFailure,
  GetMemberRequestFailurePayload,
  GetMemberRequestPayload,
  GetMemberRequestSuccess,
  GetMemberRequestSuccessPayload,
  MemberStatusUpdateRequest,
  MemberStatusUpdateRequestFailure,
  MemberStatusUpdateRequestFailurePayload,
  MemberStatusUpdateRequestPayload,
  MemberStatusUpdateRequestSuccess,
  MemberStatusUpdateRequestSuccessPayload,
  UpdateMemberRequest,
  UpdateMemberRequestFailure,
  UpdateMemberRequestFailurePayload,
  UpdateMemberRequestPayload,
  UpdateMemberRequestSuccess,
  UpdateMemberRequestSuccessPayload,
  UpdateMemberStatusLocal,
  updateMemberStatusLocalPayload,
  ViewMemberRequest,
  ViewMemberRequestFailure,
  ViewMemberRequestFailurePayload,
  ViewMemberRequestPayload,
  ViewMemberRequestSuccess,
  ViewMemberRequestSuccessPayload,
} from '../../schema/member/memberSchema'

// Create member
export const createMemberRequest = (payload: CreateMemberRequestPayload): CreateMemberRequest => ({
  type: CREATE_MEMBER_REQUEST,
  payload,
})
export const createMemberRequestSuccess = (payload: CreateMemberRequestSuccessPayload): CreateMemberRequestSuccess => ({
  type: CREATE_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const createMemberRequestFailure = (payload: CreateMemberRequestFailurePayload): CreateMemberRequestFailure => ({
  type: CREATE_MEMBER_REQUEST_FAILURE,
  payload,
})

// Get member
export const getMemberRequest = (payload: GetMemberRequestPayload): GetMemberRequest => ({
  type: GET_MEMBER_REQUEST,
  payload,
})
export const getMemberRequestSuccess = (payload: GetMemberRequestSuccessPayload): GetMemberRequestSuccess => ({
  type: GET_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const getMemberRequestFailure = (payload: GetMemberRequestFailurePayload): GetMemberRequestFailure => ({
  type: GET_MEMBER_REQUEST_FAILURE,
  payload,
})
export const getMemberCacheDataClearRequest = (): any => ({
  type: GET_MEMBER_CACHE_DATA_CLEAR_REQUEST,
})

// Update member
export const updateMemberRequest = (payload: UpdateMemberRequestPayload): UpdateMemberRequest => ({
  type: UPDATE_MEMBER_REQUEST,
  payload,
})
export const updateMemberRequestSuccess = (payload: UpdateMemberRequestSuccessPayload): UpdateMemberRequestSuccess => ({
  type: UPDATE_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const updateMemberRequestFailure = (payload: UpdateMemberRequestFailurePayload): UpdateMemberRequestFailure => ({
  type: UPDATE_MEMBER_REQUEST_FAILURE,
  payload,
})

// Delete member
export const deleteMemberRequest = (payload: DeleteMemberRequestPayload): DeleteMemberRequest => ({
  type: DELETE_MEMBER_REQUEST,
  payload,
})
export const deleteMemberRequestSuccess = (payload: DeleteMemberRequestSuccessPayload): DeleteMemberRequestSuccess => ({
  type: DELETE_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const deleteMemberRequestFailure = (payload: DeleteMemberRequestFailurePayload): DeleteMemberRequestFailure => ({
  type: DELETE_MEMBER_REQUEST_FAILURE,
  payload,
})

// update member status
export const updateMemberStatusRequest = (payload: MemberStatusUpdateRequestPayload): MemberStatusUpdateRequest => ({
  type: UPDATE_MEMBER_STATUS_REQUEST,
  payload,
})
export const updateMemberStatusRequestSuccess = (payload: MemberStatusUpdateRequestSuccessPayload): MemberStatusUpdateRequestSuccess => ({
  type: UPDATE_MEMBER_STATUS_REQUEST_SUCCESS,
  payload,
})
export const updateMemberStatusRequestFailure = (payload: MemberStatusUpdateRequestFailurePayload): MemberStatusUpdateRequestFailure => ({
  type: UPDATE_MEMBER_STATUS_REQUEST_FAILURE,
  payload,
})

// View member detail
export const viewMemberDetailRequest = (payload: ViewMemberRequestPayload): ViewMemberRequest => ({
  type: VIEW_MEMBER_REQUEST,
  payload,
})
export const viewMemberDetailRequestSuccess = (payload: ViewMemberRequestSuccessPayload): ViewMemberRequestSuccess => ({
  type: VIEW_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const viewMemberDetailRequestFailure = (payload: ViewMemberRequestFailurePayload): ViewMemberRequestFailure => ({
  type: VIEW_MEMBER_REQUEST_FAILURE,
  payload,
})
export const viewMemberCacheDataClearRequest = (): any => ({
  type: VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST,
})

// For set data from get api in edit-member
export const updateAssignRoleToMemberAction = (payload: any): any =>
  ({
    type: UPDATE_ASSIGN_ROLE_TO_MEMBER_REQUEST,
    payload,
  } as any)
// For clinic => member => edit ----------  edit-member => edit roles
export const editMemberRoleAction = (payload: any): any => ({
  type: POST_EDIT_MEMBER_ROLE_REQUEST,
  payload,
})


// get all clinic
export const getAllClinicRequest = (payload: GetAllClinicRequestPayload): GetAllClinicRequest => ({
  type: GET_ALL_CLINIC_REQUEST,
  payload,
})
export const getAllClinicRequestSuccess = (payload: GetAllClinicRequestSuccessPayload): GetAllClinicRequestSuccess => ({
  type: GET_ALL_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const getAllClinicRequestFailure = (payload: GetAllClinicRequestFailurePayload): GetAllClinicRequestFailure => ({
  type: GET_ALL_CLINIC_REQUEST_FAILURE,
  payload,
})

// Edit role to member
export const editRoleToMemberRequest = (payload: EditRoleToMemberRequestPayload): EditRoleToMemberRequest => ({
  type: EDIT_ROLE_TO_MEMBER_REQUEST,
  payload,
})
export const editRoleToMemberRequestSuccess = (payload: EditRoleToMemberRequestSuccessPayload): EditRoleToMemberRequestSuccess => ({
  type: EDIT_ROLE_TO_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const editRoleToMemberRequestFailure = (payload: EditRoleToMemberRequestFailurePayload): EditRoleToMemberRequestFailure => ({
  type: EDIT_ROLE_TO_MEMBER_REQUEST_FAILURE,
  payload,
})

// to update status local
export const updateMemberStatusLocalRequest = (payload: updateMemberStatusLocalPayload): UpdateMemberStatusLocal => ({
  type: UPDATE_MEMBER_STATUS_LOCAL,
  payload,
})
