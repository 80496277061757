import { APPROVE_CLINIC_FAILURE, APPROVE_CLINIC_REQUEST, APPROVE_CLINIC_SUCCESS, APPROVE_CLINIC_FINISHED } from '../../actions/actionTypes'

const initialState: any = {
  loader: false,
}

const approveClinicReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case APPROVE_CLINIC_REQUEST:
      return {
        ...state,
        loader: true,
      }
    case APPROVE_CLINIC_SUCCESS:
      return {
        ...state,
        loader: true,
      }
    case APPROVE_CLINIC_FAILURE:
      return {
        ...state,
        loader: false,
      }
    case APPROVE_CLINIC_FINISHED:
      return {
        ...state,
        loader: true
      }
    default:
      return {
        ...state,
      }
  }
}

export default approveClinicReducer
