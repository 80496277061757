import {
  CHANGE_OWNER_DETAILS,
  CREATE_OWNER_REQUEST,
  CREATE_OWNER_REQUEST_FAILURE,
  CREATE_OWNER_REQUEST_SUCCESS,
  DELETE_OWNER_REQUEST,
  DELETE_OWNER_REQUEST_FAILURE,
  DELETE_OWNER_REQUEST_SUCCESS,
  GET_OWNER_DETAILS_REQUEST,
  GET_OWNER_DETAILS_REQUEST_FAILURE,
  GET_OWNER_DETAILS_REQUEST_SUCCESS,
  GET_OWNER_LIST_REQUEST,
  GET_OWNER_LIST_REQUEST_FAILURE,
  GET_OWNER_LIST_REQUEST_SUCCESS,
  UPDATE_OWNER_ERROR,
  UPDATE_OWNER_REQUEST,
  UPDATE_OWNER_REQUEST_FAILURE,
  UPDATE_OWNER_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { manageOwnerActions, OwnerInterface } from '../../schema/owner/ownerSchema'

const initialState: OwnerInterface = {
  getOwnerListLoader: false,
  getOwnerListData: null,
  getOwnerListMeta: null,
  getOwnerListError: null,
  createOwnerLoader: false,
  createOwnerData: null,
  createOwnerError: null,
  updateOwnerLoader: false,
  updateOwnerData: null,
  updateOwnerError: null,
  ownerDetailsLoader: false,
  ownerDetails: null,
  ownerDetailsError: null,
  deleteOwnerLoader: false,
  deleteOwnerData: null,
  deleteOwnerError: null,
  changeOwnerDetails: {
    first_name: null,
    middle_name: null,
    last_name: null,
    email: null,
    mobile_number: null,
    home_number: null,
    address: null,
    postal_code: null,
    city: null,
    state: null,
    country: null,
    gender: null,
    date_of_birth: null,
    clinic_id: null,
    communication_preferences: 'yes',
  },
  ownersError: null,
}

const ownerReducer = (state = initialState, action: manageOwnerActions) => {
  switch (action.type) {
    case GET_OWNER_LIST_REQUEST:
      return {
        ...state,
        getOwnerListLoader: true,
        getOwnerListData: null,
        getOwnerListMeta: null,
        getOwnerListError: null,
      }
    case GET_OWNER_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        getOwnerListLoader: false,
        getOwnerListData: action.payload,
        getOwnerListMeta: action.payload,
        getOwnerListError: null,
      }
    case GET_OWNER_LIST_REQUEST_FAILURE:
      return {
        ...state,
        getOwnerListLoader: false,
        getOwnerListData: null,
        getOwnerListMeta: null,
        getOwnerListError: action.payload,
      }
    case CREATE_OWNER_REQUEST:
      return {
        ...state,
        createOwnerLoader: true,
        createOwnerData: null,
        createOwnerError: null,
      }
    case CREATE_OWNER_REQUEST_SUCCESS:
      return {
        ...state,
        createOwnerLoader: false,
        createOwnerData: action.payload,
        createOwnerError: null,
      }
    case CREATE_OWNER_REQUEST_FAILURE:
      return {
        ...state,
        createOwnerLoader: false,
        createOwnerData: null,
        createOwnerError: action.payload,
      }
    case UPDATE_OWNER_REQUEST:
      return {
        ...state,
        updateOwnerLoader: true,
        updateOwnerData: null,
        updateOwnerError: null,
      }
    case UPDATE_OWNER_REQUEST_SUCCESS:
      return {
        ...state,
        updateOwnerLoader: false,
        updateOwnerData: action.payload,
        updateOwnerError: null,
      }
    case UPDATE_OWNER_REQUEST_FAILURE:
      return {
        ...state,
        updateOwnerLoader: false,
        updateOwnerData: null,
        updateOwnerError: action.payload,
      }
    case GET_OWNER_DETAILS_REQUEST:
      return {
        ...state,
        ownerDetailsLoader: true,
        ownerDetails: null,
        ownerDetailsError: null,
      }
    case GET_OWNER_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        ownerDetailsLoader: false,
        ownerDetails: action.payload,
        ownerDetailsError: null,
      }
    case GET_OWNER_DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        ownerDetailsLoader: false,
        ownerDetails: null,
        ownerDetailsError: action.payload,
      }
    case DELETE_OWNER_REQUEST:
      return {
        ...state,
        deleteOwnerLoader: true,
        deleteOwnerData: null,
        deleteOwnerError: null,
      }
    case DELETE_OWNER_REQUEST_SUCCESS:
      return {
        ...state,
        deleteOwnerLoader: false,
        deleteOwnerData: action.payload,
        deleteOwnerError: null,
      }
    case DELETE_OWNER_REQUEST_FAILURE:
      return {
        ...state,
        deleteOwnerLoader: false,
        deleteOwnerData: null,
        deleteOwnerError: action.payload,
      }
    case CHANGE_OWNER_DETAILS:
      return {
        ...state,
        changeOwnerDetails: action.payload,
      }
    case UPDATE_OWNER_ERROR:
      return {
        ...state,
        ownersError: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
export default ownerReducer
