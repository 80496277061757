import { put, takeEvery, all } from 'redux-saga/effects'
import {
  termsConditionPrivacyPolicySuccess,
  termsConditionPrivacyPolicyFailure,
  GetTCDataSuccess,
  GetTCDataFailure
} from '../../actions/authActions/termsConditionPrivacyPolicyAction'
import { TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST, GET_TC_REQUEST } from '../../actions/actionTypes'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'

function* acceptTermsConditionPrivacyPolicyRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/auth/policy/accepted?is_owner=${payload?.payload?.isOwner}`, payload?.payload?.payload)
    yield put(
      termsConditionPrivacyPolicySuccess({
        termsConditions: response?.data?.detail,
      })
    )
    successToaster(response?.data?.meta?.message)
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      termsConditionPrivacyPolicyFailure({
        error: e?.detail,
      })
    )
  }
}

function* getTCDataRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`/v2/admin/cms?seo_url=${payload?.payload?.seo_url}&module=${payload?.payload?.module}&country=${payload?.payload?.country}`)
    yield put(
      GetTCDataSuccess({
        getTCData: response?.data?.detail
      })
    );
    if (payload?.payload?.callback) {
      payload?.payload?.callback(response)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      GetTCDataFailure({
        getTCError: e?.message || "Something went wrong!"
      })
    );
  }
}

function* acceptPolicySaga() {
  yield takeEvery(TERMS_CONDITION_AND_PRIVACY_POLICY_REQUEST, acceptTermsConditionPrivacyPolicyRequestSaga)
}

function* getTCDataSaga() {
  yield takeEvery(GET_TC_REQUEST, getTCDataRequestSaga);
}

export default function* termsConditionPrivacyPolicySaga() {
  yield all([
    acceptPolicySaga(),
    getTCDataSaga()
  ])
}
