import {
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_REQUEST_FAILURE,
  CREATE_APPOINTMENT_REQUEST_SUCCESS,
  GET_ALL_PATIENT_REQUEST,
  GET_ALL_PATIENT_REQUEST_FAILURE,
  GET_ALL_PATIENT_REQUEST_SUCCESS,
  GET_ALL_STAFF_BY_CLINIC_REQUEST,
  GET_ALL_STAFF_BY_CLINIC_REQUEST_FAILURE,
  GET_ALL_STAFF_BY_CLINIC_REQUEST_SUCCESS,
  GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
  GET_APPOINTMENT_COUNT_REQUEST,
  GET_APPOINTMENT_COUNT_REQUEST_FAILURE,
  GET_APPOINTMENT_COUNT_REQUEST_SUCCESS,
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_REQUEST_FAILURE,
  GET_APPOINTMENT_REQUEST_SUCCESS,
  GET_CHECKOUT_DATA_REQUEST,
  GET_CHECKOUT_DATA_REQUEST_FAILURE,
  GET_CHECKOUT_DATA_REQUEST_SUCCESS,
  GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST,
  GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_FAILURE,
  GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_SUCCESS,
  PUT_CHECKOUT_DATA_REQUEST,
  PUT_CHECKOUT_DATA_REQUEST_FAILURE,
  PUT_CHECKOUT_DATA_REQUEST_SUCCESS,
  SET_DESCENDING_ASCENDING_APPOINTMENT,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_REQUEST_FAILURE,
  UPDATE_APPOINTMENT_REQUEST_SUCCESS,
  UPDATE_CHECKOUT_VALIDATIONS,
  UPDATE_HYGIENE_REGIME_DATA,
  UPDATE_INSURANCE_TREATMENT_DATA,
  UPDATE_PREAUTH_CHECKLIST_DATA,
  VIEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
  VIEW_APPOINTMENT_REQUEST,
  VIEW_APPOINTMENT_REQUEST_FAILURE,
  VIEW_APPOINTMENT_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { AppointmentActions, AppointmentInterface } from '../../schema/appointment/appointmentScheme'

const initialState: AppointmentInterface = {
  createAppointmentLoader: false,
  createAppointmentData: null,
  createAppointmentError: null,
  getAppointmentLoader: false,
  getAppointmentData: null,
  getAppointmentError: null,
  viewAppointmentLoader: false,
  viewAppointmentData: null,
  viewAppointmentError: null,
  updateAppointmentLoader: false,
  updateAppointmentData: null,
  updateAppointmentError: null,
  allPatientLoader: false,
  allPatientData: null,
  allPatientError: null,
  allStaffData: null,
  allStaffLoader: false,
  allStaffError: null,
  insuranceTreatment: [
    {
      ID: '',
      alternative_treatment: '',
      is_alternative_approved: '',
      treatment: '',
      is_approved: '',
      procedure_code: '',
      fees: '',
      time: '',
      assistantID: '',
      hygienistID: '',
      dentistID: '',
      insurance_status: '',
      status: '',
      notes: '',
      priority: '',
    },
  ],
  preauthChecklist: {
    pa: false,
    bw: false,
    panoramoic_xray: false,
    perio_charting: false,
    photos: false,
  },
  getCheckoutLoader: false,
  getCheckoutData: null,
  getCheckoutError: '',
  getCheckoutDropdownLoader: false,
  getCheckoutDropdownData: null,
  getCheckoutDropdownError: '',
  updatedCheckoutLoader: false,
  updatedCheckoutData: false,
  updatedCheckoutError: '',

  hygieneRegimeData: {},
  insuranceTreatmentData: [],
  getAppointmentCountLoader: false,
  getAppointmentCountData: null,
  getAppointmentCountError: null,

  sortBy: '',
  desc: null,

  errors: {
    insuranceInfo: {},
  },
}

const appointmentReducer = (state = initialState, action: AppointmentActions) => {
  switch (action.type) {
    case GET_APPOINTMENT_REQUEST:
      return {
        ...state,
        getAppointmentLoader: true,
      }
    case GET_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        getAppointmentLoader: false,
        getAppointmentData: action?.payload?.getAppointmentData,
        getAppointmentError: null,
      }
    case GET_APPOINTMENT_REQUEST_FAILURE:
      return {
        ...state,
        getAppointmentLoader: false,
        getAppointmentData: null,
        getAppointmentError: action?.payload?.getAppointmentError,
      }
    case GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getAppointmentData: null,
      }
    case CREATE_APPOINTMENT_REQUEST:
      return {
        ...state,
        createAppointmentLoader: true,
      }
    case CREATE_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        createAppointmentLoader: false,
        createAppointmentData: action?.payload?.createAppointmentData,
        createAppointmentError: null,
      }
    case CREATE_APPOINTMENT_REQUEST_FAILURE:
      return {
        ...state,
        createAppointmentLoader: false,
        createAppointmentData: null,
        createAppointmentError: action?.payload?.createAppointmentError,
      }
    case UPDATE_APPOINTMENT_REQUEST:
      return {
        ...state,
        updateAppointmentLoader: true,
      }
    case UPDATE_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        updateAppointmentLoader: false,
        updateAppointmentData: action?.payload?.updateAppointmentData,
        updateAppointmentError: null,
      }
    case UPDATE_APPOINTMENT_REQUEST_FAILURE:
      return {
        ...state,
        updateAppointmentLoader: false,
        updateAppointmentData: null,
        updateAppointmentError: action?.payload?.updateAppointmentError,
      }
    case VIEW_APPOINTMENT_REQUEST:
      return {
        ...state,
        viewAppointmentLoader: true,
      }
    case VIEW_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        viewAppointmentLoader: false,
        viewAppointmentData: action?.payload?.viewAppointmentData,
        viewAppointmentError: null,
      }
    case VIEW_APPOINTMENT_REQUEST_FAILURE:
      return {
        ...state,
        viewAppointmentLoader: false,
        viewAppointmentData: null,
        viewAppointmentError: action?.payload?.viewAppointmentError,
      }
    case VIEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        viewAppointmentData: null,
      }
    case GET_ALL_PATIENT_REQUEST:
      return {
        ...state,
        allPatientLoader: true,
      }
    case GET_ALL_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        allPatientLoader: false,
        allPatientData: action?.payload?.allPatientData,
        allPatientError: null,
      }
    case GET_ALL_PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        allPatientLoader: false,
        allPatientData: null,
        allPatientError: action?.payload?.allPatientError,
      }

    case GET_ALL_STAFF_BY_CLINIC_REQUEST:
      return {
        ...state,
        allStaffLoader: true,
      }
    case GET_ALL_STAFF_BY_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        allStaffLoader: false,
        allStaffData: action?.payload?.allStaffData,
        allStaffError: null,
      }
    case GET_ALL_STAFF_BY_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        allStaffLoader: false,
        allStaffData: null,
        allStaffError: action?.payload?.allStaffError,
      }
    // checkout flow
    case UPDATE_PREAUTH_CHECKLIST_DATA:
      return {
        ...state,
        preauthChecklist: action.payload,
      }
    case GET_CHECKOUT_DATA_REQUEST:
      return {
        ...state,
        getCheckoutLoader: true,
      }
    case GET_CHECKOUT_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        getCheckoutLoader: false,
        hygieneRegimeData: action.payload.hygiene_regime,
        insuranceTreatmentData: action.payload.treatments,
        preauthChecklist: action.payload?.checklist,
        referrals: action?.payload?.referrals,
        getCheckoutError: '',
      }
    case GET_CHECKOUT_DATA_REQUEST_FAILURE:
      return {
        ...state,
        getCheckoutLoader: false,
        getCheckoutData: null,
        getCheckoutError: action.payload,
      }
    case GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST:
      return {
        ...state,
        getCheckoutDropdownLoader: true,
      }
    case GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_SUCCESS:
      return {
        ...state,
        getCheckoutDropdownLoader: false,
        getCheckoutDropdownData: action.payload,
        getCheckoutDropdownError: '',
      }
    case GET_CHECKOUT_PROVIDER_DROPDOWN_REQUEST_FAILURE:
      return {
        ...state,
        getCheckoutDropdownLoader: false,
        getCheckoutDropdownData: null,
        getCheckoutDropdownError: action.payload,
      }
    case UPDATE_HYGIENE_REGIME_DATA:
      return {
        ...state,
        hygieneRegimeData: action?.payload,
      }
    case UPDATE_INSURANCE_TREATMENT_DATA:
      return {
        ...state,
        insuranceTreatmentData: action?.payload,
      }
    case PUT_CHECKOUT_DATA_REQUEST:
      return {
        ...state,
        updatedCheckoutLoader: true,
      }
    case PUT_CHECKOUT_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        updatedCheckoutLoader: false,
        updatedCheckoutData: action.payload,
        updatedCheckoutError: '',
      }
    case PUT_CHECKOUT_DATA_REQUEST_FAILURE:
      return {
        ...state,
        updatedCheckoutLoader: false,
        updatedCheckoutData: null,
        updatedCheckoutError: action.payload,
      }

    case GET_APPOINTMENT_COUNT_REQUEST:
      return {
        ...state,
        getAppointmentCountLoader: true,
      }
    case GET_APPOINTMENT_COUNT_REQUEST_SUCCESS:
      return {
        ...state,
        getAppointmentCountLoader: false,
        getAppointmentCountData: action.payload,
        getAppointmentCountError: '',
      }
    case GET_APPOINTMENT_COUNT_REQUEST_FAILURE:
      return {
        ...state,
        getAppointmentCountLoader: false,
        getAppointmentCountData: null,
        getAppointmentCountError: action.payload,
      }
    case SET_DESCENDING_ASCENDING_APPOINTMENT:
      return {
        ...state,
        desc: action?.payload?.desc,
        sortBy: action?.payload?.sortBy,
      }
    case UPDATE_CHECKOUT_VALIDATIONS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default appointmentReducer
