import { VERIFY_USER_EMAIL_REQUEST, VERIFY_USER_EMAIL_REQUEST_FAIL, VERIFY_USER_EMAIL_REQUEST_SUCCESS } from '../../actions/actionTypes'
import { verifyUserEmailAction, VerifyUserEmailInterface } from '../../schema/commonFilter/verifyUserEmail'

const initialState: VerifyUserEmailInterface = {
  verifyUserEmailLoader: false,
  verifyUserEmailData: null,
  verifyUserEmailError: null,
}
const verifyUserEmailReducer = (state = initialState, action: verifyUserEmailAction) => {
  switch (action.type) {
    case VERIFY_USER_EMAIL_REQUEST:
      return {
        ...state,
        verifyUserEmailLoader: true,
        verifyUserEmailData: null,
        verifyUserEmailError: null,
      }
    case VERIFY_USER_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        verifyUserEmailLoader: false,
        verifyUserEmailData: action?.payload,
        verifyUserEmailError: null,
      }
    case VERIFY_USER_EMAIL_REQUEST_FAIL:
      return {
        ...state,
        verifyUserEmailLoader: false,
        verifyUserEmailData: null,
        verifyUserEmailError: action?.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default verifyUserEmailReducer
