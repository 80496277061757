import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST } from '../../actions/actionTypes'
import {
  clinicAdminForgotPasswordRequestSuccess,
  clinicAdminForgotPasswordRequestFailure,
  isMultipleAccountClinicAdminForgotPasswordPopUp,
} from '../../actions/authActions/forgotPasswordAction'
import { errorToaster, successToaster } from '../../../utils/helper'

function* clinicAdminForgotPasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`v2/auth/forgot/password`, payload.payload)
    successToaster(response?.data?.meta?.message)
    yield call(payload.payload.callback, response.data)
    yield put(
      clinicAdminForgotPasswordRequestSuccess({
        forgotPasswordData: response.data,
      })
    )
  } catch (e: any) {
    //TODO: update toast according to status code

    if (e?.data?.is_multiple_profile) {
      yield put(isMultipleAccountClinicAdminForgotPasswordPopUp(true))
    }
    if (e?.data == null) {
      errorToaster(e?.meta?.message)
    }

    yield put(
      clinicAdminForgotPasswordRequestFailure({
        forgotPasswordError: e?.meta?.message,
      })
    )
  }
}

function* postClinicAdminForgotPasswordSaga() {
  yield takeEvery(CLINIC_ADMIN_FORGOT_PASSWORD_REQUEST, clinicAdminForgotPasswordRequestSaga)
}

export default function* clinicAdminForgotPasswordSaga() {
  yield all([postClinicAdminForgotPasswordSaga()])
}
