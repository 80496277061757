export const checkDisease = [
  'Alcohol/Drug Abuse',
  'Anemia',
  'Angina',
  'Anxiety Attacks',
  'Arthritis',
  'Artificial Heart Valve',
  'Asthma',
  'Blood Disorder',
  'Cancer',
  'Chemotherapy',
  'Congenital Heart Defect',
  'Diabetes',
  'Dizziness/Fainting',
  'Emphysema',
  'Epilepsy/Seizures',
  'Glaucoma',
  'Headaches',
  'Hay Fever',
  'Head Injury',
  'Hearing Impaired',
  'Heart Attack',
  'Heart Disease',
  'Heart Murmur',
  'Hemophilia',
  'Hepatitis A B or C',
  'High Blood Pressure',
  'HIV/AIDS',
  'Joint Replacement',
  'Kidney Disease',
  'Liver Disease',
  'Low Blood Pressure',
  'Lung Disease/Tuberculosis',
  'Mental Disorder',
  'Mitral Valve Replacement',
  'Multiple Sclerosis',
  'Pacemaker',
  'Radiation Therapy',
  'Respiratory Problems',
  'Rheumatic Fever',
  'Sinus Problems',
  'Stomach Problems',
  'Stroke',
  'Smoking',
  'Thyroid Disease',
]

export const checkDiseaseOption2 = [
  'Aspirin',
  'Codeine',
  'Erthromycin',
  'Erthromycin',
  'Iodine',
  'Keflex',
  'Latex',
  'Local Anesthetic',
  'Metals',
  'Penicillin',
  'Sedatives',
  'Sulfa Drugs',
  'Tetracycline',
]

// Patient information
export const gender = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Transgender',
    label: 'Transgender',
  },
  {
    value: 'Non-Binary',
    label: 'Non-Binary',
  },
  {
    value: 'Prefer not to say',
    label: 'Prefer not to say',
  },
  {
    value: 'Other',
    label: 'Other - Please Specify',
  },
]

export const race = [
  {
    value: 'White/Caucasian',
    label: 'White/Caucasian',
  },
  {
    value: 'South Asian',
    label: 'South Asian (e.g., East Indian, Pakistani, Sri Lankan)',
  },
  {
    value: 'Chinese',
    label: 'Chinese',
  },
  {
    value: 'Black',
    label: 'Black',
  },
  {
    value: 'Filipino',
    label: 'Filipino',
  },
  {
    value: 'Arab',
    label: 'Arab',
  },
  {
    value: 'Latin American',
    label: 'Latin American',
  },
  {
    value: 'Southeast Asian',
    label: 'Southeast Asian (e.g., Vietnamese, Cambodian, Laotian, Thai)',
  },
  {
    value: 'West Asian',
    label: 'West Asian (e.g., Iranian, Afghan)',
  },
  {
    value: 'Korean',
    label: 'Korean',
  },
  {
    value: 'Japanese',
    label: 'Japanese',
  },
  {
    value: 'Prefer not to say',
    label: 'Prefer not to say',
  },
  {
    value: 'Other',
    label: 'Other - Please Specify',
  },
]

export const sex = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
  {
    value: 'Prefer not to say',
    label: 'Prefer not to say',
  },
]

// common for all the radio button which include yes and no option
export const radioButtonOptions = [
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Yes',
    value: 'yes',
  },
]

export const relationshipToEmergencyContactPerson = [
  {
    label: 'Spouse',
    value: 'Spouse',
  },
  {
    label: 'Parent',
    value: 'Parent',
  },
  {
    label: 'Friend',
    value: 'Friend',
  },
  {
    label: 'Family Member',
    value: 'Family Member',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]

// lets get you know better

export const dentalInsuranceOptions = [
  {
    label: 'No (skip to General Health section)',
    value: 'no',
  },
  {
    label: 'Yes (go to Dental Insurance Information section)',
    value: 'yes',
  },
]
export const maritalStatus = [
  {
    value: 'Married',
    label: 'Married',
  },
  {
    value: 'Single',
    label: 'Single',
  },
  {
    value: 'Widowed',
    label: 'Widowed',
  },
  {
    value: 'Divorced',
    label: 'Divorced',
  },
  {
    value: 'Separated',
    label: 'Separated',
  },
  {
    value: 'Other',
    label: 'Other',
  },
]

// General Health
export const heightUnitOptions = [
  {
    value: 'ft',
    label: 'ft',
  },
  {
    value: 'cm',
    label: 'cm',
  },
]
export const weightUnitOptions = [
  {
    value: 'kg',
    label: 'kg',
  },
  {
    value: 'lb',
    label: 'lbs',
  },
]
export const alcoholUsesOption = [
  { label: 'Less than 1 drink per week', value: 'Less than 1 drink per week' },
  { label: '1 to 2 drinks per week', value: '1 to 2 drinks per week' },
  { label: '3 to 7 drinks per week', value: '3 to 7 drinks per week' },
  { label: '8 to 14 drinks per week', value: '8 to 14 drinks per week' },
  {
    label: 'More than 14 drinks per week',
    value: 'More than 14 drinks per week',
  },
  { label: 'None of the above', value: 'None of the above' },
]

export const historyOfSmokingOption = [
  {
    label: 'Current smoker >10 cig per day',
    value: 'Current smoker >10 cig per day',
  },
  {
    label: 'Occasional smoker <10 cig per day',
    value: 'Occasional smoker <10 cig per day',
  },
  { label: 'Past history of smoking', value: 'Past History of smoking' },
  { label: 'Non-smoker', value: 'Non-smoker' },
]

// Dental Information

export const feelDentalHealth = [
  {
    value: 'Poor',
    label: 'Poor',
  },
  {
    value: 'Average',
    label: 'Average',
  },
  {
    value: 'Good',
    label: 'Good',
  },
  {
    value: 'Excellent',
    label: 'Excellent',
  },
]
export const homeCareProtectiveFactors = [
  {
    label: 'Fluoridated water',
    value: 'Fluoridated water',
  },
  {
    label: 'Fluoridated  toothpaste at least once a day',
    value: 'Fluoridated  toothpaste at least once a day',
  },
  {
    label: 'Fluoridated toothpaste 2X daily or more',
    value: 'Fluoridated toothpaste 2X daily or more',
  },
  // {
  //   label: '5,000 PPM (PreviDent) Fluoridated toothpaste',
  //   value: '5,000 PPM (PreviDent) Fluoridated toothpaste'
  // },
  {
    label: '5,000 PPM (PreviDent) Fluoridated toothpaste',
    value: '5000 PPM (PreviDent) Fluoridated toothpaste',
  },
  {
    label: 'Fluoride Varnish last 6 months',
    value: 'Fluoride Varnish last 6 months',
  },

  {
    label: '0.05% sodium flouride (Opti-Rinse) mouthrinse daily',
    value: '0.05% sodium flouride (Opti-Rinse) mouthrinse daily',
  },
  {
    label: '0.12% chlorhexidine (Peridex) gluconate mouthrinse daily 7 days monthly',
    value: '0.12% chlorhexidine (Peridex) gluconate mouthrinse daily 7 days monthly',
  },
  {
    label: 'Adequate saliva',
    value: 'Adequate saliva',
  },
]
export const dentalInfoCheckboxItem = [
  { label: 'Bad Breath', value: 'Bad Breath' },
  { label: 'Jaw Pain', value: 'Jaw Pain' },
  {
    label: 'Grinding or Clenching',
    value: 'Grinding or Clenching',
  },
  {
    label: 'Food Trapped Between Teeth',
    value: 'Food Trapped Between Teeth',
  },
  {
    label: 'Bleeding Gums',
    value: 'Bleeding Gums',
  },
  { label: 'Gag Reflex', value: 'Gag Reflex' },
  {
    label: 'Sensitivity to Hot/Cold',
    value: 'Sensitivity to Hot/Cold',
  },

  {
    label: 'Clicking or Popping Jaw',
    value: 'Clicking or Popping Jaw',
  },

  {
    label: 'Loose or Broken Teeth',
    value: 'Loose or Broken Teeth',
  },
]

// Sleep Quality

export const snoreLoudlyOptions = [
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'I don’t know',
    value: 'I don’t know',
  },
]

export const neckMeasuredOptions = [
  {
    label: 'Greater than or equal to 16 inches/40 cm',
    value: 'Greater than or equal to 16 inches/40 cm',
  },
  {
    label: 'Less than 16 inches/40 cm',
    value: 'Less than 16 inches/40 cm',
  },
  {
    label: 'I don’t know',
    value: 'I don’t know',
  },
]

export const sleepScreenQuestion = [
  {
    label: 'Sitting and reading',
    id: 'sitting_reading_epworth_scale',
  },
  {
    label: 'Watching TV',
    id: 'watching_tv_epworth_scale',
  },
  {
    label: 'Sitting inactive in a public place',
    id: 'sitting_inactive_epworth_scale',
  },
  {
    label: 'Being a passenger in a car for an hour',
    id: 'being_passenger_car_epworth_scale',
  },
  {
    label: 'Lying down in the afternoon',
    id: 'lying_down_afternoon_epworth_scale',
  },
  {
    label: 'Sitting and talking to someone',
    id: 'sitting_talking_someone_epworth_scale',
  },
  {
    label: 'Sitting quietly after lunch (no alcohol)',
    id: 'sitting_quietly_lunch_epworth_scale',
  },
  {
    label: 'Stopping for a few minutes in traffic while driving',
    id: 'stopping_fewminutes_driving_epworth_scale',
  },
]

// Medical Health History

export const diseaseCheckboxOption = [
  { label: 'Alcohol/Drug Abuse', value: 'Alcohol/Drug Abuse' },
  { label: 'Anemia', value: 'Anemia' },
  { label: 'Angina', value: 'Angina' },
  { label: 'Anxiety', value: 'Anxiety' },
  { label: 'Arthritis', value: 'Arthritis' },
  { label: 'Artificial Heart Valve', value: 'Artificial Heart Valve' },
  { label: 'Asthma', value: 'Asthma' },
  { label: 'Blood Disorder', value: 'Blood Disorder' },
  { label: 'Cancer', value: 'Cancer' },
  { label: 'Chemotherapy', value: 'Chemotherapy' },
  { label: 'Congenital Heart Defect', value: 'Congenital Heart Defect' },
  { label: 'Diabetes', value: 'Diabetes' },
  { label: 'Dizziness/Fainting', value: 'Dizziness/Fainting' },
  { label: 'Emphysema', value: 'Emphysema' },
  { label: 'Epilepsy/Seizures', value: 'Epilepsy/Seizures' },
  { label: 'Glaucoma', value: 'Glaucoma' },
  { label: 'Headaches', value: 'Headaches' },
  { label: 'Hay Fever', value: 'Hay Fever' },
  { label: 'Head Injury', value: 'Head Injury' },
  { label: 'Hearing Impaired', value: 'Hearing Impaired' },
  { label: 'Heart Attack', value: 'Heart Attack' },
  { label: 'Heart Disease', value: 'Heart Disease' },
  { label: 'Heart Murmur', value: 'Heart Murmur' },
  { label: 'Hemophilia', value: 'Hemophilia' },
  { label: 'Hepatitis A, B or C', value: 'Hepatitis A B or C' },
  { label: 'High Blood Pressure', value: 'High Blood Pressure' },
  { label: 'HIV/AIDS', value: 'HIV/AIDS' },
  { label: 'Joint Replacement', value: 'Joint Replacement' },
  { label: 'Kidney Disease', value: 'Kidney Disease' },
  { label: 'Liver Disease', value: 'Liver Disease' },
  { label: 'Low Blood Pressure', value: 'Low Blood Pressure' },
  { label: 'Lung Disease/Tuberculosis', value: 'Lung Disease/Tuberculosis' },
  { label: 'Mental Disorder', value: 'Mental Disorder' },
  { label: 'Mitral Valve Replacement', value: 'Mitral Valve Replacement' },
  { label: 'Multiple Sclerosis', value: 'Multiple Sclerosis' },
  { label: 'Pacemaker', value: 'Pacemaker' },
  { label: 'Radiation Therapy', value: 'Radiation Therapy' },
  { label: 'Respiratory Problems', value: 'Respiratory Problems' },
  { label: 'Rheumatic Fever', value: 'Rheumatic Fever' },
  { label: 'Sinus Problems', value: 'Sinus Problems' },
  { label: 'Stomach Problems', value: 'Stomach Problems' },
  { label: 'Stroke', value: 'Stroke' },
  { label: 'Thyroid Disease', value: 'Thyroid Disease' },
]
export const diseaseCheckboxOption2 = [
  { label: 'Aspirin', value: 'Aspirin' },
  { label: 'Codeine', value: 'Codeine' },
  { label: 'Erythromycin', value: 'Erythromycin' },
  { label: 'Iodine', value: 'Iodine' },
  { label: 'Keflex', value: 'Keflex' },
  { label: 'Latex', value: 'Latex' },
  { label: 'Local Anesthetic', value: 'Local Anesthetic' },
  { label: 'Metals', value: 'Metals' },
  { label: 'Penicillin', value: 'Penicillin' },
  { label: 'Sedatives', value: 'Sedatives' },
  { label: 'Sulfa Drugs', value: 'Sulfa Drugs' },
  { label: 'Tetracycline', value: 'Tetracycline' },
]

// Communication Preferences
export const describeYourselfMore = [
  {
    label: 'Fast-Paced and Outspoken',
    value: 'Fast-paced and outspoken',
  },
  {
    label: 'Cautious and Reflective',
    value: 'Cautious and reflective',
  },
]
export const describeYourselfMore1 = [
  {
    label: 'Questioning and Skeptical',
    value: 'Questioning and skeptical',
  },
  {
    label: 'Accepting and Warm',
    value: 'Accepting and warm',
  },
]

// convert yes no and true false
export const getYesNo = (val: any) => {
  if (val) {
    return 'yes'
  } else if (val === false) {
    return 'no'
  } else {
    return ''
  }
}

export const passTrueFalse = (val: any) => {
  if (val === 'yes') {
    return true
  } else if (val === 'no') {
    return false
  } else {
    return null
  }
}

export const checkSleep = (val?: any) => {
  let sleepVal = ''
  if (val === 0) {
    return (sleepVal = 'Would never doze or sleep')
  } else if (val === 1) {
    return (sleepVal = 'Slight chance of dozing or sleeping')
  } else if (val === 2) {
    return (sleepVal = 'Moderate chance of dozing or sleeping')
  } else if (val === 3) {
    return (sleepVal = 'High chance of dozing or sleeping')
  }
  return sleepVal
}

export const checkSleepLocal = (val?: any) => {
  if (val === 'Would never doze or sleep') {
    return 0
  } else if (val === 'Slight chance of dozing or sleeping') {
    return 1
  } else if (val === 'Moderate chance of dozing or sleeping') {
    return 2
  } else if (val === 'High chance of dozing or sleeping') {
    return 3
  }
  return null
}
export const marks10 = [
  {
    value: 0,
    label: '00',
  },
  {
    value: 1,
    label: '01',
  },
  {
    value: 2,
    label: '02',
  },
  {
    value: 3,
    label: '03',
  },
  {
    value: 4,
    label: '04',
  },
  {
    value: 5,
    label: '05',
  },
  {
    value: 6,
    label: '06',
  },
  {
    value: 7,
    label: '07',
  },
  {
    value: 8,
    label: '08',
  },
  {
    value: 9,
    label: '09',
  },
  {
    value: 10,
    label: '10',
  },
]
// export const checkSleepLocal = (val?: any) => {
//   return (
//     [
//       'Would never doze or sleep',
//       'Slight chance of dozing or sleeping',
//       'Moderate chance of dozing or sleeping',
//       'High chance of dozing or sleeping'
//     ][val] || null
//   );
// };
