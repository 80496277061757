import {
  GetSubscriptionPlanHistoryRequest,
  GetSubscriptionPlanHistoryRequestFailure,
  GetSubscriptionPlanHistoryRequestFailurePayload,
  GetSubscriptionPlanHistoryRequestPayload,
  GetSubscriptionPlanHistoryRequestSuccess,
  GetSubscriptionPlanHistoryRequestSuccessPayload,
} from '../../schema/subscriptionPlans/subscriptionPlansSchema'
import {
  ADD_CARD_REQUEST,
  GET_CARDS_REQUEST,
  GET_CARDS_REQUEST_FAILURE,
  GET_CARDS_REQUEST_SUCCESS,
  GET_SUBSCRIPTION_PLANS_REQUEST,
  GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST,
  GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_FAILURE,
  GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_SUCCESS,
  REMOVE_CARD_REQUEST,
  REMOVE_CARD_REQUEST_FAILURE,
  REMOVE_CARD_REQUEST_SUCCESS,
  SELECT_CARD_REQUEST,
  SELECT_SUBSCRIPTION_PLANS_ID,
  UNSUBSCRIBE_REQUEST,
  UNSUBSCRIBE_REQUEST_FAILURE,
  UNSUBSCRIBE_REQUEST_SUCCESS,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_REQUEST_FAILURE,
  UPDATE_PAYMENT_METHOD_REQUEST_SUCCESS,
} from '../actionTypes'

import {
  UnsubscribePlanRequest,
  UnsubscribePlanRequestFailure,
  UnsubscribePlanRequestFailurePayload,
  UnsubscribePlanRequestPayload,
  UnsubscribePlanRequestSuccess,
  UnsubscribePlanRequestSuccessPayload,
} from '../../schema/subscriptionPlans/subscriptionPlansSchema'

export const getSubscriptionPlansAction = () => ({
  type: GET_SUBSCRIPTION_PLANS_REQUEST,
})

export const getSubscriptionPlansSuccess = (payload: any): any => ({
  type: GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  payload,
})

export const getSubscriptionPlansFailure = (payload: any): any => ({
  type: GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  payload,
})
export const selectSubscriptionPlanId = (payload: any): any => ({
  type: SELECT_SUBSCRIPTION_PLANS_ID,
  payload,
})

export const unSubscriptionPlanAction = (payload: UnsubscribePlanRequestPayload): UnsubscribePlanRequest => ({
  type: UNSUBSCRIBE_REQUEST,
  payload,
})

export const unSubscriptionPlanSuccess = (payload: UnsubscribePlanRequestSuccessPayload): UnsubscribePlanRequestSuccess => ({
  type: UNSUBSCRIBE_REQUEST_SUCCESS,
  payload,
})

export const unSubscriptionPlanFailure = (payload: UnsubscribePlanRequestFailurePayload): UnsubscribePlanRequestFailure => ({
  type: UNSUBSCRIBE_REQUEST_FAILURE,
  payload,
})
// get subscription plan history data

export const getSubscriptionPlanHistoryAction = (payload: GetSubscriptionPlanHistoryRequestPayload): GetSubscriptionPlanHistoryRequest => ({
  type: GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST,
  payload,
})

export const getSubscriptionPlanHistorySuccess = (
  payload: GetSubscriptionPlanHistoryRequestSuccessPayload
): GetSubscriptionPlanHistoryRequestSuccess => ({
  type: GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_SUCCESS,
  payload,
})

export const getSubscriptionPlanHistoryFailure = (
  payload: GetSubscriptionPlanHistoryRequestFailurePayload
): GetSubscriptionPlanHistoryRequestFailure => ({
  type: GET_SUBSCRIPTION_PLAN_HISTORY_REQUEST_FAILURE,
  payload,
})

export const getCardsAction = (payload: any) => ({
  type: GET_CARDS_REQUEST,
  payload,
})

export const getCardsSuccessAction = (payload: any): any => ({
  type: GET_CARDS_REQUEST_SUCCESS,
  payload,
})

export const getCardsFailureAction = () => ({
  type: GET_CARDS_REQUEST_FAILURE,
})

export const addCardAction = (payload: any) => ({
  type: ADD_CARD_REQUEST,
  payload,
})

export const selectCardAction = (payload: any) => ({
  type: SELECT_CARD_REQUEST,
  payload,
})

export const removeCardAction = (payload: any) => ({
  type: REMOVE_CARD_REQUEST,
  payload,
})
export const RemoveCardSuccessAction = (payload: any) => ({
  type: REMOVE_CARD_REQUEST_SUCCESS,
  payload,
})

export const RemoveCardFailureAction = (payload: any) => ({
  type: REMOVE_CARD_REQUEST_FAILURE,
  payload,
})

export const updatePaymentMethodCardAction = (payload: any) => ({
  type: UPDATE_PAYMENT_METHOD_REQUEST,
  payload,
})
export const updatePaymentMethodCardSuccessAction = (payload: any) => ({
  type: UPDATE_PAYMENT_METHOD_REQUEST_SUCCESS,
  payload,
})

export const updatePaymentMethodCardFailureAction = (payload: any) => ({
  type: UPDATE_PAYMENT_METHOD_REQUEST_FAILURE,
  payload,
})
