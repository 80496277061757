import {
  SET_DESCENDING_ASCENDING
} from '../../actions/actionTypes'
import { DashboardInterface, dashboardAction } from '../../schema/dashboard/dashboard'

const initialState: DashboardInterface = {
  sortBy: '',
  desc: null,
}
const dashboardReducer = (state = initialState, action: dashboardAction) => {
  switch (action.type) {
    case SET_DESCENDING_ASCENDING:
      return {
        ...state,
        desc: action?.payload?.desc,
        sortBy: action?.payload?.sortBy,
      }

    default:
      return {
        ...state,
      }
  }
}

export default dashboardReducer
