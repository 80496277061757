import {
    MATCH_PASSWORD_REQUEST,
    MATCH_PASSWORD_REQUEST_SUCCESS,
    MATCH_PASSWORD_REQUEST_FAILURE,
} from "../../actions/actionTypes"
import { MatchPasswordInterface, matchPasswordActions } from "../../schema/auth/matchPasswordSchema"

const initialState: MatchPasswordInterface = {
    matchPasswordLoader: false,
    matchPasswordData: null,
    matchPasswordError: null
}

const matchPasswordReducer = (state = initialState, action: matchPasswordActions) => {
    switch (action.type) {
        case MATCH_PASSWORD_REQUEST:
            return {
                ...state,
                matchPasswordLoader: true,
            };
        case MATCH_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                matchPasswordLoader: false,
                matchPasswordData: action?.payload,
                matchPasswordError: null
            }
        case MATCH_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                matchPasswordLoader: false,
                matchPasswordData: null,
                matchPasswordError: action?.payload?.matchPasswordError
            }
        default:
            return {
                ...state
            }
    }
}

export default matchPasswordReducer