import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import App from './App'
import { CustomRouter } from './components/common/CustomRouter'
import Loader from './components/common/Loader'
import './index.css'
import store from './redux/store'
import * as serviceWorker from './serviceWorker'
import theme from './theme'

const pk = process.env.REACT_APP_STRIPE_PK as string
const stripePromise = loadStripe(pk)
ReactDOM.render(
  <React.Suspense fallback={<Loader />}>
    <CookiesProvider>
      <Provider store={store}>
        <StyledEngineProvider injectFirst={true}>
          <ThemeProvider theme={theme}>
            <Elements stripe={stripePromise}>
              <CssBaseline />
              <ToastContainer limit={0} position={'top-right'} hideProgressBar={true} autoClose={2000} />
              <CustomRouter>
                <App />
              </CustomRouter>
            </Elements>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </CookiesProvider>
  </React.Suspense>,
  document.getElementById('root')
)
serviceWorker.unregister()
