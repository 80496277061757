import {
  EDIT_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
  EDIT_OWNER_PROFILE_INFO_REQUEST,
  EDIT_OWNER_PROFILE_INFO_REQUEST_FAILURE,
  EDIT_OWNER_PROFILE_INFO_REQUEST_SUCCESS,
  GET_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
  GET_OWNER_PROFILE_INFO_REQUEST,
  GET_OWNER_PROFILE_INFO_REQUEST_FAILURE,
  GET_OWNER_PROFILE_INFO_REQUEST_SUCCESS,
  UPDATE_OWNER_DETAILS_STATE,
} from '../../actions/actionTypes'
import { GetOwnerProfileInfoInterface, OwnerProfileInfoActions } from '../../schema/ownerProfile/ownerProfileSchema'

const initialState: GetOwnerProfileInfoInterface = {
  profileLoader: false,
  profileData: null,
  profileError: '',
  updateOwnerDetailState: false,
  updateProfileLoader: false,
}

const ownerProfileReducer = (state = initialState, action: OwnerProfileInfoActions) => {
  switch (action.type) {
    case GET_OWNER_PROFILE_INFO_REQUEST:
      return {
        ...state,
        profileLoader: true,
      }
    case GET_OWNER_PROFILE_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        profileLoader: false,
        profileData: action?.payload?.profileData,
        profileError: null,
      }
    case GET_OWNER_PROFILE_INFO_REQUEST_FAILURE:
      return {
        ...state,
        profileLoader: false,
        profileData: null,
        profileError: action?.payload?.profileError,
      }
    case GET_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        profileData: null,
      }
    case EDIT_OWNER_PROFILE_INFO_REQUEST:
      return {
        ...state,
        updateProfileLoader: true,
      }
    case EDIT_OWNER_PROFILE_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        updateProfileLoader: false,
        profileData: action?.payload?.profileData,
        profileError: null,
      }
    case EDIT_OWNER_PROFILE_INFO_REQUEST_FAILURE:
      return {
        ...state,
        updateProfileLoader: false,
        profileData: null,
        profileError: action?.payload?.profileError,
      }
    case EDIT_OWNER_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        profileData: null,
      }
    case UPDATE_OWNER_DETAILS_STATE:
      return {
        ...state,
        updateOwnerDetailState: action.payload.updateOwnerDetailState,
      }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default ownerProfileReducer
