import {
    MATCH_PASSWORD_REQUEST,
    MATCH_PASSWORD_REQUEST_SUCCESS,
    MATCH_PASSWORD_REQUEST_FAILURE,
} from '../../actions/actionTypes'
import {
    MatchPasswordRequest,
    MatchPasswordRequestSuccess,
    MatchPasswordRequestFailure,
    MatchPasswordRequestPayload,
    MatchPasswordRequestSuccessPayload,
    MatchPasswordFailurePayload
} from '../../schema/auth/matchPasswordSchema'

export const matchPasswordRequest = (payload: MatchPasswordRequestPayload): MatchPasswordRequest => ({
    type: MATCH_PASSWORD_REQUEST,
    payload,
});

export const matchPasswordRequestSuccess = (payload: MatchPasswordRequestSuccessPayload): MatchPasswordRequestSuccess => ({
    type: MATCH_PASSWORD_REQUEST_SUCCESS,
    payload,
});

export const matchPasswordRequestFailure = (payload: MatchPasswordFailurePayload): MatchPasswordRequestFailure => ({
    type: MATCH_PASSWORD_REQUEST_FAILURE,
    payload,
});
