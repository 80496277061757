import { makeStyles } from '@mui/styles'
import { debounce } from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import validator from 'validator'
import theme from '../theme'
import { defaultErrorMessage, HeathHistoryCompleted, HeathHistoryPending } from './constants'

export const url = process.env.REACT_APP_API_ENDPOINT || 'https://dev-oraq-apis.35.239.156.91.nip.io/'
export const currentYear = moment().year()
export const formatPhoneNumber = (phoneNumberString: any) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return cleaned
}
// Local Storage stuff start
export const getLocalStorageItem = (key: any) => localStorage.getItem(key)

export const ownerData = JSON.parse(getLocalStorageItem('ownerData') as string)

export const getOwnerData = () => {
  if (getLocalStorageItem('ownerToken')) {
    if (ownerData?.is_staff) {
      return JSON.parse(getLocalStorageItem('staffClinicInfo') as string)?.profile
    } else {
      return JSON.parse(localStorage.getItem('ownerData') as any)?.profile
    }
  }
  return null
}

export const getAccountData = () => {
  const ownerToken = getLocalStorageItem('ownerToken')
  if (ownerToken) {
    return JSON.parse(localStorage.getItem('ownerData') as string)
  }
  return null
}

// Local Storage stuff end
export const notAllowNumericRegex = new RegExp(/[0-9]/)
export const emailRegex= /^(?!\.)[A-Za-z0-9._%+-]+(?!\.)@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

export function containsSpecialChars(str: any) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return specialChars.test(str)
}

export const icRegex = /I\.C\.(?!\.)/i
export const alphabetRegex = /[a-zA-Z]/
export const lowercaseReg = new RegExp(/(?=.*?[a-z])/)
export const uppercaseReg = new RegExp(/(?=.*?[A-Z])/)
export const numReg = new RegExp(/(?=.*?[0-9])/)

export const notAllowSpecialCharRegex = new RegExp(/[!`#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/)
export const notAllowSpecificSpecialCharRegex = /^[a-zA-Z{}\[\]\s+()\s+,\s+"\s+'\-]*$/
export const canadaPostalRegex = new RegExp(/[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/)
export const usPostalRegex = new RegExp(/^\d{5}(?:-\d{4})?$/)

function deleteAllCookies() {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

export const handleLogout = () => {
  deleteAllCookies()
  localStorage.removeItem('ownerToken')
  localStorage.removeItem('ownerData')
  localStorage.removeItem('staffClinicInfo')
  window.location.href = '/login'
  // return <Navigate to='/login' />
  // return <Navigate to='/clinic-admin' />
}
export const usDateFormat = 'MM/DD/YYYY' as string
export const newUsDateFormat = 'MM-DD-YYYY' as string
export const newBackendDateFormat = 'YYYY-MM-DD' as string
export const phoneNumberPlaceholder = '(XXX)-XXX-XXXX'
export const postalCodePlaceholder = 'XXXXXX'

export const dateFormat = (dateValue: any) => {
  if (dateValue) {
    return moment(dateValue).format(usDateFormat)
  }
  return ''
}

export const newDateFormat = (dateValue: any) => {
  if (dateValue) {
    return moment(dateValue).format(newBackendDateFormat)
  }
  return ''
}

// for convert 24 hour to 12 hour with AM/PM
export const timeFormat = (timeString?: any) => {
  if (!timeString) {
    return ' - '
  }
  const [hourString, minute] = timeString.split(':')
  const hour = +hourString % 24
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM')
}
export const positionArray = [
  { label: 'Dentist', disabled: false, checked: false, isAdmin: false },
  { label: 'Assistant', disabled: false, checked: false, isAdmin: false },
  { label: 'Administrator', disabled: false, checked: false, isAdmin: true },
  { label: 'Hygienist', disabled: false, checked: false, isAdmin: false },
  { label: 'Office Manager', disabled: false, checked: false, isAdmin: true },
]

export const assignModuleArray = [
  { label: 'Manage Clinics', checked: false, isAdmin: false },
  { label: 'Manage Members', checked: false, isAdmin: false },
  { label: 'Manage Patients', checked: false, isAdmin: true },
  { label: 'Manage Appointments', checked: false, isAdmin: true },
]

export function AvoidSpace(e: any) {
  if (e.target.value.length === 0 && e.which === 32) {
    e.preventDefault()
  }
}

export const errorToaster = (message: string) => {
  toast.error(message || defaultErrorMessage, {
    className: 'toaster-container-error',
    icon: false,
  })
}
export const successToaster = (message: string) => {
  toast.success(message, {
    className: 'toaster-container',
    icon: false,
  })
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

/*To check the object is empty or not [returnType: boolean]*/
export const isEmptyObject = (obj: object) => Object.keys(obj).length < 1

export const getShortSurface = (surface: string) => {
  const surfaces = {
    Mesial: 'M',
    'Occlusal/Incisal': 'O',
    Buccal: 'B',
    Distal: 'D',
    Lingual: 'L',
  } as any
  return surfaces[surface]
}
export const stringToArray = (str: string) => {
  const arr = str?.split(',')?.filter((f: string) => f?.length)
  return arr
}

export const arrayToString = (arr: any) => {
  const str = arr?.toString()
  return str
}

interface ToothNumbers {
  [key: number]: number
}

export const toothNumberPreferences = (tooth: number, tooth_numbering_format: string) => {
  const toothNumbers = {
    18: 1,
    17: 2,
    16: 3,
    15: 4,
    14: 5,
    13: 6,
    12: 7,
    11: 8,
    21: 9,
    22: 10,
    23: 11,
    24: 12,
    25: 13,
    26: 14,
    27: 15,
    28: 16,
    38: 17,
    37: 18,
    36: 19,
    35: 20,
    34: 21,
    33: 22,
    32: 23,
    31: 24,
    41: 25,
    42: 26,
    43: 27,
    44: 28,
    45: 29,
    46: 30,
    47: 31,
    48: 32,
  } as ToothNumbers
  return tooth_numbering_format === 'USA' ? toothNumbers[tooth] : tooth
}

export const treatmentDescription = (treatmentFinding: any, fromAlternative: boolean, tooth_numbering_format: string) => {
  if (treatmentFinding) {
    const { tooth_number, tooth_surface, subtype, finding } = treatmentFinding
    const newToothNumber = toothNumberPreferences(parseInt(tooth_number), tooth_numbering_format)
    const updatedToothSurface = fromAlternative
      ? stringToArray(tooth_surface)
        ?.map((surface: string) => getShortSurface(surface))
        ?.join('')
      : tooth_surface

    if (tooth_surface && subtype) {
      return `${newToothNumber}-${finding?.replace(/_/g, ' ')}-${updatedToothSurface?.replace(/,/g, '')}-${subtype}`
    } else if (tooth_surface) {
      return `${newToothNumber}-${finding?.replace(/_/g, ' ')}-${updatedToothSurface?.replace(/,/g, '')}`
    } else if (subtype) {
      return `${newToothNumber}-${finding?.replace(/_/g, ' ')}-${subtype}`
    } else if (newToothNumber) {
      return `${newToothNumber}-${finding?.replace(/_/g, ' ')}`
    } else {
      return finding
    }
  }
  return ''
}

export const checkIsEmptyValidator = (value: any) => {
  if (value === '') {
    return validator.isEmpty(value)
  } else {
    return value === null || value === ''
  }
}
export const convertToLowerCase = (string: string) => {
  let newValue = ''
  if (string) {
    newValue = string?.toLowerCase()
  }
  return newValue
}
export const handleChangeWithDebounce = (fn: any) => debounce(fn, 500)

export const removeRefFromJson = (data: any) => {
  return JSON.parse(JSON.stringify(data)) as any
}
export const textLengthStyles = makeStyles(() => ({
  textLengthCount: {
    marginRight: '12px',
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      fontFamily: 'FavoritPro-Regular !important',
      fontSize: '12px',
    },
  },
}))
export const pastDateValidation = (key: string, data: any) => {
  const updatedInfo = data
  let errorMsg = '' as string
  const newFormatted = moment(new Date(updatedInfo[key]))?.format(usDateFormat)
  const todayNewFormatted = moment(new Date())?.format(usDateFormat)
  const pastDate = dateFormat(updatedInfo[key])?.length > 9 && moment(newFormatted).isSameOrBefore(todayNewFormatted)
  const momentDate = moment(updatedInfo[key])
  if (updatedInfo[key] && (!momentDate.isValid() || !momentDate.invalidAt())) {
    errorMsg = 'Please enter a valid date.'
  } else if (updatedInfo[key] && !pastDate) {
    errorMsg = "Please enter a past date or today's date."
  }
  return errorMsg
}
export const MailIconColor = (data: any) => {
  if (data?.is_invitation_sent && data?.pre_intake_status === HeathHistoryPending) {
    return theme.palette.warning.main // yellow
  } else if (data?.is_invitation_sent && data?.pre_intake_status === HeathHistoryCompleted) {
    return theme.palette.success.main // green
  } else {
    return theme.palette.secondary.main // purple
  }
}

export const MailIconHoverText = (data: any) => {
  if (data?.is_invitation_sent && data?.pre_intake_status === HeathHistoryPending) {
    return 'Invitation sent'
  } else if (data?.is_invitation_sent && data?.pre_intake_status === HeathHistoryCompleted) {
    return 'Health History completed'
  } else {
    return 'Send Invitation'
  }
}

export const accessCodeDisplay = (code: string) => {
  if (code.length !== 10) return code; // Ensure the code has exactly 10 digits
  return `${code.slice(0, 3)}-${code.slice(3, 6)}-${code.slice(6)}`;
};
