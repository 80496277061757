import {
  ChangeOwnerDetailsRequest,
  CreateOwnerRequest,
  CreateOwnerRequestFailure,
  CreateOwnerRequestFailurePayload,
  CreateOwnerRequestPayload,
  CreateOwnerRequestSuccess,
  CreateOwnerRequestSuccessPayload,
  DeleteOwnerRequest,
  DeleteOwnerRequestFailure,
  DeleteOwnerRequestFailurePayload,
  DeleteOwnerRequestPayload,
  DeleteOwnerRequestSuccess,
  DeleteOwnerRequestSuccessPayload,
  GetOwnerDetailsRequest,
  GetOwnerDetailsRequestFailure,
  GetOwnerDetailsRequestFailurePayload,
  GetOwnerDetailsRequestPayload,
  GetOwnerDetailsRequestSuccess,
  GetOwnerDetailsRequestSuccessPayload,
  GetOwnerListRequest,
  GetOwnerListRequestFailure,
  GetOwnerListRequestFailurePayload,
  GetOwnerListRequestSuccess,
  GetOwnerListRequestSuccessPayload,
  GetOwnerRequestPayload,
  UpdateOwnerErrorRequest,
  UpdateOwnerRequest,
  UpdateOwnerRequestFailure,
  UpdateOwnerRequestFailurePayload,
  UpdateOwnerRequestPayload,
  UpdateOwnerRequestSuccess,
  UpdateOwnerRequestSuccessPayload,
} from '../../schema/owner/ownerSchema'
import {
  GET_OWNER_LIST_REQUEST,
  GET_OWNER_LIST_REQUEST_FAILURE,
  GET_OWNER_LIST_REQUEST_SUCCESS,
  CREATE_OWNER_REQUEST,
  CREATE_OWNER_REQUEST_SUCCESS,
  CREATE_OWNER_REQUEST_FAILURE,
  UPDATE_OWNER_REQUEST,
  UPDATE_OWNER_REQUEST_SUCCESS,
  UPDATE_OWNER_REQUEST_FAILURE,
  GET_OWNER_DETAILS_REQUEST,
  GET_OWNER_DETAILS_REQUEST_SUCCESS,
  GET_OWNER_DETAILS_REQUEST_FAILURE,
  DELETE_OWNER_REQUEST,
  DELETE_OWNER_REQUEST_SUCCESS,
  DELETE_OWNER_REQUEST_FAILURE,
  CHANGE_OWNER_DETAILS,
  UPDATE_OWNER_ERROR,
} from '../actionTypes'

// Get Owner list
export const getOwnerRequest = (payload: GetOwnerRequestPayload): GetOwnerListRequest => ({
  type: GET_OWNER_LIST_REQUEST,
  payload,
})
export const getOwnerRequestSuccess = (payload: GetOwnerListRequestSuccessPayload): GetOwnerListRequestSuccess => ({
  type: GET_OWNER_LIST_REQUEST_SUCCESS,
  payload,
})
export const getOwnerRequestFailure = (payload: GetOwnerListRequestFailurePayload): GetOwnerListRequestFailure => ({
  type: GET_OWNER_LIST_REQUEST_FAILURE,
  payload,
})

// create owner
export const createOwnerRequest = (payload: CreateOwnerRequestPayload): CreateOwnerRequest => ({
  type: CREATE_OWNER_REQUEST,
  payload,
})
export const createOwnerRequestSuccess = (payload: CreateOwnerRequestSuccessPayload): CreateOwnerRequestSuccess => ({
  type: CREATE_OWNER_REQUEST_SUCCESS,
  payload,
})
export const createOwnerRequestFailure = (payload: CreateOwnerRequestFailurePayload): CreateOwnerRequestFailure => ({
  type: CREATE_OWNER_REQUEST_FAILURE,
  payload,
})
// create owner
export const updateOwnerRequest = (payload: UpdateOwnerRequestPayload): UpdateOwnerRequest => ({
  type: UPDATE_OWNER_REQUEST,
  payload,
})
export const updateOwnerRequestSuccess = (payload: UpdateOwnerRequestSuccessPayload): UpdateOwnerRequestSuccess => ({
  type: UPDATE_OWNER_REQUEST_SUCCESS,
  payload,
})
export const updateOwnerRequestFailure = (payload: UpdateOwnerRequestFailurePayload): UpdateOwnerRequestFailure => ({
  type: UPDATE_OWNER_REQUEST_FAILURE,
  payload,
})

// owner details
export const getOwnerDetailsRequest = (payload: GetOwnerDetailsRequestPayload): GetOwnerDetailsRequest => ({
  type: GET_OWNER_DETAILS_REQUEST,
  payload,
})
export const getOwnerDetailsRequestSuccess = (payload: GetOwnerDetailsRequestSuccessPayload): GetOwnerDetailsRequestSuccess => ({
  type: GET_OWNER_DETAILS_REQUEST_SUCCESS,
  payload,
})
export const getOwnerDetailsRequestFailure = (payload: GetOwnerDetailsRequestFailurePayload): GetOwnerDetailsRequestFailure => ({
  type: GET_OWNER_DETAILS_REQUEST_FAILURE,
  payload,
})
// Delete owner
export const deleteOwnerRequest = (payload: DeleteOwnerRequestPayload): DeleteOwnerRequest => ({
  type: DELETE_OWNER_REQUEST,
  payload,
})
export const deleteOwnerRequestSuccess = (payload: DeleteOwnerRequestSuccessPayload): DeleteOwnerRequestSuccess => ({
  type: DELETE_OWNER_REQUEST_SUCCESS,
  payload,
})
export const deleteOwnerRequestFailure = (payload: DeleteOwnerRequestFailurePayload): DeleteOwnerRequestFailure => ({
  type: DELETE_OWNER_REQUEST_FAILURE,
  payload,
})

export const changeOwnerDetailsRequestAction = (payload: any): ChangeOwnerDetailsRequest => ({
  type: CHANGE_OWNER_DETAILS,
  payload,
})
export const updateOwnerErrorRequestAction = (payload: any): UpdateOwnerErrorRequest => ({
  type: UPDATE_OWNER_ERROR,
  payload,
})
