import {
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_REQUEST_FAILURE,
  CREATE_PASSWORD_REQUEST_SUCCESS,
  UPDATE_CREATE_PASSWORD_DATA,
  UPDATE_CREATE_PASSWORD_ERROR,
} from '../../actions/actionTypes'
import { createPasswordActions, createPasswordInterface } from '../../schema/auth/createPasswordSchema'

const initialState: createPasswordInterface = {
  createPasswordLoader: false,
  createPasswordData: null,
  createPasswordError: null,
  updateCreatePasswordData: { newPassword: '', showNewPassword: false, confirmPassword: '', showConfirmPassword: false },
  createPasswordErrors: {},
}

const createPasswordReducer = (state = initialState, action: createPasswordActions) => {
  switch (action.type) {
    case CREATE_PASSWORD_REQUEST:
      return {
        ...state,
        createPasswordLoader: true,
      }
    case CREATE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        createPasswordLoader: false,
        createPasswordData: action.payload,
        createPasswordError: null,
      }
    case CREATE_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        createPasswordLoader: false,
        createPasswordData: null,
        createPasswordError: action.payload,
      }
    case UPDATE_CREATE_PASSWORD_DATA:
      return {
        ...state,
        updateCreatePasswordData: action.payload,
      }
    case UPDATE_CREATE_PASSWORD_ERROR:
      return { ...state, createPasswordErrors: action.payload }
    default:
      return {
        ...state,
      }
  }
}
export default createPasswordReducer
