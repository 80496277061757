import {
  CreatePatientFailurePayload,
  CreatePatientRequest,
  CreatePatientRequestFailure,
  CreatePatientRequestPayload,
  CreatePatientRequestSuccess,
  CreatePatientRequestSuccessPayload,
  DeletePatientFailurePayload,
  DeletePatientRequest,
  DeletePatientRequestFailure,
  DeletePatientRequestPayload,
  DeletePatientRequestSuccess,
  DeletePatientRequestSuccessPayload,
  GetPatientRequest,
  GetPatientRequestFailure,
  GetPatientRequestFailurePayload,
  GetPatientRequestPayload,
  GetPatientRequestSuccess,
  GetPatientRequestSuccessPayload,
  GetWellnessProfileFailure,
  GetWellnessProfileFailurePayload,
  GetWellnessProfileRequest,
  GetWellnessProfileRequestPayload,
  GetWellnessProfileSuccess,
  GetWellnessProfileSuccessPayload,
  GenerateRiskScoreWellnessFailure,
  GenerateRiskScoreWellnessFailurePayload,
  GenerateRiskScoreWellnessRequest,
  GenerateRiskScoreWellnessRequestPayload,
  GenerateRiskScoreWellnessSuccess,
  GenerateRiskScoreWellnessSuccessPayload,
  PatientStatusUpdateFailurePayload,
  PatientStatusUpdateRequest,
  PatientStatusUpdateRequestFailure,
  PatientStatusUpdateRequestPayload,
  PatientStatusUpdateRequestSuccess,
  PatientStatusUpdateRequestSuccessPayload,
  SendPateIntPreIntakeReminderRequest,
  SendPateIntPreIntakeReminderRequestFailure,
  SendPateIntPreIntakeReminderRequestSuccess,
  UpdatePatientCommunicationLocal,
  UpdatePatientCommunicationLocalPayload,
  UpdatePatientRequest,
  UpdatePatientRequestFailure,
  UpdatePatientRequestFailurePayload,
  UpdatePatientRequestPayload,
  UpdatePatientRequestSuccess,
  UpdatePatientRequestSuccessPayload,
  UpdatePatientStatusLocal,
  UpdatePatientStatusLocalPayload,
  UpdatePopUpPatientMode,
  ViewPatientRequest,
  ViewPatientRequestFailure,
  ViewPatientRequestFailurePayload,
  ViewPatientRequestPayload,
  ViewPatientRequestSuccess,
  ViewPatientRequestSuccessPayload,
} from '../../schema/patient/patientSchema'
import {
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_REQUEST_FAILURE,
  CREATE_PATIENT_REQUEST_SUCCESS,
  DELETE_PATIENT_REQUEST,
  DELETE_PATIENT_REQUEST_FAILURE,
  DELETE_PATIENT_REQUEST_SUCCESS,
  GENERATE_RISK_SCORE_PMS_REQUEST,
  GENERATE_RISK_SCORE_PMS_REQUEST_FAILURE,
  GENERATE_RISK_SCORE_PMS_REQUEST_SUCCESS,
  GET_PATIENT_CACHE_DATA_CLEAR_REQUEST,
  GET_PATIENT_REQUEST,
  GET_PATIENT_REQUEST_FAILURE,
  GET_PATIENT_REQUEST_SUCCESS,
  GET_WELLNESS_REQUEST,
  GET_WELLNESS_REQUEST_FAILURE,
  GET_WELLNESS_REQUEST_SUCCESS,
  OPEN_POPUP_PATIENT_MODE,
  SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST,
  SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_FAIL,
  SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_SUCCESS,
  UPDATE_PATIENT_COMMUNICATION_LOCAL,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_REQUEST_FAILURE,
  UPDATE_PATIENT_REQUEST_SUCCESS,
  UPDATE_PATIENT_STATUS_LOCAL,
  UPDATE_PATIENT_STATUS_REQUEST,
  UPDATE_PATIENT_STATUS_REQUEST_FAILURE,
  UPDATE_PATIENT_STATUS_REQUEST_SUCCESS,
  VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST,
  VIEW_PATIENT_REQUEST,
  VIEW_PATIENT_REQUEST_FAILURE,
  VIEW_PATIENT_REQUEST_SUCCESS,
} from '../actionTypes'

// create Patient
export const createPatientRequest = (payload: CreatePatientRequestPayload): CreatePatientRequest => ({
  type: CREATE_PATIENT_REQUEST,
  payload,
})
export const createPatientRequestSuccess = (payload: CreatePatientRequestSuccessPayload): CreatePatientRequestSuccess => ({
  type: CREATE_PATIENT_REQUEST_SUCCESS,
  payload,
})
export const createPatientRequestFailure = (payload: CreatePatientFailurePayload): CreatePatientRequestFailure => ({
  type: CREATE_PATIENT_REQUEST_FAILURE,
  payload,
})

// get patient list
export const getPatientRequest = (payload: GetPatientRequestPayload): GetPatientRequest => ({
  type: GET_PATIENT_REQUEST,
  payload,
})
export const getPatientRequestSuccess = (payload: GetPatientRequestSuccessPayload): GetPatientRequestSuccess => ({
  type: GET_PATIENT_REQUEST_SUCCESS,
  payload,
})
export const getPatientRequestFailure = (payload: GetPatientRequestFailurePayload): GetPatientRequestFailure => ({
  type: GET_PATIENT_REQUEST_FAILURE,
  payload,
})
export const getPatientCacheDataClearRequest = (): any => ({
  type: GET_PATIENT_CACHE_DATA_CLEAR_REQUEST,
})

// view Patient
export const viewPatientRequest = (payload: ViewPatientRequestPayload): ViewPatientRequest => ({
  type: VIEW_PATIENT_REQUEST,
  payload,
})
export const viewPatientRequestSuccess = (payload: ViewPatientRequestSuccessPayload): ViewPatientRequestSuccess => ({
  type: VIEW_PATIENT_REQUEST_SUCCESS,
  payload,
})
export const viewPatientRequestFailure = (payload: ViewPatientRequestFailurePayload): ViewPatientRequestFailure => ({
  type: VIEW_PATIENT_REQUEST_FAILURE,
  payload,
})
export const viewPatientCacheDataClearRequest = (): any => ({
  type: VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST,
})

// update patient
export const updatePatientRequest = (payload: UpdatePatientRequestPayload): UpdatePatientRequest => ({
  type: UPDATE_PATIENT_REQUEST,
  payload,
})
export const updatePatientRequestSuccess = (payload: UpdatePatientRequestSuccessPayload): UpdatePatientRequestSuccess => ({
  type: UPDATE_PATIENT_REQUEST_SUCCESS,
  payload,
})
export const updatePatientRequestFailure = (payload: UpdatePatientRequestFailurePayload): UpdatePatientRequestFailure => ({
  type: UPDATE_PATIENT_REQUEST_FAILURE,
  payload,
})

// delete Patient
export const deletePatientRequest = (payload: DeletePatientRequestPayload): DeletePatientRequest => ({
  type: DELETE_PATIENT_REQUEST,
  payload,
})
export const deletePatientRequestSuccess = (payload: DeletePatientRequestSuccessPayload): DeletePatientRequestSuccess => ({
  type: DELETE_PATIENT_REQUEST_SUCCESS,
  payload,
})
export const deletePatientRequestFailure = (payload: DeletePatientFailurePayload): DeletePatientRequestFailure => ({
  type: DELETE_PATIENT_REQUEST_FAILURE,
  payload,
})

// update patient status
export const patientUpdateStatusRequest = (payload: PatientStatusUpdateRequestPayload): PatientStatusUpdateRequest => ({
  type: UPDATE_PATIENT_STATUS_REQUEST,
  payload,
})
export const patientUpdateStatusRequestSuccess = (
  payload: PatientStatusUpdateRequestSuccessPayload
): PatientStatusUpdateRequestSuccess => ({
  type: UPDATE_PATIENT_STATUS_REQUEST_SUCCESS,
  payload,
})
export const patientUpdateStatusRequestFailure = (payload: PatientStatusUpdateFailurePayload): PatientStatusUpdateRequestFailure => ({
  type: UPDATE_PATIENT_STATUS_REQUEST_FAILURE,
  payload,
})
export const updatePatientStatusLocalRequest = (payload: UpdatePatientStatusLocalPayload): UpdatePatientStatusLocal => ({
  type: UPDATE_PATIENT_STATUS_LOCAL,
  payload,
})
export const updatePopUpPatientModeAction = (payload: any): UpdatePopUpPatientMode => ({
  type: OPEN_POPUP_PATIENT_MODE,
  payload,
})

export const sendPateIntPreIntakeReminderRequestAction = (payload: any): SendPateIntPreIntakeReminderRequest => ({
  type: SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST,
  payload,
})
export const sendPateIntPreIntakeReminderRequestSuccessAction = (payload: any): SendPateIntPreIntakeReminderRequestSuccess => ({
  type: SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_SUCCESS,
  payload,
})
export const sendPateIntPreIntakeReminderRequestFailureAction = (payload: any): SendPateIntPreIntakeReminderRequestFailure => ({
  type: SEND_PATIENT_PRE_INTAKE_REMINDER_REQUEST_FAIL,
  payload,
})

export const getWellnessProfileRequestAction = (payload: GetWellnessProfileRequestPayload): GetWellnessProfileRequest => ({
  type: GET_WELLNESS_REQUEST,
  payload,
})

export const getWellnessProfileSuccessAction = (payload: GetWellnessProfileSuccessPayload): GetWellnessProfileSuccess => ({
  type: GET_WELLNESS_REQUEST_SUCCESS,
  payload,
})

export const getWellnessProfileFailureAction = (payload: GetWellnessProfileFailurePayload): GetWellnessProfileFailure => ({
  type: GET_WELLNESS_REQUEST_FAILURE,
  payload,
})

export const updatePatientCommunicationLocalRequest = (
  payload: UpdatePatientCommunicationLocalPayload
): UpdatePatientCommunicationLocal => ({
  type: UPDATE_PATIENT_COMMUNICATION_LOCAL,
  payload,
})


export const generateRiskWellnessPMSRequestAction = (
  payload: GenerateRiskScoreWellnessRequestPayload
): GenerateRiskScoreWellnessRequest => ({
  type: GENERATE_RISK_SCORE_PMS_REQUEST,
  payload,
})
export const generateRiskWellnessPMSRequestSuccessAction = (
  payload: GenerateRiskScoreWellnessSuccessPayload
): GenerateRiskScoreWellnessSuccess => ({
  type: GENERATE_RISK_SCORE_PMS_REQUEST_SUCCESS,
  payload,
})
export const generateRiskWellnessPMSRequestFailureAction = (
  payload: GenerateRiskScoreWellnessFailurePayload
): GenerateRiskScoreWellnessFailure => ({
  type: GENERATE_RISK_SCORE_PMS_REQUEST_FAILURE,
  payload,
})