import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, getAccountData, successToaster } from '../../../utils/helper'
import {
  CREATE_MEMBER_REQUEST,
  DELETE_MEMBER_REQUEST,
  EDIT_ROLE_TO_MEMBER_REQUEST,
  GET_ALL_CLINIC_REQUEST,
  GET_MEMBER_REQUEST,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_STATUS_REQUEST,
  VIEW_MEMBER_REQUEST,
} from '../../actions/actionTypes'
import {
  createMemberRequestFailure,
  createMemberRequestSuccess,
  deleteMemberRequestFailure,
  deleteMemberRequestSuccess,
  editRoleToMemberRequestFailure,
  editRoleToMemberRequestSuccess,
  getAllClinicRequestFailure,
  getAllClinicRequestSuccess,
  getMemberCacheDataClearRequest,
  getMemberRequestFailure,
  getMemberRequestSuccess,
  updateMemberRequestFailure,
  updateMemberRequestSuccess,
  updateMemberStatusRequestFailure,
  updateMemberStatusRequestSuccess,
  viewMemberCacheDataClearRequest,
  viewMemberDetailRequestFailure,
  viewMemberDetailRequestSuccess,
} from '../../actions/member/memberAction'

const accountData = getAccountData()

// Create member
function* createMemberRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/staff`, payload?.payload)
    if (payload.payload.callback) {
      yield call(payload.payload.callback, response.data)
    }
    yield put(createMemberRequestSuccess({ memberCreateData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(createMemberRequestFailure({ memberCreateError: e?.message }))
  }
}

// Get member
function* getMemberRequestSaga(payload: any): any {
  yield put(getMemberCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
    ]
    if (accountData?.is_staff || payload?.payload?.fromClinic || payload?.payload?.clinic_id) {
      params.push({ key: 'clinic_id', value: payload?.payload?.clinic_id })
    }
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const response = yield API.get(`/v2/staff/get/all${tmpUrl}`)
    yield put(
      getMemberRequestSuccess({
        getMemberData: response?.data?.detail,
        getMemberMeta: response?.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getMemberRequestFailure({
        getMemberError: e?.message,
      })
    )
  }
}

// update member
function* updateMemberRequestSaga(payload: any): any {
  try {
    const clinic_id = payload?.payload?.profile ? payload?.payload?.profile?.get('clinic_id') : payload?.payload?.clinic_id
    const staff_id = payload?.payload?.profile ? payload?.payload?.profile?.get('staff_id') : payload?.payload?.staff_id

    const updatedPayload = payload?.payload?.profile ? payload?.payload?.profile : payload?.payload?.data

    const response = yield API.put(`/v2/staff?staff_id=${staff_id}&clinic_id=${clinic_id}`, updatedPayload)

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    successToaster(response?.data?.meta?.message)
    yield put(
      updateMemberRequestSuccess({
        updateMemberData: response?.data,
      })
    )
  } catch (e: any) {
    if (payload?.payload?.errorCallback) {
      payload?.payload?.errorCallback()
    }
    errorToaster(e?.message)
    yield put(
      updateMemberRequestFailure({
        updateMemberError: e?.message,
      })
    )
  }
}

// delete member
function* deleteMemberRequestSaga(payload: any): any {
  try {
    const params = []
    if (accountData?.is_staff || payload?.payload?.fromClinic) {
      params.push({ key: 'clinic_id', value: payload?.payload?.clinic_id })
    }
    let tmpUrl = params?.length > 0 ? `&` : ''
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const response = yield API.delete(`v2/staff?staff_id=${payload?.payload?.staff_id}${tmpUrl}`)
    yield call(payload?.payload?.callback, response?.data)
    successToaster(response?.data?.meta?.message)
    yield put(
      deleteMemberRequestSuccess({
        deleteMemberData: response?.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      deleteMemberRequestFailure({
        deleteMemberError: e?.message,
      })
    )
  }
}

// update member status
function* updateMemberStatusRequestSaga(payload: any): any {
  const UpdatedPayload = {
    clinic_id: payload?.payload?.clinic_id,
    status: payload?.payload?.status,
  }
  try {
    const response = yield API.put(`v2/staff/${payload?.payload?.staff_id}/status`, UpdatedPayload)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      updateMemberStatusRequestSuccess({
        memberStatusUpdateData: response?.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      updateMemberStatusRequestFailure({
        memberStatusUpdateError: e?.message,
      })
    )
  }
}

// view member details
function* viewMemberRequestSaga(payload: any): any {
  yield put(viewMemberCacheDataClearRequest())
  try {
    const response = yield API.get(
      `/v2/staff/${payload?.payload?.staff_id}${accountData?.is_staff ? `?clinic_id=${payload?.payload?.clinic_id}` : ``}`
    )
    yield call(payload?.payload?.callback, response?.data?.detail)
    yield put(
      viewMemberDetailRequestSuccess({
        viewMemberData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      viewMemberDetailRequestFailure({
        viewMemberError: e?.message,
      })
    )
  }
}

// get all Clinic
function* getAllClinicRequestSaga(payload: any): any {
  const staffUrl = `v2/clinic/details/all?clinic_id=${payload?.payload?.clinic_id}`
  const ownerUrl = `v2/clinic/details/all`
  try {
    const response = yield API.get(accountData?.is_staff ? staffUrl : ownerUrl)
    yield put(
      getAllClinicRequestSuccess({
        allClinicData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getAllClinicRequestFailure({
        allClinicError: e?.message,
      })
    )
  }
}

// edit role to member
function* editRoleToMemberRequestSaga(payload: any): any {
  try {
    const response = yield API.put(
      `v2/clinic/${payload?.payload?.clinic_id}/staff/${payload?.payload?.staff_id}`,
      payload?.payload?.assignData
    )
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    successToaster(response?.data?.meta?.message)
    yield put(
      editRoleToMemberRequestSuccess({
        editRoleToMemberData: response?.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      editRoleToMemberRequestFailure({
        editRoleToMemberError: e?.message,
      })
    )
  }
}

function* createMemberSaga() {
  yield takeEvery(CREATE_MEMBER_REQUEST, createMemberRequestSaga)
}

function* getMemberSaga() {
  yield takeEvery(GET_MEMBER_REQUEST, getMemberRequestSaga)
}

function* updateMemberSaga() {
  yield takeEvery(UPDATE_MEMBER_REQUEST, updateMemberRequestSaga)
}

function* deleteMemberSaga() {
  yield takeEvery(DELETE_MEMBER_REQUEST, deleteMemberRequestSaga)
}

function* updateMemberStatusSaga() {
  yield takeEvery(UPDATE_MEMBER_STATUS_REQUEST, updateMemberStatusRequestSaga)
}

function* viewMemberDetailSaga() {
  yield takeEvery(VIEW_MEMBER_REQUEST, viewMemberRequestSaga)
}

function* getAllClinicSaga() {
  yield takeEvery(GET_ALL_CLINIC_REQUEST, getAllClinicRequestSaga)
}

function* editRoleToMemberSaga() {
  yield takeEvery(EDIT_ROLE_TO_MEMBER_REQUEST, editRoleToMemberRequestSaga)
}

export default function* memberSaga() {
  yield all([
    createMemberSaga(),
    getMemberSaga(),
    updateMemberSaga(),
    deleteMemberSaga(),
    updateMemberStatusSaga(),
    viewMemberDetailSaga(),
    getAllClinicSaga(),
    editRoleToMemberSaga(),
  ])
}
