import {
  PUT_CLINIC_SETTINGS_REQUEST,
  PUT_CLINIC_SETTINGS_REQUEST_FAILURE,
  PUT_CLINIC_SETTINGS_REQUEST_SUCCESS,
  UPDATE_CLINIC_SETTINGS,
  UPDATE_CLINIC_SETTINGS_VALIDATIONS,
} from '../../actions/actionTypes'
import { clinicSettingsActions, ClinicSettingsInterface } from '../../schema/clinic/clinicSettingsSchema'

const initialState: ClinicSettingsInterface = {
  updateClinicSettings: null,
  putClinicSettingsLoader: false,
  putClinicSettingsData: null,
  putClinicSettingsError: null,
  clinicSettingsError: {
    clinic_email: null,
  },
}

const clinicSettingsReducer = (state = initialState, action: clinicSettingsActions) => {
  switch (action.type) {
    case UPDATE_CLINIC_SETTINGS:
      return {
        ...state,
        updateClinicSettings: action.payload,
      }
    case PUT_CLINIC_SETTINGS_REQUEST:
      return {
        ...state,
        putClinicSettingsLoader: true,
        putClinicSettingsData: null,
        putClinicSettingsError: null,
      }
    case PUT_CLINIC_SETTINGS_REQUEST_SUCCESS:
      return {
        ...state,
        putClinicSettingsLoader: false,
        putClinicSettingsData: action.payload,
        putClinicSettingsError: null,
      }
    case PUT_CLINIC_SETTINGS_REQUEST_FAILURE:
      return {
        ...state,
        putClinicSettingsLoader: false,
        putClinicSettingsData: null,
        putClinicSettingsError: action.payload,
      }
    case UPDATE_CLINIC_SETTINGS_VALIDATIONS:
      return {
        ...state,
        clinicSettingsError: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default clinicSettingsReducer
