import {
  PutClinicSettingsRequest,
  PutClinicSettingsRequestFailure,
  PutClinicSettingsRequestFailurePayload,
  PutClinicSettingsRequestPayload,
  PutClinicSettingsRequestSuccess,
  PutClinicSettingsRequestSuccessPayload,
  UpdateClinicSettingsRequest,
  UpdateClinicSettingsRequestPayload,
  UpdateClinicSettingsValidationRequest,
} from '../../schema/clinic/clinicSettingsSchema'
import {
  PUT_CLINIC_SETTINGS_REQUEST,
  PUT_CLINIC_SETTINGS_REQUEST_FAILURE,
  PUT_CLINIC_SETTINGS_REQUEST_SUCCESS,
  UPDATE_CLINIC_SETTINGS,
  UPDATE_CLINIC_SETTINGS_VALIDATIONS,
} from '../actionTypes'

// update clinic settings

export const updateClinicSettingsRequest = (payload: UpdateClinicSettingsRequestPayload): UpdateClinicSettingsRequest => ({
  type: UPDATE_CLINIC_SETTINGS,
  payload,
})
export const putClinicSettingsRequest = (payload: PutClinicSettingsRequestPayload): PutClinicSettingsRequest => ({
  type: PUT_CLINIC_SETTINGS_REQUEST,
  payload,
})
export const putClinicSettingsRequestSuccess = (payload: PutClinicSettingsRequestSuccessPayload): PutClinicSettingsRequestSuccess => ({
  type: PUT_CLINIC_SETTINGS_REQUEST_SUCCESS,
  payload,
})
export const putClinicSettingsRequestFailure = (payload: PutClinicSettingsRequestFailurePayload): PutClinicSettingsRequestFailure => ({
  type: PUT_CLINIC_SETTINGS_REQUEST_FAILURE,
  payload,
})
export const updateClinicSettingsValidationAction = (payload: any): UpdateClinicSettingsValidationRequest => ({
  type: UPDATE_CLINIC_SETTINGS_VALIDATIONS,
  payload,
})
