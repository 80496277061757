import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST } from '../../actions/actionTypes'
import {
  clinicAdminChangePasswordRequestSuccess,
  clinicAdminChangePasswordRequestFailure,
} from '../../actions/authActions/changePasswordAction'
import { errorToaster, successToaster } from '../../../utils/helper'

function* clinicAdminChangePasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`v2/auth/password`, payload?.payload)
    yield call(payload?.payload?.callback, response?.data)
    yield put(clinicAdminChangePasswordRequestSuccess({ changePasswordData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(clinicAdminChangePasswordRequestFailure({ changePasswordError: e?.detail }))
  }
}

function* postClinicAdminChangePasswordSaga() {
  yield takeEvery(CLINIC_ADMIN_CHANGE_PASSWORD_REQUEST, clinicAdminChangePasswordRequestSaga)
}

export default function* clinicAdminChangePasswordSaga() {
  yield all([postClinicAdminChangePasswordSaga()])
}
