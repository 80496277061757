import {
    VERIFIED_EMAIL_REQUEST,
    VERIFIED_EMAIL_REQUEST_SUCCESS,
    VERIFIED_EMAIL_REQUEST_FAILURE,
    SEND_EMAIL_VERIFICATION_REQUEST,
    SEND_EMAIL_VERIFICATION_REQUEST_SUCCESS,
    SEND_EMAIL_VERIFICATION_REQUEST_FAILURE,
} from '../../actions/actionTypes'

import {
    VerifiedEmailRequest,
    VerifiedEmailRequestSuccess,
    VerifiedEmailRequestFailure,
    VerifiedEmailRequestPayload,
    VerifiedEmailRequestSuccessPayload,
    VerifiedEmailFailurePayload,
    SendEmailVerificationRequest,
    SendEmailVerificationRequestSuccess,
    SendEmailVerificationRequestFailure,
    SendEmailVerificationRequestPayload,
    SendEmailVerificationSuccessPayload,
    SendEmailVerificationFailurePayload
} from '../../schema/auth/verifiedEmailSchema'

export const verifiedEmailRequest = (payload: VerifiedEmailRequestPayload): VerifiedEmailRequest => ({
    type: VERIFIED_EMAIL_REQUEST,
    payload,
});

export const verifiedEmailRequestSuccess = (payload: VerifiedEmailRequestSuccessPayload): VerifiedEmailRequestSuccess => ({
    type: VERIFIED_EMAIL_REQUEST_SUCCESS,
    payload,
});

export const verifiedEmailRequestFailure = (payload: VerifiedEmailFailurePayload): VerifiedEmailRequestFailure => ({
    type: VERIFIED_EMAIL_REQUEST_FAILURE,
    payload,
});

export const sendEmailVerificationRequest = (payload: SendEmailVerificationRequestPayload): SendEmailVerificationRequest => ({
    type: SEND_EMAIL_VERIFICATION_REQUEST,
    payload,
});

export const sendEmailVerificationRequestSuccess = (payload: SendEmailVerificationSuccessPayload): SendEmailVerificationRequestSuccess => ({
    type: SEND_EMAIL_VERIFICATION_REQUEST_SUCCESS,
    payload,
});

export const sendEmailVerificationRequestFailure = (payload: SendEmailVerificationFailurePayload): SendEmailVerificationRequestFailure => ({
    type: SEND_EMAIL_VERIFICATION_REQUEST_FAILURE,
    payload,
});

